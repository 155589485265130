var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c('div', {
    staticClass: "popIncome"
  }, [_c('div', {
    staticClass: "shadow"
  }), _c('transition', {
    attrs: {
      "appear": "",
      "name": "popPromptBottom"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "popIncome-main"
  }, [_c('div', {
    staticClass: "btn-close",
    on: {
      "click": _vm.onClose
    }
  }), _c('div', {
    staticClass: "popIncome__title"
  }, [_vm._v(_vm._s(_vm.$lang("Offline Income")))]), _c('div', {
    staticClass: "popIncome__sub"
  }, [_vm._v(_vm._s(_vm.$lang("Close this window to forfeit offline rewards.")))]), _c('div', {
    staticClass: "popIncome-total"
  }, [_c('div', {
    staticClass: "popIncome-total-main"
  }, [_vm._v(_vm._s(_vm._f("toPre")(_vm.accountInfo.offline_coin)))])]), _c('div', {
    staticClass: "popIncome-info"
  }, [_c('div', {
    staticClass: "popIncome-item",
    on: {
      "click": _vm.useAds
    }
  }, [_c('div', {
    staticClass: "popIncome-tag"
  }, [_vm._v(_vm._s(_vm.gameInfo.offlineAdvCoin * 100) + "%")]), _c('div', {
    staticClass: "popIncome-item-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-ads.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "popIncome-num"
  }, [_vm.isAdvLoading ? _c('div', {
    staticClass: "circleLoading"
  }) : _c('div', {
    staticClass: "popIncome-num-main"
  }, [_vm._v("+" + _vm._s(_vm._f("toPre")(_vm.accountInfo.offline_coin * _vm.gameInfo.offlineAdvCoin)))])]), _c('div', {
    staticClass: "popInfo-info"
  }, [_vm._v(_vm._s(_vm.$lang("Watch ads to get")))])]), _c('div', {
    staticClass: "popIncome-item",
    on: {
      "click": _vm.useDiamond
    }
  }, [_c('div', {
    staticClass: "popIncome-tag"
  }, [_vm._v("100%")]), _c('div', {
    staticClass: "popIncome-item-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-diamonds.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "popIncome-cost"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-diamonds.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.accountInfo.offline_diamond) + " ")]), _c('div', {
    staticClass: "popIncome-num"
  }, [_c('div', {
    staticClass: "popIncome-num-main"
  }, [_vm._v("+" + _vm._s(_vm._f("toPre")(_vm.accountInfo.offline_coin)))])]), _c('div', {
    staticClass: "popInfo-info"
  }, [_vm._v(_vm._s(_vm.$lang("Obtained using diamonds")))])])])])]), _c('pop-prompt', {
    ref: "popOfflineRef",
    attrs: {
      "show": _vm.popOfflineShow,
      "content": "html"
    },
    on: {
      "update:show": function ($event) {
        _vm.popOfflineShow = $event;
      },
      "confirm": _vm.popOfflineConfirm
    }
  }, [_c('div', {
    staticClass: "pop-offline"
  }, [_c('div', {
    staticClass: "pop-offline-text diamond"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Confirm to cost"))), _c('br'), _c('img', {
    attrs: {
      "src": require('@images/ui/icon-diamonds.png'),
      "alt": ""
    }
  }), _vm._v(_vm._s(_vm.accountInfo.offline_diamond) + " " + _vm._s(_vm.$lang("to receive")) + " "), _c('img', {
    attrs: {
      "src": require('@images/ui/icon-gold.png'),
      "alt": ""
    }
  }), _vm._v(_vm._s(_vm._f("toPre")(_vm.accountInfo.offline_coin)) + "? ")])])])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };