var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "task"
  }, [_c('m-assets', {
    ref: "mAssetsRef"
  }), _c('div', {
    staticClass: "task-body page-body"
  }, [_c('div', {
    staticClass: "task-main page-main"
  }, [_c('div', {
    staticClass: "tab"
  }, _vm._l(_vm.tab, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "tab-item",
      class: _vm.selectTab === item.value && 'active',
      on: {
        "click": function ($event) {
          return _vm.onTab(item);
        }
      }
    }, [_c('span', [_vm._v(_vm._s(item.label) + " "), item.value === 'task' && _vm.notifyInfo.task || item.value === 'daily' && _vm.notifyInfo.dailySign ? _c('div', {
      staticClass: "tag"
    }) : _vm._e()])]);
  }), 0), _vm.selectTab === 'task' ? [_c('m-task', {
    attrs: {
      "task": _vm.task
    }
  })] : _vm.selectTab === 'daily' ? [_c('m-daily', {
    ref: "mDailyRef",
    attrs: {
      "config": _vm.signConfig,
      "task": _vm.signTask
    },
    on: {
      "update:config": function ($event) {
        _vm.signConfig = $event;
      },
      "update:task": function ($event) {
        _vm.signTask = $event;
      }
    }
  })] : _vm._e()], 2), _c('m-menu')], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };