var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c('div', {
    staticClass: "popPayDiamondInfo popPrompt"
  }, [_c('div', {
    staticClass: "shadow",
    on: {
      "click": _vm.onClose
    }
  }), _c('transition', {
    attrs: {
      "appear": "",
      "name": "popPromptCenter"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "popPrompt-main center"
  }, [_c('div', {
    staticClass: "btn-close",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onClose.apply(null, arguments);
      }
    }
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-close"
    }
  })], 1), _c('div', {
    staticClass: "popPrompt-con",
    on: {
      "click": _vm.onSkip
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/popPayDiamondInfo.jpg`),
      "alt": ""
    }
  })])])])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };