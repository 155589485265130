import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
export default {
  name: "popPayReward",
  props: {
    show: Boolean
  },
  computed: {
    ...userVuex.mapState(["accountInfo", "payDiamondInterReward"]),
    ...gameVuex.mapState(["cardList"])
  },
  watch: {
    show(bool) {
      this.popFunc(bool, this);
      if (bool) {
        this.setWowAnim();
      } else {
        this.isFlag = true;
        this.isLoading = false;
        this.userInfo = null;
        this.cards = null;
        this.isShowAirDrop = false;
        this.rewards = null;
      }
    }
  },
  data() {
    return {
      isFlag: true,
      isLoading: false,
      rewards: null,
      isShowAirDrop: false,
      userInfo: null,
      cards: null
    };
  },
  methods: {
    ...gameVuex.mapActions(["getCardList"]),
    ...userVuex.mapActions(["getAccountBonus", "checkAdsgram", "getAccountInfo"]),
    ...userVuex.mapMutations(["setPayDiamondInterReward"]),
    onClose() {
      this.setPayDiamondInterReward(null);
      this.$emit("update:show", false);
    },
    async onConfirm() {
      this.$emit("update:show", false);
      if (this.payDiamondInterReward.asset === "card") {
        await this.getCardList();
        this.$emit("update:show", false);
        return;
      }
      setTimeout(() => {
        this.$nextTick(() => {
          this.$emit("update:show", false);
          const coinAnimStartRef = document.getElementById("coinAnimStartRef");
          if (!coinAnimStartRef) return;
          const coinAnimEndRef = document.getElementById("coinAnimEndRef");
          const menuAssetsId = document.getElementById("menuassetsId");
          if (!coinAnimEndRef) return;
          let coinImg = "";
          if (this.payDiamondInterReward.asset == "coin") {
            coinImg = "icon-gold.png";
          } else if (this.payDiamondInterReward.asset == "usdt") {
            coinImg = "icon-usdt.png";
          } else if (this.payDiamondInterReward.asset == "pony") {
            coinImg = "pony.png";
          }
          this.coinAnimFn({
            containerRef: coinAnimStartRef,
            ref: menuAssetsId ? menuAssetsId : coinAnimEndRef,
            img: coinImg,
            numCoins: 20,
            callback: async () => {
              this.setPayDiamondInterReward(null);
            }
          });
        });
      }, 500);
    },
    async onCheck() {
      this.$emit("update:show", false);
      await this.getCardList();
      let cardItem = this.payDiamondInterReward.cards[0];
      this.cardList.map(item => {
        item.cards.map(itm => {
          if (cardItem.id == itm.id) {
            cardItem = {
              ...itm,
              ...cardItem,
              gid: item.gid,
              group: item.name
            };
          }
        });
      });
      this.$router.push({
        name: "detail",
        query: cardItem
      });
    }
  }
};