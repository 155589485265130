import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "index",
  inject: ["getPopGuideRef"],
  data() {
    return {
      popActivateCardShow: false,
      selectCardRow: null,
      popIncomeShow: true,
      onDrawHandler: null,
      popJoinArenaShow: false,
      selectGroup: null,
      popRewardShow: false,
      popLuckyAirdropInfoShow: false,
      popBtnAirDropShow: true,
      //当领取空投奖励或者在空投奖励关闭时
      isBtnAirdropShow: true,
      dropTimeTimer: null,
      dropTimeCountdown: null,
      popPayDiamondInfoShow: false,
      popAmountNoLongerInfoShow: false,
      popBattleQuitShow: false,
      remainingAmount: 0,
      assetType: null,
      isBattleQuitFlag: true
    };
  },
  computed: {
    ...gameVuex.mapState(["gameInfo", "cardList", "battleList"]),
    ...userVuex.mapState(["accountInfo"]),
    isShowArena() {
      return this.gameInfo.battleCoinBet || this.gameInfo.battleUsdtBet || this.gameInfo.battlePonyBet;
    },
    accountInfoDropId() {
      return this.accountInfo.hasOwnProperty("drop_id") && this.accountInfo.drop_id > 0;
    },
    accountInfoDropTime() {
      return this.accountInfo.hasOwnProperty("drop_time") && this.accountInfo.drop_time;
    },
    isBtnPayDiamondShow() {
      return this.accountInfo.hasOwnProperty("show_topup") && this.accountInfo.show_topup;
    }
  },
  beforeDestroy() {
    this.EventBus.$off("onSkipCardEvent", this.onDrawHandler);
    this.popActivateCardShow = false;
    this.popIncomeShow = false;
    clearInterval(this.dropTimeTimer);
    this.dropTimeTimer = null;
  },
  watch: {
    accountInfoDropId: {
      handler(bool) {
        console.log("this.popRewardShow", this.popRewardShow);
        if (this.popRewardShow) return;
        this.popRewardShow = bool;
      },
      deep: true,
      immediate: true
    },
    accountInfoDropTime: {
      handler(bool) {
        clearInterval(this.dropTimeTimer);
        this.dropTimeTimer = null;
        if (bool) {
          let nowTime = parseInt(new Date().getTime() / 1000);
          const fn = () => {
            nowTime++;
            const dropTime = this.accountInfo.drop_time;
            if (nowTime >= dropTime && this.accountInfo.show_airdrop) {
              clearInterval(this.dropTimeTimer);
              this.dropTimeTimer = null;
              this.dropTimeCountdown = 0;
              this.getAccountInfo();
            } else {
              this.dropTimeCountdown = dropTime - nowTime;
            }
          };
          fn();
          this.dropTimeTimer = setInterval(fn, 1000);
        } else {
          this.dropTimeCountdown = null;
        }
      },
      deep: true,
      immediate: true
    },
    popBattleQuitShow: {
      handler(bool) {
        this.isBattleQuitFlag = true;
        if (this.$refs?.popBattleQuitRef) {
          this.$refs.popBattleQuitRef.isLoading = false;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...gameVuex.mapActions(["getCardList", "quitBattle"]),
    ...userVuex.mapActions(["getAccountInfo"]),
    onAmountNoLongerInfo(e, item, type) {
      e.stopPropagation();
      let remainingAmount = 0;
      if (type === "coin" && item.hasOwnProperty("battle_coin")) {
        remainingAmount = item.battle_coin.amount - item.battle_coin.init_amount;
      } else if (type === "usdt" && item.hasOwnProperty("battle_usdt")) {
        remainingAmount = item.battle_usdt.amount - item.battle_usdt.init_amount;
      } else if (type === "pony" && item.hasOwnProperty("battle_pony")) {
        remainingAmount = item.battle_pony.amount - item.battle_pony.init_amount;
      }
      this.selectGroup = item;
      console.log("this.selectGroup", this.selectGroup);
      this.remainingAmount = remainingAmount;
      this.assetType = type;
      this.popAmountNoLongerInfoShow = true;
    },
    popAmountNoLongerInfoConfirm(callback) {
      this.popBattleQuitShow = true;
    },
    async popBattleQuitConfirm(callback) {
      if (!this.isBattleQuitFlag) return;
      this.isBattleQuitFlag = false;
      const params = {
        gid: this.selectGroup.gid,
        asset: this.assetType
      };
      let isAnim = true;
      if (this.assetType === "coin" && this.selectGroup.hasOwnProperty("battle_coin") && this.selectGroup.battle_coin.amount <= 0 || this.assetType === "usdt" && this.selectGroup.hasOwnProperty("battle_usdt") && this.selectGroup.battle_usdt.amount <= 0 || this.assetType === "pony" && this.selectGroup.hasOwnProperty("battle_pony") && this.selectGroup.battle_pony.amount <= 0) {
        isAnim = false;
      }
      const res = await this.quitBattle(params);
      if (!res) {
        this.isBattleQuitFlag = true;
        this.$refs.popBattleQuitRef.isLoading = false;
        return;
      }
      const cardItem = res.cards.filter(item => item.gid === res.battle.gid)[0];
      this.$emit("update:detail", cardItem);
      this.selectGroup = cardItem;
      this.isBattleQuitFlag = true;
      callback();
      this.popBattleQuitShow = false;
      this.popAmountNoLongerInfoShow = false;
      if (!isAnim) {
        this.getAccountInfo(res.userInfo);
        return;
      }
      if (this.assetType === "coin") {
        window.scrollTo(0, 0);
      }
      setTimeout(() => {
        this.$nextTick(() => {
          const refs = this.$refs;
          const destoryCoinContainerRef = refs.destoryCoinContainerRef;
          if (!destoryCoinContainerRef) return;
          let coinRef = "";
          let coinImg = "";
          if (this.isShowArena) {
            const mAssetsRef = refs.mAssetsRef;
            if (!mAssetsRef) return;
            coinRef = mAssetsRef.$refs[`${this.assetType}Ref`];
            coinImg = this.assetType === "coin" && "icon-gold.png" || this.assetType === "usdt" && "icon-usdt.png" || this.assetType === "pony" && "pony.png";
          } else {
            const mAssetsRef = refs.mAssetsRef;
            if (!mAssetsRef) return;
            if (this.assetType === "coin") {
              coinRef = mAssetsRef.$refs["coinRef"];
              coinImg = "icon-gold.png";
            } else {
              const mMenuRef = refs.mMenuRef;
              if (!mMenuRef) return;
              const _coinRef = mMenuRef.$refs[`menuassets`];
              if (!_coinRef) return;
              coinRef = _coinRef[0];
              coinImg = this.assetType === "usdt" ? "icon-usdt.png" : "pony.png";
            }
          }
          if (!coinRef && !coinImg) return;
          this.coinAnimFn({
            containerRef: destoryCoinContainerRef,
            ref: coinRef,
            img: coinImg,
            numCoins: 20,
            callback: () => {
              this.getAccountInfo(res.userInfo);
            }
          });
        });
      }, 1000);
    },
    onBtnTotal() {
      // this.coinAnimFn({
      //   containerRef: this.$refs.destoryCoinContainerRef,
      //   ref: this.$refs.coinRef,
      //   callback: () => {
      //     console.log(222);
      //   },
      // });
    },
    onCheckArena(item) {
      if ((!this.gameInfo.battleCoinBet || !this.gameInfo.battleUsdtBet || !this.gameInfo.battlePonyBet) && !item.hasOwnProperty("battle_coin") && !item.hasOwnProperty("battle_usdt") && !item.hasOwnProperty("battle_pony")) {
        return;
      }
      this.selectGroup = item;
      this.popJoinArenaShow = true;
    },
    onDetail(itm, item) {
      //加入竞技场后 不允许在进入详情
      if (this.isShowArena && (item.hasOwnProperty("battle_coin") || item.hasOwnProperty("battle_usdt") || item.hasOwnProperty("battle_pony"))) {
        return;
      }
      if (!itm.hasOwnProperty("activated")) {
        return;
      }
      // //未激活 未达到激活条件
      // if (!itm.activated && !itm.star_upgradable) {
      //   console.log(333);
      //   return;
      // }
      this.selectCardRow = {
        ...itm,
        gid: item.gid,
        group: item.name
      };
      // //未激活 已达到激活条件
      // if (!itm.activated && itm.star_upgradable) {
      //   this.popActivateCardShow = true;
      //   return;
      // }
      //已激活
      console.log("this.selectActivateCardRow", this.selectCardRow);
      this.$router.push({
        name: "detail",
        query: this.selectCardRow
      });
    },
    showOfflineCoin() {
      if (this.accountInfo.offline_coin > 0 && this.accountInfo.offline_coin_displayed <= 0) {
        this.popIncomeShow = true;
        // this.accountInfo.offline_coin_displayed = 1;
      } else {
        this.popIncomeShow = false;
      }
    },
    // showReward() {
    //   if (this.accountInfo.hasOwnProperty("drop_id") && this.accountInfo.drop_id > 0) {
    //     this.popRewardShow = true;
    //   } else {
    //     this.popRewardShow = false;
    //   }
    // },
    popRewardAnim(params) {
      this.isBtnAirdropShow = false;
      const rect = params.rect;
      const bool = params.bool;
      const btnAirDropRef = this.$refs.btnAirDropRef;
      const btnAirDropRect = btnAirDropRef.getBoundingClientRect();
      const endX = btnAirDropRect.left;
      const endY = btnAirDropRect.top;
      const endW = btnAirDropRect.width;
      const endH = btnAirDropRect.height;
      btnAirDropRef.style.left = rect.left + 80 + "px";
      btnAirDropRef.style.top = rect.top + "px";
      btnAirDropRef.style.transform = "scale(1.5)";
      this.isBtnAirdropShow = this.accountInfo.show_airdrop;
      setTimeout(() => {
        btnAirDropRef.style.left = `${endX}px`;
        btnAirDropRef.style.top = `${endY}px`;
        btnAirDropRef.style.width = `${endW}px`;
        btnAirDropRef.style.height = `${endH}px`;
        btnAirDropRef.style.transform = "scale(1)";
      }, 800);
    },
    onLuckyAirdropInfoShow() {
      this.popLuckyAirdropInfoShow = true;
    },
    onPayDiaondShow() {
      this.popPayDiamondInfoShow = true;
    },
    async initData() {
      if (this.accountInfo.init >= 2) {
        this.setWowAnim();
      }
      this.showOfflineCoin();
    },
    onBtnJoin(item) {
      this.selectGroup = item;
      this.popJoinArenaShow = true;
    },
    onSkipRecord(item) {
      console.log("item====", item);
      this.$router.push({
        query: {
          gid: item.gid,
          name: item.name,
          asset: "coin",
          direction: "attacker"
        },
        path: "/battleRecord"
      });
    }
  },
  created() {
    this.onDrawHandler = this.onDetail.bind(this, {
      id: "p0101",
      rare: 1,
      name: "Horned Guardian",
      activated: false,
      star_cost: 5,
      cards: 5,
      star_upgradable: true,
      level_upgradable: false
    }, {
      gid: "g001",
      name: "Beasts of the Savannah"
    });
  },
  mounted() {
    this.initData();
    this.EventBus.$off("onSkipCardEvent", this.onDrawHandler);
    this.EventBus.$on("onSkipCardEvent", this.onDrawHandler);
  }
};