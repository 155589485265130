import vailcode from "@utils/errcode";
export default {
  namespaced: true,
  state: {
    cardList: null,
    diamondList: [],
    gameInfo: null,
    notifyInfo: {
      dailySign: false,
      task: false,
      airdrop: false,
    },
    popInsufficientPrompt: {
      show: false,
      message: "",
      code: "",
    },
  },
  mutations: {
    setPopInsufficient: (state, payload) => {
      for (const key in payload) {
        state.popInsufficientPrompt[key] = payload[key];
      }
    },

    setCardList: (state, payload) => {
      state.cardList = payload;
    },

    setDiamondList: (state, payload) => {
      state.diamondList = payload;
    },
    setGameInfo: (state, payload) => {
      state.gameInfo = {};
      for (const key in payload) {
        _v.$set(state.gameInfo, key, payload[key]);
      }
    },
    setNotifyInfo: (state, payload) => {
      state.notifyInfo = {};
      for (const key in payload) {
        _v.$set(state.notifyInfo, key, payload[key]);
      }
    },
  },
  actions: {
    //获取卡牌列表
    getCardList: ({ state, commit }, _data) => {
      return new Promise(async (resolve, reject) => {
        if (!_data) {
          const res = await _v.$http.post("card/list", {}, { useInterceptor: "request" });
          vailcode(
            res,
            () => {
              const data = res.data;
              commit("setCardList", data.cards);
              commit("setNotifyInfo", data.notify);
              resolve(1);
            },
            () => {
              resolve();
            }
          );
        } else {
          console.log("getCardList!", _data);
          commit("setCardList", _data);
          resolve(1);
        }
      });
    },

    getNotify: ({ state, commit }, _data) => {
      return new Promise(async (resolve, reject) => {
        if (!_data) {
          const res = await _v.$http.post("account/notify", {}, { useInterceptor: "request" });
          vailcode(
            res,
            () => {
              const data = res.data;
              commit("setNotifyInfo", data.notify);
              resolve(1);
            },
            () => {
              resolve();
            }
          );
        } else {
          commit("setNotifyInfo", _data);
          resolve(1);
        }
      });
    },

    //抽卡
    drawCardRandom: ({ state, commit, dispatch }, { amount, asset, isGuide }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("card/random", { amount, asset }, { useInterceptor: "request" });
        vailcode(
          res,
          async () => {
            const cardList = res.data.draw_cards.map((item) => {
              return {
                ...item,
                isShow: false,
              };
            });
            if (!res.data.cards) {
              resolve();
              return;
            }
            if (!res.data.userInfo) {
              resolve();
              return;
            }
            await dispatch("getCardList", res.data.cards);
            if (!isGuide) await dispatch("user/getAccountInfo", res.data.userInfo, { root: true });
            resolve(cardList);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //卡牌激活或升星
    upStarCard: ({ state, commit, dispatch }, { pony_id, isGuide }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("card/upstar", { pony_id }, { useInterceptor: "request" });
        vailcode(
          res,
          async () => {
            if (!res.data.userInfo) {
              resolve();
              return;
            }
            const data = res.data.cards;
            if (!data) {
              resolve();
              return;
            }
            await dispatch("getCardList", res.data.cards);
            if (!isGuide) await dispatch("user/getAccountInfo", res.data.userInfo, { root: true });
            resolve(res.data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //卡牌升级
    upLevelCard: ({ state, commit, dispatch }, pony_id) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("card/uplevel", { pony_id }, { useInterceptor: "request" });

        vailcode(
          res,
          async () => {
            if (!res.data.userInfo) {
              resolve();
              return;
            }
            const data = res.data.cards;
            if (!data) {
              resolve();
              return;
            }
            await dispatch("getCardList", res.data.cards);
            await dispatch("user/getAccountInfo", res.data.userInfo, { root: true });
            console.log("data", data);
            resolve(data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //销毁卡牌
    destroyCard: ({ state, commit, dispatch }, { pony_id, amount }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("card/destroy", { pony_id, amount }, { useInterceptor: "request" });
        vailcode(
          res,
          async () => {
            if (!res.data.cards) {
              resolve();
              return;
            }
            if (!res.data.userInfo) {
              resolve();
              return;
            }
            const data = res.data.card;
            await dispatch("getCardList", res.data.cards);
            await dispatch("user/getAccountInfo", res.data.userInfo, { root: true });
            resolve(data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //获取背包卡牌
    getCardPack: ({ state, commit }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("card/pack", {}, { useInterceptor: "request" });

        vailcode(
          res,
          () => {
            if (res.data && res.data.cards) {
              const cards = res.data.cards.map((itm) => {
                return {
                  ...itm,
                  cid: itm.id,
                  id: itm.pony_id,
                };
              });
              resolve(cards);
            } else {
              resolve();
            }
          },
          () => {
            resolve();
          }
        );
      });
    },
    //获取活动卡牌
    getSaleCard: ({ state, commit }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("card/sales", {}, { useInterceptor: "request" });
        vailcode(
          res,
          () => {
            if (!res.data && !res.data.sales) {
              resolve();
              return;
            }
            const data = res.data.sales;
            resolve(data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //购买活动卡牌
    buySaleCard: ({ state, commit, dispatch }, amount) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("card/buy", { amount }, { useInterceptor: "request" });
        vailcode(
          res,
          async () => {
            if (!res.data && !res.data.sales) {
              resolve();
              return;
            }
            if (!res.data && !res.data.cards) {
              resolve();
              return;
            }
            if (!res.data && !res.data.userInfo) {
              resolve();
              return;
            }
            const data = res.data.sales;
            await dispatch("getCardList", res.data.cards);
            await dispatch("user/getAccountInfo", res.data.userInfo, { root: true });
            resolve(data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //加入竞技场
    joinArena: ({ state, commit, dispatch, rootState }, params) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("battle/join", params, { useInterceptor: "request" });
        vailcode(
          res,
          async () => {
            const data = res.data;
            await dispatch("user/getAccountInfo", res.data.userInfo, { root: true });
            const battleList = data?.battleList;
            if (battleList) {
              _v.updateCardsWithBattleData(rootState.game.cardList, battleList);
            }
            const cardList = data?.cards;
            if (cardList) {
              await dispatch("getCardList", cardList);
              resolve(cardList);
              return;
            }
            resolve(1);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //退出竞技场
    quitBattle: ({ state, commit, dispatch }, params) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("battle/quit", params, { useInterceptor: "request" });
        vailcode(
          res,
          async () => {
            const data = res.data;
            await dispatch("getCardList", data.cards);
            await dispatch("user/getAccountInfo", data.userInfo, { root: true });
            resolve(data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //获取竞技场列表
    getBattleList: ({ state, commit }, params) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("battle/list", params, { useInterceptor: "request" });
        vailcode(
          res,
          () => {
            resolve(res.data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    // 获取战斗数据
    getBattleFight: ({ state, commit, dispatch }, params) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("battle/fight", params, { useInterceptor: "request" });
        vailcode(
          res,
          async () => {
            await dispatch("user/getAccountInfo", res.data.userInfo, { root: true });
            resolve(res.data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //获取战斗回放数据
    getBattleReplay: ({ state, commit }, params) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("battle/replay", params, { useInterceptor: "request" });
        vailcode(
          res,
          () => {
            console.log("getBattleReplay====", res);
            resolve(res.data);
          },
          () => {
            resolve();
          }
        );
      });
    },
    //看广告得体力
    getAdvEnergy: ({ state, commit }) => {
      return new Promise(async (resolve, reject) => {
        const res = await _v.$http.post("battle/adv_energy", {}, { useInterceptor: "encRequest" });
        vailcode(
          res,
          () => {
            console.log("getBattleReplay====", res);
            resolve(res.data);
          },
          () => {
            resolve();
          }
        );
      });
    },
  },
  getters: {},
};
