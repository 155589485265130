const battleData = {
  rounds: [{
    attacker: [{
      id: "p0401",
      power: 69.67,
      helth: 69.67,
      star: 0,
      level: 7,
      rare: 3
    }],
    defender: [{
      id: "p0101",
      power: 69.73,
      helth: 69.73,
      star: 0,
      level: 13,
      rare: 1
    }]
  }, {
    attacker: [{
      id: "p0401",
      power: 69.67,
      helth: 69.67,
      star: 0,
      level: 7,
      rare: 3,
      action: "attack"
    }],
    defender: [{
      id: "p0101",
      power: 69.73,
      helth: 0.06,
      star: 0,
      level: 13,
      rare: 1,
      action: "defense"
    }]
  }, {
    attacker: [{
      id: "p0401",
      power: 69.67,
      helth: 69.67,
      star: 0,
      level: 7,
      rare: 3,
      action: "attack"
    }],
    defender: [{
      id: "p0101",
      power: 69.73,
      helth: 0,
      star: 0,
      level: 13,
      rare: 1,
      action: "defense"
    }]
  }],
  result: true,
  honor: 1
};
export default {
  name: "battle",
  data() {
    const attackFrames = [require(`@images/ui/battleEffect/0.png`), require(`@images/ui/battleEffect/1.png`), require(`@images/ui/battleEffect/2.png`)];
    return {
      rounds: null,
      attack: null,
      defender: null,
      currentRound: 0,
      popBattleResultShow: false,
      isBattleHeadAnim: false,
      popJumpShow: false,
      attackUserName: "",
      defenderUserName: "",
      attackFrames,
      popBattleResultType: null,
      popBattleResultReward: 0,
      popBattleResultAmount: 0,
      popBattleResultAsset: "",
      popBattleResultDirection: "",
      popBattleResultHonor: ""
    };
  },
  computed: {
    battleData() {
      return battleData;
    }
  },
  methods: {
    setBattleOption(battleInfo) {
      const rounds = battleInfo.rounds;
      const attacker = rounds[0].attacker;
      const defender = rounds[0].defender;
      this.popBattleResultType = battleInfo.result;
      this.popBattleResultReward = battleInfo.reward;
      this.popBattleResultAmount = battleInfo.amount;
      this.popBattleResultAsset = battleInfo.asset;
      this.popBattleResultHonor = battleInfo.honor;
      console.log(" this.popBattleResultAsset", this.popBattleResultAsset);
      this.popBattleResultDirection = battleInfo.direction;
      this.attack = attacker.map(item => {
        return {
          id: item.id,
          power: item.power,
          helth: item.helth,
          totalHelth: item.helth,
          star: item.star,
          level: item.level,
          rare: item.rare
        };
      });
      this.defender = defender.map(item => {
        return {
          id: item.id,
          power: item.power,
          helth: item.helth,
          totalHelth: item.helth,
          star: item.star,
          level: item.level,
          rare: item.rare
        };
      });
      this.rounds = rounds;
      this.attackUserName = battleInfo.attackUserName;
      this.defenderUserName = battleInfo.defenderUserName;
    },
    selectDefenderByAction(defenders, action) {
      return document.getElementById(`defender${defenders.find(defender => defender.action === action).id}`);
    },
    selectAttackerByAction(attackers, action) {
      return document.getElementById(`attacker${attackers.find(attacker => attacker.action === action).id}`);
    },
    setZindex(attacker, zIndex) {
      attacker.style.zIndex = zIndex;
      attacker.parentNode.style.zIndex = zIndex;
    },
    //进场动画
    entryAnimation() {
      const cards = document.querySelectorAll(".card");
      const steps = 20;
      const duration = 1200;
      for (const card of cards) {
        let step = 0;
        const entryInterval = setInterval(() => {
          const scaleValue = 0.9 + Math.sin(step * Math.PI / 3) * 0.25;
          const translateYValue = Math.sin(step * Math.PI / steps) * 50 - 25; // Small bounce effect
          card.style.transform = `translateY(${translateYValue}px) scale(${scaleValue})`;
          step++;
          if (step > steps) {
            clearInterval(entryInterval);
            card.style.transform = "translateY(0) scale(1)";
          }
        }, duration / steps);
      }
    },
    //开始战斗
    async startBattle() {
      for (let round of this.rounds) {
        // 攻击阶段
        for (let attacker of round.attacker) {
          if (this.skipBattle) break;
          if (attacker.action === "attack") {
            const attackerElement = document.getElementById(`attacker${attacker.id}`);
            const defenderElement = this.selectDefenderByAction(round.defender, "defense");
            await this.attackCard(attackerElement, defenderElement, "attacker");
          }
        }

        // 防守阶段
        for (let defender of round.defender) {
          if (this.skipBattle) break;
          if (defender.action === "attack") {
            const defenderElement = document.getElementById(`defender${defender.id}`);
            const attackerElement = this.selectAttackerByAction(round.attacker, "defense");
            await this.attackCard(defenderElement, attackerElement, "defender");
          }
        }
        // // 更新回合数
        const currentRoundElement = document.getElementById("current-round");
        this.currentRound += 1;
        //跳过
        if (this.skipBattle) {
          this.popBattleResultShow = true;
        }
      }
      // console.log("战斗结束");
      setTimeout(() => {
        _v["isExitBattle"] = false;
        this.popBattleResultShow = true;
      }, 700);
    },
    async attackCard(attacker, defender, type) {
      return new Promise(resolve => {
        try {
          // 攻击前增加打击感以及攻击方层级设置
          attacker.style.transition = "transform 0.3s ease";
          attacker.style.transform = "scale(1.2)";
          setTimeout(() => {
            const attackerRect = attacker.getBoundingClientRect();
            const defenderRect = defender.getBoundingClientRect();
            const deltaX = defenderRect.left - attackerRect.left;
            const deltaY = defenderRect.top - attackerRect.top;
            attacker.style.transition = "transform 0.5s ease";
            attacker.style.transform = `translate(${deltaX}px, ${deltaY}px) scale(1.2)`;
            this.setZindex(attacker, 10);
            setTimeout(() => {
              const attackerId = attacker.getAttribute("id");
              const _rounds = this.rounds[this.currentRound];
              const attackerData = _rounds[type].find(it => `${type}${it.id}` === attackerId);
              const damageValue = attackerData ? attackerData.power : 0;
              this.playAttackEffect(defenderRect);
              this.showDamage(defender, damageValue);
              this.attack = this.attack.map((itm, idx) => {
                return {
                  ...itm,
                  ..._rounds.attacker[idx]
                };
              });
              this.defender = this.defender.map((itm, idx) => {
                return {
                  ...itm,
                  ..._rounds.defender[idx]
                };
              });
              // this.attack = { ...this.attack, ..._rounds.attacker };
              // this.defender = { ...this.defender, ..._rounds.defender };

              // this.attack = this.attack.map((_it) => {
              //   this.$set(_it, "helth", _rounds.attacker.filter((it) => it.id === _it.id)[0].helth);
              //   return _it;
              // });
              // this.defender = this.defender.map((_it) => {
              //   this.$set(_it, "helth", _rounds.defender.filter((it) => it.id === _it.id)[0].helth);
              //   return _it;
              // });
              // this.attack = this.attack.map(it=> {
              //   this.$set(_it, "helth", _rounds.helth);
              //   return it
              // })
              // this.defender = this.defender.map((it) => {
              //   this.$set(it, "helth", _rounds.helth);
              //   return it;
              // });
              console.log("this.attack66666", this.currentRound, _rounds, this.attack, this.defender);
              attacker.style.transition = "transform 0.2s ease";
              attacker.style.transform = `translate(${deltaX}px, ${deltaY}px) scale(1)`;
              setTimeout(() => {
                attacker.style.transition = "transform 0.5s ease";
                attacker.style.transform = "translate(0, 0) scale(1)";
                setTimeout(() => {
                  this.setZindex(attacker, 1);
                }, 500);
                setTimeout(() => {
                  resolve();
                }, 1000);
              }, 200);
            }, 500);
          }, 50);
        } catch (err) {
          // console.log("");
        }
      });
    },
    // 显示被攻击方受到伤害数值
    showDamage(target, damage) {
      const damageNumber = document.createElement("div");
      damageNumber.classList.add("damage-number");
      damageNumber.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="400" height="200">
        <text>
          <tspan
              dx="0"
              dy="0"
              alignment-baseline="text-before-edge"
              text-anchor="start"
              fill="#fff"
          >
              -${damage}
          </tspan>
        </text>
      </svg>
      `;
      console.log("target", target);
      target.appendChild(damageNumber);
      setTimeout(() => {
        damageNumber.style.opacity = 1;
        damageNumber.style.transform = "translate(0, -50px)";
      }, 100);
      setTimeout(() => {
        damageNumber.style.opacity = 0;
        damageNumber.style.transform = "translate(50px, -50px)";
        setTimeout(() => {
          damageNumber.remove();
        }, 300);
      }, 1000);
    },
    playAttackEffect(attackerRect) {
      try {
        const attackEffect = document.getElementById("attack-effect");
        attackEffect.style.left = `${attackerRect.left + attackerRect.width / 2 - 80}px`; // Adjust based on effect size
        attackEffect.style.top = `${attackerRect.top + attackerRect.height / 2 - 100}px`;
        attackEffect.style.display = "block";
        let frameIndex = 0;
        const frameInterval = setInterval(() => {
          attackEffect.style.backgroundImage = `url(${this.attackFrames[frameIndex]})`;
          frameIndex++;
          if (frameIndex >= this.attackFrames.length) {
            clearInterval(frameInterval);
            attackEffect.style.display = "none";
          }
        }, 80);
      } catch (err) {}
    },
    onJump() {
      this.skipBattle = true;
    }
  },
  async mounted() {
    this.isBattleHeadAnim = true;
    this.setWowAnim();
    _v["isExitBattle"] = true;
    const battleInfo = this.battleData;
    console.log("battleInfo", battleInfo);
    if (!battleInfo) {
      this.$router.go(-1);
      return;
    }
    await this.setBattleOption(battleInfo);
    await this.entryAnimation();
    setTimeout(this.startBattle, 2000);
    _v[battleInfo] = null;
    this.WebApp.BackButton.onClick(() => {
      setTimeout(() => {
        _v["isExitBattle"] = false;
        this.popBattleResultShow = true;
      }, 1000);
    });
  }
};