import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
export default {
  name: "activity",
  data() {
    return {
      isJoinFlag: true,
      isJoinLoading: false,
      task: null,
      rows: null,
      user: null,
      airdropTimer: null,
      airdropTime: null,
      popRewardShow: false,
      isLoading: false,
      listPage: {
        page: 0,
        total: 0,
        perPage: 50
      },
      isFinished: false,
      isComingSoon: false
    };
  },
  computed: {},
  beforeRouteLeave(to, from, next) {
    clearInterval(this.airdropTimer);
    window.removeEventListener("scroll", this.handleScroll);
    this.popRewardShow = false;
    setTimeout(() => {
      next();
    }, 100);
  },
  methods: {
    ...gameVuex.mapActions(["getNotify"]),
    ...userVuex.mapActions(["airdropJoin", "airdropInfo"]),
    async airdropJoinFn() {
      if (!this.isJoinFlag) return;
      this.isJoinFlag = false;
      this.isJoinLoading = true;
      const res = await this.airdropJoin();
      if (!res) {
        this.isJoinFlag = true;
        this.isJoinLoading = false;
        return;
      }
      await this.getAiropInfo();
      await this.getNotify();
      console.log("res", res);
      this.isJoinFlag = true;
      this.isJoinLoading = false;
    },
    onJoin() {
      if (this.airdropTime < 0) {
        this.$toasted.error(this.$lang("Not Started!"));
      } else if (this.user) {
        this.$toasted.error(this.$lang("Joined!"));
      } else if (this.airdropTime === "end") {
        this.$toasted.error(this.$lang("Ended!"));
      } else if (this.task.max_user > 0 && this.task.total_user >= this.task.max_user) {
        this.$toasted.error(this.$lang("Participant limit reached!"));
      } else {
        this.airdropJoinFn();
      }
    },
    calcAirdropTimeFn() {
      if (!this.task) {
        return;
      }
      const startTime = this.task.start;
      const endTime = this.task.end;
      let nowTime = parseInt(new Date().getTime() / 1000);
      clearInterval(this.airdropTimer);
      const fn = async () => {
        nowTime++;
        if (nowTime < startTime) {
          this.airdropTime = -(startTime - nowTime);
        } else if (nowTime == startTime) {
          this.airdropTime = 0;
          clearInterval(this.airdropTimer);
          await this.getAiropInfo();
          this.calcAirdropTimeFn();
        } else if (nowTime > startTime && nowTime < endTime) {
          this.airdropTime = endTime - nowTime;
        } else if (nowTime >= endTime) {
          this.airdropTime = "end";
          clearInterval(this.airdropTimer);
          await this.getAiropInfo();
        }
      };
      fn();
      this.airdropTimer = setInterval(() => {
        (async () => {
          fn();
        })();
      }, 1000);
    },
    checkReard() {
      this.popRewardShow = true;
    },
    popRewardConfirm(callback) {
      this.popRewardShow = false;
    },
    async getAiropInfo(isPage) {
      this.listPage.page++;
      if (!isPage || !this.rows) {
        this.rows = {};
        this.$set(this.rows, "items", []);
        this.listPage.page = 1;
      }
      this.isLoading = true;
      const res = await this.airdropInfo({
        page: this.listPage.page,
        perPage: this.listPage.perPage
      });
      if (!res) {
        this.isLoading = false;
        return;
      }
      this.task = res.task;
      const rows = res.rows;
      this.rows.items.push(...rows.items);
      this.user = res.user;
      console.log(" this.rows", this.rows.items);
      this.listPage.total = res.rows.total;
      this.isFinished = this.rows.items.length >= this.listPage.total; // 没有更多数据
      this.isLoading = false;
    },
    async handleScroll() {
      console.log("this.isLoading || this.isFinished", this.isLoading || this.isFinished);
      if (this.isLoading || this.isFinished) return;
      // 获取页面滚动的距离
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      console.log("scrollTop + windowHeight + 1 >= documentHeight", scrollTop + windowHeight + 1 >= documentHeight);
      if (scrollTop + windowHeight + 1 >= documentHeight) {
        await this.getAiropInfo(true);
      }
    }
  },
  async mounted() {
    window.addEventListener("scroll", this.handleScroll);
    await this.getAiropInfo();
    this.setWowAnim();
    console.log("this.task", this.task);
    this.calcAirdropTimeFn();
    this.isComingSoon = true;
  }
};