var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "diamond"
  }, [_c('m-assets'), _c('div', {
    staticClass: "diamond-body page-body"
  }, [_c('div', {
    staticClass: "diamond-main page-main"
  }, [_c('transition-group', {
    staticClass: "diamond-list",
    attrs: {
      "tag": "div",
      "appear": ""
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterList
    }
  }, _vm._l(_vm.diamondList, function (item, index) {
    return _c('div', {
      key: `d${index}`,
      staticClass: "diamond-item",
      attrs: {
        "data-index": index + 1
      },
      on: {
        "click": function ($event) {
          return _vm.onPayDiamond(item, index);
        }
      }
    }, [_c('m-diamond-item', {
      attrs: {
        "item": item,
        "index": index
      }
    })], 1);
  }), 0)], 1)]), _c('m-menu')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };