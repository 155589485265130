var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "rank"
  }, [_c('div', {
    staticClass: "rank-head",
    style: {
      background: 'url(' + require('@images/ui/rank-head-bg.jpg') + ') center/auto 100% no-repeat'
    }
  }), _c('div', {
    staticClass: "rank-main"
  }, [_c('div', {
    staticClass: "tab"
  }, _vm._l(_vm.tab, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "tab-item",
      class: _vm.selectTab === item.value && 'active',
      on: {
        "click": function ($event) {
          return _vm.onTab(item);
        }
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 0), _vm.rankList ? [_c('transition-group', {
    staticClass: "rank-list",
    attrs: {
      "tag": "div"
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterLongList
    }
  }, [_vm.rankList.length > 0 ? _vm._l(_vm.rankList, function (item, index) {
    return _c('div', {
      key: `l${index}`,
      staticClass: "rank-item",
      attrs: {
        "data-index": index <= 50 ? index + 1 : 0
      }
    }, [_c('m-rank-item', {
      attrs: {
        "item": item,
        "index": index
      }
    })], 1);
  }) : _vm._e()], 2), _vm.isLoading === true ? _c('div', {
    staticClass: "loading-list"
  }, _vm._l(3, function (_) {
    return _c('div');
  }), 0) : _vm._e(), _vm.isFinished && _vm.rankList.length > 0 && _vm.rankList.length > _vm.listPage.perPage ? _c('div', {
    staticClass: "finished"
  }, [_vm._v(" " + _vm._s(_vm.$lang("No more")) + " ")]) : _vm._e(), _vm.rankList.length <= 0 && _vm.isLoading === false ? _c('m-empty', {
    attrs: {
      "content": _vm.$lang(`It's empty here.`)
    }
  }) : _vm._e()] : _vm._e()], 2), _c('m-menu')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };