import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "arena"
  }, [_c('m-arena-assets', {
    ref: "mArenaAssetsRef"
  }), _c('div', {
    staticClass: "arena-body page-body"
  }, [_c('div', {
    staticClass: "arena-main page-main"
  }, [_c('div', {
    staticClass: "arena-head"
  }, [_c('div', {
    staticClass: "arena-head-main"
  }, [_c('span', {
    style: {
      zIndex: 1,
      background: 'url(' + require('@images/ui/arena-head-bg.jpg') + ') center/auto 100% no-repeat'
    }
  }), _c('span', {
    staticClass: "wow fadeInLeft",
    style: {
      zIndex: 2,
      background: 'url(' + require('@images/ui/arena-head-left.png') + ') center/auto 100% no-repeat'
    },
    attrs: {
      "data-wow-duration": "1s"
    }
  }), _c('span', {
    staticClass: "wow fadeInRight",
    style: {
      zIndex: 2,
      background: 'url(' + require('@images/ui/arena-head-right.png') + ') center/auto 100% no-repeat'
    },
    attrs: {
      "data-wow-duration": "1s"
    }
  }), _c('span', {
    staticClass: "wow fadeInUp",
    style: {
      zIndex: 3,
      background: 'url(' + require('@images/ui/arena-head-s.png') + ') center/auto 100% no-repeat'
    },
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }), _c('span', {
    staticClass: "wow fadeInDown",
    style: {
      zIndex: 3,
      background: 'url(' + require('@images/ui/arena-head-v.png') + ') center/auto 100% no-repeat'
    },
    attrs: {
      "data-wow-duration": "0.5s"
    }
  })])]), _c('div', {
    staticClass: "tab"
  }, _vm._l(_vm.tab, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "tab-item",
      class: _vm.selectTab === item.value && 'active',
      on: {
        "click": function ($event) {
          return _vm.onTab(item);
        }
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 0), _c('div', {
    staticClass: "filter"
  }, [_c('div', {
    staticClass: "filter-sort",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onAmountFilter.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "filter__label",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onShowSelectSort.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm._f("capitalizeFirstLetter")(_vm.listPage.sort)))]), _c('div', {
    staticClass: "filter-sort__arrow",
    class: _vm.listPage.direction
  }, [_c('span'), _c('span')]), _vm.isShowSelectSort ? _c('div', {
    staticClass: "pop-filter"
  }, [_c('div', {
    staticClass: "pop-filter-shadow",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onFilterClose.apply(null, arguments);
      }
    }
  }), _c('div', {
    staticClass: "pop-filter-main"
  }, [_c('div', {
    staticClass: "pop-filter-list"
  }, [_c('div', {
    staticClass: "pop-filter-item",
    class: _vm.listPage.sort == 'amount' && 'active',
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onSelectSort('amount');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("Amount")))]), _c('div', {
    staticClass: "pop-filter-item",
    class: _vm.listPage.sort == 'power' && 'active',
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onSelectSort('power');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("Power")))])])])]) : _vm._e()]), _c('div', {
    staticClass: "filter-select",
    class: (_vm.isShowSelectAmount || _vm.listPage.min_amount !== null || _vm.listPage.max_amount !== null) && 'active',
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onSelectFilter('amount');
      }
    }
  }, [_c('div', {
    staticClass: "filter__label select"
  }, [_vm._v(_vm._s(_vm.$lang("Amount")))]), _c('div', {
    staticClass: "filter-arrow"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 1), _vm.isShowSelectAmount ? _c('div', {
    staticClass: "pop-filter"
  }, [_c('div', {
    staticClass: "pop-filter-shadow",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onFilterClose.apply(null, arguments);
      }
    }
  }), _c('div', {
    staticClass: "pop-filter-main"
  }, [_c('div', {
    staticClass: "pop-filter-title"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Amount")) + " "), _c('div', {
    staticClass: "pop-filter-clear",
    on: {
      "click": function ($event) {
        return _vm.onFilterClear('amount');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("Clear")))])]), _c('div', {
    staticClass: "pop-filter-input"
  }, [_c('div', {
    staticClass: "pop-filter-input-main"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.listPage.min_amount,
      expression: "listPage.min_amount"
    }],
    attrs: {
      "type": "text",
      "inputmode": "numeric",
      "placeholder": _vm.$lang('min')
    },
    domProps: {
      "value": _vm.listPage.min_amount
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.listPage, "min_amount", $event.target.value);
      }, function ($event) {
        return _vm.onInput($event, 'min_amount');
      }],
      "change": function ($event) {
        return _vm.onChange($event, 'min_amount');
      }
    }
  }), _c('span', [_vm._v("To")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.listPage.max_amount,
      expression: "listPage.max_amount"
    }],
    attrs: {
      "type": "text",
      "inputmode": "numeric",
      "placeholder": _vm.$lang('max')
    },
    domProps: {
      "value": _vm.listPage.max_amount
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.listPage, "max_amount", $event.target.value);
      }, function ($event) {
        return _vm.onInput($event, 'max_amount');
      }],
      "change": function ($event) {
        return _vm.onChange($event, 'max_amount');
      }
    }
  })])])])]) : _vm._e()]), _c('div', {
    staticClass: "filter-select",
    class: (_vm.isShowSelectPower || _vm.listPage.min_power !== null || _vm.listPage.max_power !== null) && 'active',
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onSelectFilter('power');
      }
    }
  }, [_c('div', {
    staticClass: "filter__label select"
  }, [_vm._v(_vm._s(_vm.$lang("Power")))]), _c('div', {
    staticClass: "filter-arrow"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 1), _vm.isShowSelectPower ? _c('div', {
    staticClass: "pop-filter"
  }, [_c('div', {
    staticClass: "pop-filter-shadow",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onFilterClose.apply(null, arguments);
      }
    }
  }), _c('div', {
    staticClass: "pop-filter-main"
  }, [_c('div', {
    staticClass: "pop-filter-title"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Power")) + " "), _c('div', {
    staticClass: "pop-filter-clear",
    on: {
      "click": function ($event) {
        return _vm.onFilterClear('power');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("Clear")))])]), _c('div', {
    staticClass: "pop-filter-input"
  }, [_c('div', {
    staticClass: "pop-filter-input-main"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.listPage.min_power,
      expression: "listPage.min_power"
    }],
    attrs: {
      "type": "text",
      "inputmode": "numeric",
      "placeholder": _vm.$lang('min')
    },
    domProps: {
      "value": _vm.listPage.min_power
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.listPage, "min_power", $event.target.value);
      }, function ($event) {
        return _vm.onInput($event, 'min_power');
      }],
      "change": function ($event) {
        return _vm.onChange($event, 'min_power');
      }
    }
  }), _c('span', [_vm._v("To")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.listPage.max_power,
      expression: "listPage.max_power"
    }],
    attrs: {
      "type": "text",
      "inputmode": "numeric",
      "placeholder": _vm.$lang('max')
    },
    domProps: {
      "value": _vm.listPage.max_power
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.listPage, "max_power", $event.target.value);
      }, function ($event) {
        return _vm.onInput($event, 'max_power');
      }],
      "change": function ($event) {
        return _vm.onChange($event, 'max_power');
      }
    }
  })])])])]) : _vm._e()]), _c('div', {
    staticClass: "filter-record",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onSkipRecord.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "filter__label"
  }, [_c('span', {
    style: {
      background: 'url(' + require('@images/ui/icon-arena-record.png') + ') center/auto 100% no-repeat'
    }
  }), _vm._v(_vm._s(_vm.$lang("Record")))])])]), _vm.isLoading === true ? _c('div', {
    staticClass: "loading-list"
  }, _vm._l(3, function (_) {
    return _c('div');
  }), 0) : _vm._e(), _vm.arenaList ? _c('div', {
    staticClass: "arena-list"
  }, _vm._l(_vm.arenaList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "index-con",
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.onSelect(item);
        }
      }
    }, [_c('div', {
      staticClass: "title"
    }, [_c('div', {
      staticClass: "title__label"
    }, [_vm._v(_vm._s(item.firstname) + " " + _vm._s(item.lastname))]), _c('div', {
      staticClass: "title-element"
    }, [_c('div', {
      staticClass: "title-element-item"
    }, [_vm.selectTab === 'coin' ? _c('span', {
      style: {
        background: 'url(' + require('@images/ui/icon-gold.png') + ') center/auto 100% no-repeat'
      }
    }) : _vm.selectTab === 'usdt' ? _c('span', {
      style: {
        background: 'url(' + require('@images/ui/icon-usdt.png') + ') center/auto 100% no-repeat'
      }
    }) : _vm.selectTab === 'pony' ? _c('span', {
      style: {
        background: 'url(' + require('@images/ui/pony.png') + ') center/auto 100% no-repeat'
      }
    }) : _vm._e(), _vm._v(" " + _vm._s(_vm._f("toPre")(item.amount)) + " ")]), _c('div', {
      staticClass: "title-element-item power"
    }, [_c('span', {
      style: {
        background: 'url(' + require('@images/ui/icon-arena-power.png') + ') center/auto 100% no-repeat'
      }
    }), _vm._v(_vm._s(_vm._f("toPre")(item.group_power * 3600)))])])]), _c('transition-group', {
      staticClass: "list",
      attrs: {
        "tag": "div",
        "appear": ""
      },
      on: {
        "before-enter": _vm.beforeEnter,
        "enter": _vm.enterList
      }
    }, _vm._l(item.group_data.cards, function (itm, idx) {
      return _c('div', {
        key: `l${idx}`,
        staticClass: "item",
        attrs: {
          "data-index": idx + 1
        }
      }, [_c('m-card-item', {
        attrs: {
          "detail": itm,
          "isShowUpdateLevelUI": false,
          "isShowUpdateStarUI": false
        }
      })], 1);
    }), 0)], 1);
  }), 0) : _vm._e(), _vm.arenaList && _vm.arenaList.length <= 0 && _vm.isLoading === false ? _c('m-empty', {
    attrs: {
      "isBtn": true,
      "btnText": _vm.$lang('Join Arena')
    },
    on: {
      "confirm": () => _vm.$router.push('/')
    }
  }) : _vm._e()], 1)]), _vm.listPage.total > _vm.listPage.perPage ? _c('div', {
    staticClass: "mPage"
  }, [_c('m-page', {
    staticClass: "pagination",
    attrs: {
      "total": _vm.listPage.total,
      "page-size": _vm.listPage.perPage,
      "page": _vm.listPage.page,
      "on-page-change": _vm.onArenaListPage
    }
  })], 1) : _vm._e(), _c('m-menu'), _c('pop-pick-combination', {
    attrs: {
      "show": _vm.popPickCombinationShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popPickCombinationShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };