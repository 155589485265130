import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
import noZoom from "./assets/js/noZoom";
noZoom();
export default {
  provide() {
    return {
      reload: this.reload,
      getPopGuideRef: this.getPopGuideRef,
      setPopComingSoon: this.setPopComingSoon
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme"]),
    ...gameVuex.mapState(["popInsufficientPrompt"]),
    ...userVuex.mapState(["accountInfo", "payDiamondInterReward"]),
    isDisablePcApi() {
      return this.isDisablePc && !this.isPhone;
    },
    route() {
      return this.$route.path;
    },
    isWatchInit() {
      return !this.isPreload && this.accountInfo !== null && this.accountInfo.init <= 2;
    },
    isPhone() {
      const sUserAgent = navigator.userAgent.toLowerCase();
      return /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent);
    }
  },
  data() {
    return {
      isRouterAlive: true,
      isPreload: true,
      popGuideShow: false,
      popComingsoonShow: false,
      popPayRewardShow: false
    };
  },
  watch: {
    isDisablePcApi: {
      handler(bool) {
        if (bool && this.WebApp.initData) {
          this.getLoginInfo();
        }
      },
      deep: true,
      immediate: true
    },
    "$route.path": {
      async handler(newPath, oldPath) {
        if (newPath === "/") {
          this.WebApp.BackButton.isVisible && this.WebApp.BackButton.hide();
        } else {
          this.WebApp.BackButton.show();
        }
        if (this.accountInfo !== null && this.accountInfo.init < 2 && oldPath === "/shop") {
          this.popGuideShow = false;
          await this.getAccountInfo();
          this.popGuideShow = true;
        }
        this.clearPopFunc();
        if (newPath != oldPath) {
          _v["isExitBattle"] = false;
        }
      },
      deep: true
    },
    isWatchInit: {
      handler(bool) {
        this.popGuideShow = this.accountInfo != null && this.accountInfo.init < 2;
      },
      immediate: true,
      deep: true
    },
    payDiamondInterReward: {
      handler(obj) {
        if (obj) {
          if (obj) {
            this.popPayRewardShow = true;
          } else {
            this.popPayRewardShow = false;
          }
        }
        console.log("payDiamondInterReward", obj);
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...userVuex.mapActions(["getLoginInfo", "getAccountInfo"]),
    ...gameVuex.mapMutations(["setPopInsufficient"]),
    ...gameVuex.mapActions(["getCardList"]),
    getPopGuideRef() {
      return new Promise(resolve => {
        this.$nextTick(() => {
          resolve(this.$refs.popGuideRef);
        });
      });
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    async perloadResultFn() {
      this.isPreload = false;
      await this.getCardList();
    },
    popInsufficientPromptConfirm(callback) {
      this.setPopInsufficient({
        show: false
      });
      this.$nextTick(() => {
        setTimeout(() => {
          console.log("this.popInsufficientPrompt.code", this.popInsufficientPrompt.code);
          this.popInsufficientPrompt.code === 101 && this.$router.push("/diamond");
          this.popInsufficientPrompt.code === 102 && this.$router.push("/shop");
          callback();
        }, 500);
      });
    },
    setPopComingSoon(bool) {
      this.popComingsoonShow = bool;
    }
  },
  mounted() {
    this.WebApp.BackButton.onClick(() => {
      if (_v["isExitBattle"]) return;
      this.$router.go(-1);
    });
  }
};