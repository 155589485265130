var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c('div', {
    staticClass: "popPrompt popJoinArena"
  }, [_c('div', {
    staticClass: "shadow",
    on: {
      "click": function ($event) {
        return _vm.$emit('update:show', false);
      }
    }
  }), _c('transition', {
    attrs: {
      "appear": "",
      "name": "popPromptBottom"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "popPrompt-main bottom"
  }, [_c('div', {
    staticClass: "btn-close",
    on: {
      "click": function ($event) {
        return _vm.$emit('update:show', false);
      }
    }
  }), _c('div', {
    staticClass: "popPrompt__title"
  }, [_vm._v(_vm._s(_vm.$lang("Join Arena")))]), _c('div', {
    staticClass: "popPrompt-con"
  }, [_c('div', {
    staticClass: "popJoinArena__sub"
  }, [_vm._v(_vm._s(_vm.$lang("Arena Settings")))]), _vm.gameInfo && _vm.detail ? _c('div', {
    staticClass: "popJoinArena-list"
  }, [_vm.gameInfo.battleCoinBet ? _c('div', {
    staticClass: "popJoinArena-item"
  }, [_c('div', {
    staticClass: "popJoinArena-item__name"
  }, [_c('span', {
    style: {
      background: 'url(' + require('@images/ui/icon-gold.png') + ') center/auto 100% no-repeat'
    }
  }), _vm.detail.hasOwnProperty('battle_coin') ? [_c('i', [_vm._v(_vm._s(_vm._f("toPre")(_vm.detail.battle_coin.amount)) + " "), _c('em')]), _vm.detail.battle_coin.amount - _vm.detail.battle_coin.init_amount != 0 ? _c('em', {
    class: _vm.detail.battle_coin.amount - _vm.detail.battle_coin.init_amount > 0 ? 'arena-item__add' : 'arena-item__reduce'
  }, [_c('svg-icon', {
    staticClass: "icon-increase-add",
    attrs: {
      "name": "icon-Increase"
    }
  }), _vm._v(" " + _vm._s(_vm._f("toPre")(_vm.detail.battle_coin.amount - _vm.detail.battle_coin.init_amount, 2)) + " ")], 1) : _vm._e()] : _c('i', [_vm._v(_vm._s(_vm.$lang("COIN")))])], 2), _c('div', {
    staticClass: "popJoinArena-btns"
  }, [_vm.detail.hasOwnProperty('battle_coin') ? [_c('div', {
    staticClass: "btn-quit",
    on: {
      "click": function ($event) {
        return _vm.onQuit('coin');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("Quit")))]), _c('div', {
    staticClass: "btn-record",
    on: {
      "click": function ($event) {
        return _vm.onSkipRecord('coin');
      }
    }
  }, [_c('span')])] : _c('div', {
    staticClass: "btn-join",
    on: {
      "click": function ($event) {
        return _vm.onBtnJoin('coin');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("Join")))])])], 2)]) : _vm._e(), _vm.gameInfo.battleUsdtBet ? _c('div', {
    staticClass: "popJoinArena-item"
  }, [_c('div', {
    staticClass: "popJoinArena-item__name"
  }, [_c('span', {
    style: {
      background: 'url(' + require('@images/ui/icon-usdt.png') + ') center/auto 100% no-repeat'
    }
  }), _vm.detail.hasOwnProperty('battle_usdt') ? [_c('i', [_vm._v(_vm._s(_vm._f("toPre")(_vm.detail.battle_usdt.amount)) + " "), _c('em')]), _vm.detail.battle_usdt.amount - _vm.detail.battle_usdt.init_amount != 0 ? _c('em', {
    class: _vm.detail.battle_usdt.amount - _vm.detail.battle_usdt.init_amount > 0 ? 'arena-item__add' : 'arena-item__reduce'
  }, [_c('svg-icon', {
    staticClass: "icon-increase-add",
    attrs: {
      "name": "icon-Increase"
    }
  }), _vm._v(" " + _vm._s(_vm._f("toPre")(_vm.detail.battle_usdt.amount - _vm.detail.battle_usdt.init_amount, 2)) + " ")], 1) : _vm._e()] : _c('i', [_vm._v(_vm._s(_vm.$lang("USDT")))])], 2), _c('div', {
    staticClass: "popJoinArena-btns"
  }, [_vm.detail.hasOwnProperty('battle_usdt') ? [_c('div', {
    staticClass: "btn-quit",
    on: {
      "click": function ($event) {
        return _vm.onQuit('usdt');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("Quit")))]), _c('div', {
    staticClass: "btn-record",
    on: {
      "click": function ($event) {
        return _vm.onSkipRecord('usdt');
      }
    }
  }, [_c('span')])] : _c('div', {
    staticClass: "btn-join",
    on: {
      "click": function ($event) {
        return _vm.onBtnJoin('usdt');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("Join")))])])], 2)]) : _vm._e(), _vm.gameInfo.battlePonyBet ? _c('div', {
    staticClass: "popJoinArena-item"
  }, [_c('div', {
    staticClass: "popJoinArena-item__name"
  }, [_c('span', {
    style: {
      background: 'url(' + require('@images/ui/pony.png') + ') center/auto 100% no-repeat'
    }
  }), _vm.detail.hasOwnProperty('battle_pony') ? [_c('i', [_vm._v(_vm._s(_vm._f("toPre")(_vm.detail.battle_pony.amount)) + " "), _c('em')]), _vm.detail.battle_pony.amount - _vm.detail.battle_pony.init_amount != 0 ? _c('em', {
    class: _vm.detail.battle_pony.amount - _vm.detail.battle_pony.init_amount > 0 ? 'arena-item__add' : 'arena-item__reduce'
  }, [_c('svg-icon', {
    staticClass: "icon-increase-add",
    attrs: {
      "name": "icon-Increase"
    }
  }), _vm._v(" " + _vm._s(_vm._f("toPre")(_vm.detail.battle_pony.amount - _vm.detail.battle_pony.init_amount, 2)) + " ")], 1) : _vm._e()] : _c('i', [_vm._v(_vm._s(_vm.$lang("PONY")))])], 2), _c('div', {
    staticClass: "popJoinArena-btns"
  }, [_vm.detail.hasOwnProperty('battle_pony') ? [_c('div', {
    staticClass: "btn-quit",
    on: {
      "click": function ($event) {
        return _vm.onQuit('pony');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("Quit")))]), _c('div', {
    staticClass: "btn-record",
    on: {
      "click": function ($event) {
        return _vm.onSkipRecord('pony');
      }
    }
  }, [_c('span')])] : _c('div', {
    staticClass: "btn-join",
    on: {
      "click": function ($event) {
        return _vm.onBtnJoin('pony');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("Join")))])])], 2)]) : _vm._e()]) : _vm._e()])])]), _c('pop-join-arena-select-amount', {
    attrs: {
      "show": _vm.popJoinArenaSelectAmountShow,
      "type": _vm.selectType,
      "detail": _vm.popJoinArenaSelectAmountDetail
    },
    on: {
      "update:show": function ($event) {
        _vm.popJoinArenaSelectAmountShow = $event;
      },
      "confirm": _vm.popJoinArenaSelectAmountConfirm
    }
  }), _c('pop-prompt', {
    ref: "popBattleQuitRef",
    attrs: {
      "show": _vm.popBattleQuitShow,
      "content": "html",
      "title": "Quit Hint"
    },
    on: {
      "update:show": function ($event) {
        _vm.popBattleQuitShow = $event;
      },
      "confirm": _vm.popBattleQuitConfirm
    }
  }, [_vm.detail ? _c('div', {
    staticClass: "popBattleQuit"
  }, [_vm.remainingAmount === 0 ? [_vm._v(" " + _vm._s(_vm.$lang("Exit the arena?")) + " ")] : [_vm._v(" You " + _vm._s(_vm.remainingAmount > 0 ? "earned" : "lost") + " "), _c('img', {
    attrs: {
      "src": require(`@images/ui/${_vm.assetType === 'coin' && 'icon-gold' || _vm.assetType === 'usdt' && 'icon-usdt' || _vm.assetType === 'pony' && 'icon-pony'}.png`),
      "alt": ""
    }
  }), _vm._v(" "), _c('i', {
    class: _vm.remainingAmount > 0 ? 'earned' : 'lost'
  }, [_vm._v(_vm._s(_vm._f("toPre")(Math.abs(_vm.remainingAmount))))]), _vm._v(" this time. "), _c('br'), _vm._v("Exit the arena? ")]], 2) : _vm._e()])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };