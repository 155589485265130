import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "mMenu",
  inject: ["setPopComingSoon"],
  computed: {
    ...gameVuex.mapState(["gameInfo"]),
    isShowArena() {
      return this.gameInfo.battleCoinBet || this.gameInfo.battleUsdtBet || this.gameInfo.battlePonyBet;
    }
  },
  watch: {
    "$route.path": {
      handler(newPath, oldPath) {
        if (newPath == "/" || newPath == "") {
          return this.selectMenu = "/";
        }
        this.selectMenu = newPath;
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    const menuList = [{
      icon: "menu-home",
      label: this.$lang("Home"),
      value: "/",
      name: "home"
    }, {
      icon: "menu-rank",
      label: this.$lang("Ranking"),
      value: "/rank",
      name: "rank"
    }, {
      icon: "menu-arena",
      label: this.$lang("Arena"),
      value: "/arena",
      name: "arena"
    }, {
      icon: "menu-friend",
      label: this.$lang("Friends"),
      value: "/friend",
      name: "friend"
    }, {
      icon: "menu-assets",
      label: this.$lang("Assets"),
      value: "/assets",
      name: "assets"
    }];
    return {
      menuList,
      selectMenu: null
    };
  },
  methods: {
    onMenu(item) {
      if (item.value === "/arena" && !this.gameInfo.battleCoinBet && !this.gameInfo.battleUsdtBet && !this.gameInfo.battlePonyBet) {
        this.setPopComingSoon(true);
        return;
      }
      this.selectMenu = item.value;
      this.$router.push(item.value);
    },
    setMenu() {
      if (this.version !== 1) {
        const miningMenu = {
          icon: "menu-mining",
          label: this.$lang("Mining"),
          value: "/mining",
          name: "mining"
        };
        this.menuList.splice(1, 1); //删除排行版
        this.menuList.splice(1, 0, miningMenu); //
      }
    }
  },

  created() {
    this.setMenu();
  }
};