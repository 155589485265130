import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "assets"
  }, [JSON.stringify('accountInfo') != '{}' ? _c('div', {
    staticClass: "assets-main"
  }, [_c('div', {
    staticClass: "name wow fadeInDown",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_vm._v(_vm._s(_vm.accountInfo.firstname) + " " + _vm._s(_vm.accountInfo.lastname))]), _c('transition-group', {
    staticClass: "assets-list",
    attrs: {
      "tag": "div",
      "appear": ""
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterLongList
    }
  }, [_c('div', {
    key: "a",
    staticClass: "assets-item",
    attrs: {
      "data-index": 1
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-gold.png`),
      "alt": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm.accountInfo.coin.toLocaleString("en-US")))])]), _c('div', {
    key: "b",
    staticClass: "assets-item",
    attrs: {
      "data-index": 2
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-diamonds.png`),
      "alt": ""
    }
  }), _c('span', [_c('em', [_vm._v(" " + _vm._s(_vm.accountInfo.diamond.toLocaleString("en-US")) + " "), _vm.isPayDiamondInterLoading ? _c('u', [_c('svg-icon', {
    attrs: {
      "name": "loading",
      "spin": ""
    }
  })], 1) : _vm._e()])]), _c('i', {
    staticClass: "top",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/diamond');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("Top Up")))])]), _c('div', {
    key: "c",
    staticClass: "assets-item",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "data-index": 3
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-ton.png`),
      "alt": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm.accountInfo.ton.toLocaleString("en-US")))]), _c('i', {
    staticClass: "withdraw",
    on: {
      "click": function ($event) {
        return _vm.onWithdraw('ton');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("Withdraw")))])]), _c('div', {
    key: "d",
    staticClass: "assets-item",
    attrs: {
      "data-index": 4
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-usdt.png`),
      "alt": ""
    }
  }), _c('span', [_c('em', [_vm._v(" " + _vm._s(_vm.accountInfo.usdt.toLocaleString("en-US")) + " "), _vm.isPayUsdtInterLoading ? _c('u', [_c('svg-icon', {
    attrs: {
      "name": "loading",
      "spin": ""
    }
  })], 1) : _vm._e()])]), _c('i', {
    staticClass: "top xb",
    on: {
      "click": function ($event) {
        return _vm.onDeposit('usdt');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("Deposit")))]), _c('i', {
    staticClass: "withdraw",
    on: {
      "click": function ($event) {
        return _vm.onWithdraw('usdt');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("Withdraw")))]), _vm.accountInfo.hasOwnProperty('usdt_locked') && _vm.accountInfo.usdt_locked > 0 ? _c('div', {
    staticClass: "lock"
  }, [_c('span', [_c('img', {
    staticClass: "lockImg",
    attrs: {
      "src": require('@images/ui/icon-arena-lock.png'),
      "alt": ""
    }
  }), _vm._v(_vm._s(_vm._f("toPre")(_vm.accountInfo.usdt_locked)))]), _c('img', {
    directives: [{
      name: "tip",
      rawName: "v-tip.top.click",
      value: `Unprocessed withdrawal-locked tokens will unlock in five minutes.`,
      expression: "`Unprocessed withdrawal-locked tokens will unlock in five minutes.`",
      modifiers: {
        "top": true,
        "click": true
      }
    }],
    staticClass: "lockQuestion",
    attrs: {
      "src": require('@images/ui/icon-question.png'),
      "alt": ""
    }
  })]) : _vm._e()]), _c('div', {
    key: "e",
    staticClass: "assets-item",
    attrs: {
      "data-index": 5
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/pony.png`),
      "alt": ""
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.accountInfo.pony.toLocaleString("en-US")) + " "), _c('em', [_vm.isPayPonyInterLoading ? _c('u', [_c('svg-icon', {
    attrs: {
      "name": "loading",
      "spin": ""
    }
  })], 1) : _vm._e()])]), _c('i', {
    staticClass: "top xb",
    staticStyle: {
      "display": "none"
    },
    on: {
      "click": function ($event) {
        return _vm.onDeposit('pony');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("Deposit")))]), _c('i', {
    staticClass: "withdraw",
    staticStyle: {
      "display": "none"
    },
    on: {
      "click": function ($event) {
        return _vm.onWithdraw('pony');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("Withdraw")))]), _vm.accountInfo.hasOwnProperty('pony_locked') && _vm.accountInfo.pony_locked > 0 ? _c('div', {
    staticClass: "lock"
  }, [_c('span', [_c('img', {
    staticClass: "lockImg",
    attrs: {
      "src": require('@images/ui/icon-arena-lock.png'),
      "alt": ""
    }
  }), _vm._v(_vm._s(_vm._f("toPre")(_vm.accountInfo.pony_locked)))]), _c('img', {
    directives: [{
      name: "tip",
      rawName: "v-tip.top.click",
      value: `Unprocessed withdrawal-locked tokens will unlock in five minutes.`,
      expression: "`Unprocessed withdrawal-locked tokens will unlock in five minutes.`",
      modifiers: {
        "top": true,
        "click": true
      }
    }],
    staticClass: "lockQuestion",
    attrs: {
      "src": require('@images/ui/icon-question.png'),
      "alt": ""
    }
  })]) : _vm._e()]), _c('div', {
    key: "t",
    staticClass: "assets-item",
    attrs: {
      "data-index": 6
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/battle-icon-honor.png`),
      "alt": ""
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.accountInfo.honor.toLocaleString("en-US")) + "  "), _c('img', {
    directives: [{
      name: "tip",
      rawName: "v-tip.top.click",
      value: `The USDT and PONY arena challenge awards Honor Points, which boost Power for added hash rate in the mining pool. Each Honor Point increases hash rate share by 1‰.`,
      expression: "`The USDT and PONY arena challenge awards Honor Points, which boost Power for added hash rate in the mining pool. Each Honor Point increases hash rate share by 1‰.`",
      modifiers: {
        "top": true,
        "click": true
      }
    }],
    attrs: {
      "src": require('@images/ui/icon-question.png'),
      "alt": ""
    }
  })]), _c('i', {
    staticClass: "top",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/arena');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("Go Arena")))])]), _c('div', {
    key: "f",
    staticClass: "assets-title",
    attrs: {
      "data-index": 7
    }
  }, [_c('span', [_vm._v("Wallet")]), !_vm.accountInfo.wallet && _vm.accountInfo.wallet !== _vm.tonAddress && _vm.tonConnect ? _c('i', {
    on: {
      "click": _vm.bindWalletFn
    }
  }, [_vm.isBindLoading ? _c('div', {
    staticClass: "circleLoading"
  }) : [_vm._v("Bind Wallet")]], 2) : _vm.tonConnect && _vm.accountInfo.wallet !== _vm.tonAddress ? _c('i', {
    on: {
      "click": _vm.rebindWalletFn
    }
  }, [_vm._v("Rebind Wallet")]) : _vm._e()]), _vm.tonAddress ? _c('div', {
    key: "g",
    staticClass: "assets-item wallet",
    attrs: {
      "data-index": 7
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-wallet.png`),
      "alt": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm._f("fmt_address")(_vm.tonWallet.tranAddress(_vm.tonAddress), 16)))]), _c('em', {
    on: {
      "click": _vm.quitWalletFn
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-quit.png`),
      "alt": ""
    }
  })])]) : !_vm.tonAddress ? _c('div', {
    key: "gs",
    staticClass: "assets-item wallet",
    attrs: {
      "data-index": 7
    },
    on: {
      "click": _vm.connectWalletFn
    }
  }, [_vm.isLoading ? _c('div', {
    staticClass: "circleLoading"
  }) : [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-wallet.png`),
      "alt": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$lang("Connect Wallet")))])]], 2) : _vm._e()])], 1) : _vm._e(), _c('m-menu'), _c('pop-prompt', {
    ref: "popRebindRef",
    attrs: {
      "show": _vm.popRebindShow,
      "content": "html",
      "isBtnConfirmText": "Rebind"
    },
    on: {
      "update:show": function ($event) {
        _vm.popRebindShow = $event;
      },
      "confirm": _vm.popRebindConfirm
    }
  }, [_c('div', {
    staticClass: "popRebind"
  }, [_c('div', {
    staticClass: "popRebind-main"
  }, [_vm.tonAddress ? _c('div', {
    staticClass: "popRebind-item active"
  }, [_vm._v("Wallet: " + _vm._s(_vm._f("fmt_address")(_vm.tonWallet.tranAddress(_vm.tonAddress), 16)))]) : _vm._e(), _c('div', {
    staticClass: "popRebind-arrow"
  }), _vm.accountInfo.wallet ? _c('div', {
    staticClass: "popRebind-item"
  }, [_vm._v("Bind: " + _vm._s(_vm._f("fmt_address")(_vm.tonWallet.tranAddress(_vm.accountInfo.wallet), 16)))]) : _vm._e()])])]), _c('pop-prompt', {
    ref: "popWithdrawRef",
    attrs: {
      "show": _vm.popWithdrawShow,
      "title": "Withdraw",
      "content": "html"
    },
    on: {
      "update:show": function ($event) {
        _vm.popWithdrawShow = $event;
      },
      "confirm": _vm.popWithdrawConfirm
    }
  }, [_vm.assetType && _vm.popWithdrawShow ? _c('div', {
    staticClass: "pop-withdraw"
  }, [_c('div', {
    staticClass: "pop-withdraw-balance"
  }, [_c('span', [_c('img', {
    attrs: {
      "src": require(`@images/ui/${_vm.assetType === 'ton' && 'icon-ton.png' || _vm.assetType === 'usdt' && 'icon-usdt.png' || _vm.assetType === 'pony' && 'pony.png'}`),
      "alt": ""
    }
  }), _vm._v(_vm._s(_vm._f("toPre")(_vm.assetType === "ton" && _vm.accountInfo.ton || _vm.assetType === "usdt" && _vm.accountInfo.usdt || _vm.assetType === "pony" && _vm.accountInfo.pony)))])]), _c('div', {
    staticClass: "pop-withdraw-input"
  }, [_c('div', {
    staticClass: "pop-withdraw-input-main"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.withdrawNum,
      expression: "withdrawNum"
    }],
    attrs: {
      "type": "text",
      "inputmode": "numeric"
    },
    domProps: {
      "value": _vm.withdrawNum
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.withdrawNum = $event.target.value;
      }, _vm.onInput]
    }
  })]), _c('div', {
    staticClass: "pop-withdraw-btn",
    on: {
      "click": _vm.onAll
    }
  }, [_c('span', [_vm._v("All")])])])]) : _vm._e()]), _c('pop-prompt', {
    ref: "popDepositRef",
    attrs: {
      "show": _vm.popDepositShow,
      "title": "Desposit",
      "content": "html"
    },
    on: {
      "update:show": function ($event) {
        _vm.popDepositShow = $event;
      },
      "confirm": _vm.popDepositConfirm
    }
  }, [_vm.assetType && _vm.popDepositShow ? _c('div', {
    staticClass: "pop-withdraw"
  }, [_c('div', {
    staticClass: "pop-withdraw-input deposit"
  }, [_c('div', {
    staticClass: "pop-withdraw-input-main"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/${_vm.assetType === 'usdt' && 'icon-usdt' || _vm.assetType === 'pony' && 'pony'}.png`),
      "alt": ""
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.depositNum,
      expression: "depositNum"
    }],
    attrs: {
      "type": "text",
      "inputmode": "numeric"
    },
    domProps: {
      "value": _vm.depositNum
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.depositNum = $event.target.value;
      }, _vm.onDepositInput]
    }
  })])])]) : _vm._e()]), _c('pop-prompt', {
    attrs: {
      "show": _vm.popInsufficientShow,
      "content": _vm.$lang(`${_vm.assetType}  Insufficient`),
      "isBtnConfirmText": "OK"
    },
    on: {
      "update:show": function ($event) {
        _vm.popInsufficientShow = $event;
      },
      "confirm": _vm.popInsufficientConfirm
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };