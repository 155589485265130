var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c('div', {
    staticClass: "popJoinArenaSelectAmount popPrompt"
  }, [_c('div', {
    staticClass: "shadow"
  }), _c('transition', {
    attrs: {
      "appear": "",
      "name": "popPromptCenter"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "popPrompt-main center"
  }, [_c('div', {
    staticClass: "btn-close",
    on: {
      "click": function ($event) {
        return _vm.$emit('update:show', false);
      }
    }
  }), _c('div', {
    staticClass: "popPrompt__title"
  }, [_vm._v(_vm._s(_vm.$lang("SET AMOUNT")))]), _c('div', {
    staticClass: "popPrompt-con"
  }, [_vm.type ? _c('div', {
    staticClass: "popJoinArenaSelectAmount__type"
  }, [_vm._v(_vm._s(_vm._f("capitalizeFirstLetter")(_vm.type)))]) : _vm._e(), _c('div', {
    staticClass: "popJoinArenaSelectAmount-balance"
  }, [_c('div', {
    staticClass: "popJoinArenaSelectAmount__title"
  }, [_vm._v(_vm._s(_vm.$lang("Balance")))]), _c('div', {
    staticClass: "popJoinArenaSelectAmount__label"
  }, [_vm.type == 'coin' ? _c('span', [_vm._v(_vm._s(_vm._f("toPre")(_vm.accountInfo.coin)))]) : _vm.type == 'usdt' ? _c('span', [_vm._v(_vm._s(_vm._f("toPre")(_vm.accountInfo.usdt)))]) : _vm.type == 'pony' ? _c('span', [_vm._v(_vm._s(_vm._f("toPre")(_vm.accountInfo.pony)))]) : _vm._e(), _vm._v("  " + _vm._s(_vm._f("capitalizeFirstLetter")(_vm.type)) + " ")])]), _c('div', {
    staticClass: "popJoinArenaSelectAmount-main"
  }, [_c('div', {
    staticClass: "popJoinArenaSelectAmount-input"
  }, [_c('div', {
    staticClass: "popJoinArenaSelectAmount-input__label"
  }, [_vm._v(" " + _vm._s(_vm.selectAmount) + " ")]), _c('div', {
    staticClass: "btn-all",
    on: {
      "click": _vm.onClear
    }
  }, [_vm._v(_vm._s(_vm.$lang("CLEAR")))])]), _c('div', {
    staticClass: "popJoinArenaSelectAmount-list"
  }, _vm._l(_vm.amountList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "popJoinArenaSelectAmount-item",
      class: [item + _vm.selectAmount > _vm.balance && 'disabled'],
      on: {
        "click": function ($event) {
          return _vm.onSelectAmount(item);
        }
      }
    }, [_vm._v("+" + _vm._s(item))]);
  }), 0)]), _vm.balance > 0 ? _c('div', {
    staticClass: "popJoinArenaSelectAmount-btns",
    on: {
      "click": _vm.onConfirm
    }
  }, [_c('div', {
    staticClass: "btn-confirm"
  }, [_vm.isLoading ? _c('div', {
    staticClass: "circleLoading"
  }) : [_vm._v(_vm._s(_vm.$lang("Join")))]], 2)]) : _c('div', {
    staticClass: "popJoinArenaSelectAmount-btns",
    on: {
      "click": _vm.onPay
    }
  }, [_c('div', {
    staticClass: "btn-confirm"
  }, [_vm.isLoading ? _c('div', {
    staticClass: "circleLoading"
  }) : [_vm._v(_vm._s(_vm.$lang("Deposit")))]], 2)])])])]), _c('pop-prompt', {
    ref: "popDepositRef",
    attrs: {
      "show": _vm.popDepositShow,
      "title": "Desposit",
      "content": "html"
    },
    on: {
      "update:show": function ($event) {
        _vm.popDepositShow = $event;
      },
      "confirm": _vm.popDepositConfirm
    }
  }, [_vm.popDepositShow ? _c('div', {
    staticClass: "pop-withdraw"
  }, [_c('div', {
    staticClass: "pop-withdraw-input deposit"
  }, [_c('div', {
    staticClass: "pop-withdraw-input-main"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/${_vm.type === 'usdt' && 'icon-usdt' || _vm.type === 'pony' && 'pony'}.png`),
      "alt": ""
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.depositNum,
      expression: "depositNum"
    }],
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.depositNum
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.depositNum = $event.target.value;
      }, _vm.onDepositInput]
    }
  })])])]) : _vm._e()])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };