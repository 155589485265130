import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
export default {
  name: "mAssets",
  props: {
    gold: {
      type: [String, Number],
      default: "--"
    },
    diamond: {
      type: [String, Number],
      default: "--"
    }
  },
  data() {
    return {
      isAssetCoin: false
    };
  },
  watch: {
    "accountInfo.coin": {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.isAssetCoin = true;
          setTimeout(() => {
            this.isAssetCoin = false;
          }, 100);
        }
      }
    },
    "$route.path": {
      handler(path) {
        this.path = path;
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...userVuex.mapState(["accountInfo", "isPayDiamondInterLoading"])
  },
  methods: {
    onSkip() {
      this.$router.push("/diamond");
    }
  }
};