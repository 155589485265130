export default {
  name: "popBattleResult",
  props: {
    show: Boolean,
    type: Boolean,
    // true: 胜利 false:失败
    reward: [String, Number],
    amount: [String, Number],
    asset: [String, Number],
    direction: [String, Number],
    honor: [String, Number]
  },
  watch: {
    show(bool) {
      this.popFunc(bool, this);
      if (bool) {
        this.setWowAnim();
      }
    }
  },
  methods: {
    onConfirm() {
      _v["isExitBattle"] = false;
      setTimeout(() => {
        this.$router.go(-1);
      }, 200);
    }
  }
};