import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
export default {
  name: "rank",
  data() {
    const tab = [{
      label: this.$lang("Coin"),
      value: "coin"
    }, {
      label: this.$lang("Power"),
      value: "power"
    }];
    return {
      tab,
      selectTab: "coin",
      rankGoldList: [],
      rankPowerList: [],
      rankList: [],
      isLoading: false,
      listPage: {
        page: 0,
        total: 0,
        perPage: 100
      },
      isFinished: false
    };
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.handleScroll);
    setTimeout(() => {
      next();
    }, 100);
  },
  methods: {
    ...userVuex.mapActions(["getRankList"]),
    onTab(item) {
      if (item.value === this.selectTab) return;
      this.selectTab = item.value;
      this.getRank();
    },
    async getRank(isPage) {
      this.listPage.page++;
      if (!isPage) {
        this.rankList = [];
        this.listPage.page = 1;
      }
      const params = {
        type: this.selectTab,
        page: this.listPage.page,
        perPage: this.listPage.perPage
      };
      this.isLoading = true;
      const res = await this.getRankList(params);
      if (!res) {
        this.isLoading = false;
        return;
      }
      const rankList = res.items.map(item => {
        return {
          ...item,
          type: this.selectTab
        };
      });
      this.listPage.total = res.total;
      this.rankList.push(...rankList);
      this.isFinished = this.rankList.length >= this.listPage.total; // 没有更多数据
      this.isLoading = false;
    },
    async handleScroll() {
      if (this.isLoading || this.isFinished) return;
      // 获取页面滚动的距离
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

      // 判断是否接近页面底部（距离底部100px以内时触发加载更多）
      // console.log("xxxxxx", scrollTop + windowHeight + 1 >= documentHeight, scrollTop + windowHeight + 1, documentHeight);
      if (scrollTop + windowHeight + 1 >= documentHeight) {
        console.log("88888888");
        await this.getRank(true);
      }
    }
  },
  async mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.getRank(true);
  }
};