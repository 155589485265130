import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "detail"
  }, [_c('m-assets', {
    ref: "mAssetsRef"
  }), _c('div', {
    staticClass: "detail-body page-body"
  }, [_c('div', {
    staticClass: "detail-main page-main"
  }, [_c('div', {
    staticClass: "detail-con"
  }, [_vm.card !== null ? [_c('div', {
    staticClass: "rarity wow fadeInLeft",
    attrs: {
      "data-wow-duration": "0.4s"
    }
  }, [_c('img', {
    directives: [{
      name: "popover",
      rawName: "v-popover:rarity.right",
      arg: "rarity",
      modifiers: {
        "right": true
      }
    }],
    attrs: {
      "src": require(`@images/ui/rarity-${_vm.card.rare}.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "frag wow fadeInRight",
    attrs: {
      "data-wow-duration": "0.4s"
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/chip-1.png`),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "frag__value"
  }, [_vm._v("x" + _vm._s(_vm._f("toPre")(_vm.card.cards)))]), [_vm.card.cards > 0 ? _c('div', {
    staticClass: "btn-destroy",
    on: {
      "click": _vm.onDestroy
    }
  }, [_vm._v(_vm._s(_vm.$lang("Destroy")))]) : _c('div', {
    staticClass: "btn-destroy",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/shop');
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("Shop")))])]], 2), _c('div', {
    staticClass: "card wow bounceIn",
    attrs: {
      "data-wow-duration": "0.4s"
    }
  }, [_c('div', {
    staticClass: "card-main"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/card/${_vm.card.id}.png`),
      "alt": ""
    }
  })])]), _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "info__title wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.4s"
    }
  }, [_vm._v(_vm._s(_vm.card.group))]), _c('div', {
    staticClass: "info__name wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.4s"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.card.name))])])]), _c('div', {
    staticClass: "ele"
  }, [_c('div', {
    staticClass: "ele-item stars wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.4s"
    }
  }, [_c('div', {
    staticClass: "star"
  }, [_c('div', {
    staticClass: "star-bg"
  }, _vm._l(4, function (_) {
    return _c('i');
  }), 0), _vm.card.star > 0 ? _c('div', {
    staticClass: "star-main"
  }, _vm._l(_vm.card.star, function (_) {
    return _c('i');
  }), 0) : _vm._e()]), _c('div', {
    staticClass: "progress"
  }, [_c('div', {
    staticClass: "bar",
    style: {
      width: parseInt(_vm.card.star / _vm.gameInfo.maxStar * 100) + '%'
    }
  })]), _vm.card.hasOwnProperty('star') ? _c('div', {
    staticClass: "progress-tag"
  }, [_vm._v(_vm._s(parseInt(_vm.card.star / _vm.gameInfo.maxStar * 100)) + "%")]) : _vm._e()]), _c('div', {
    staticClass: "ele-item wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.4s"
    }
  }, [_c('span', [_vm._v("Lv.")]), _c('i', [_vm._v(_vm._s(_vm.card.hasOwnProperty("level") ? _vm.card.level : "--"))]), _c('div', {
    staticClass: "progress"
  }, [_c('div', {
    staticClass: "bar",
    style: {
      width: parseInt(_vm.card.level / _vm.gameInfo.maxLevel * 100) + '%'
    }
  })]), _vm.card.hasOwnProperty('level') ? _c('div', {
    staticClass: "progress-tag"
  }, [_vm._v(_vm._s(parseInt(_vm.card.level / _vm.gameInfo.maxLevel * 100)) + "%")]) : _vm._e()]), _c('div', {
    staticClass: "ele-item xb power wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.4s"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("Power")) + " (Coin/h)")]), _vm.card.hasOwnProperty('cid') ? _c('i', [_vm._v(_vm._s(_vm._f("toPre")(_vm.card.power * 3600)) + " ")]) : _c('i', [_vm._v("--")])]), _c('div', {
    staticClass: "ele-item xb wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.4s"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("ID")))]), _c('i', [_vm._v(_vm._s(_vm.card.hasOwnProperty("cid") ? _vm.card.cid : "--"))])])]), [!_vm.card.activated ? _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn-mint activation wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.4s",
      "id": "activateCardBtn"
    },
    on: {
      "click": _vm.onActivate
    }
  }, [_c('div', {
    staticClass: "btn-mint-main"
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("ACTIVATE")))]), _c('div', {
    staticClass: "btn-mint-info"
  }, [_c('div', {
    staticClass: "btn-mint-info-main"
  }, [_vm._v(_vm._s(_vm.card.cards) + "/" + _vm._s(_vm.card.star_cost))])]), _vm.card.hasOwnProperty('star_upgradable') && !_vm.card.activated && _vm.card.star_upgradable ? _c('div', {
    staticClass: "btn-upgrade-upgrade-info"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-anim-upgrade_1.png`),
      "alt": ""
    }
  })]) : _vm._e()])])]) : _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn-upgrade wow fadeInUp",
    class: _vm.card.level >= _vm.gameInfo.maxLevel && 'max',
    attrs: {
      "data-wow-duration": "0.4s"
    },
    on: {
      "click": function ($event) {
        _vm.card.level < _vm.gameInfo.maxLevel && _vm.onUpgrade();
      }
    }
  }, [_c('div', {
    staticClass: "btn-upgrade-main"
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("UPGRADE")))]), _c('div', {
    staticClass: "btn-upgrade-info"
  }, [_vm.card.level < _vm.gameInfo.maxLevel ? _c('div', {
    staticClass: "btn-upgrade-info-main"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("toPre")(_vm.card.level_cost)))])]) : [_vm._v(_vm._s(_vm.$lang("Max")))]], 2), _vm.card.hasOwnProperty('level_upgradable') && _vm.card.activated && _vm.card.level_upgradable && _vm.card.level < _vm.gameInfo.maxLevel ? _c('div', {
    staticClass: "btn-upgrade-upgrade-info"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-anim-upgrade.png`),
      "alt": ""
    }
  })]) : _vm._e()])]), _c('div', {
    staticClass: "btn-upgrade btn-upgrade-star wow fadeInUp",
    class: _vm.card.star >= _vm.gameInfo.maxStar && 'max',
    attrs: {
      "data-wow-duration": "0.4s"
    },
    on: {
      "click": function ($event) {
        _vm.card.star < _vm.gameInfo.maxStar && _vm.onUpgradestar();
      }
    }
  }, [_c('div', {
    staticClass: "btn-upgrade-main"
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("UPGRADE STAR")))]), _c('div', {
    staticClass: "btn-upgrade-info"
  }, [_vm.card.star < _vm.gameInfo.maxStar ? _c('div', {
    staticClass: "btn-upgrade-info-main"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("toPre")(_vm.card.star_cost)))])]) : [_vm._v(_vm._s(_vm.$lang("Max")))]], 2), _vm.card.hasOwnProperty('level_upgradable') && _vm.card.activated && _vm.card.star_upgradable && _vm.card.star < _vm.gameInfo.maxStar ? _c('div', {
    staticClass: "btn-upgrade-upgrade-info"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-anim-upgrade.png`),
      "alt": ""
    }
  })]) : _vm._e()])]), _c('div', {
    staticClass: "btn-mint wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.4s"
    }
  }, [_c('div', {
    directives: [{
      name: "tip",
      rawName: "v-tip.top.click",
      value: `The platform will enable NFT minting. When a card's star rating and level are maxed, it can be minted into an NFT. The card's Power becomes the NFT's rating, and revenue is shared by NFT rating.`,
      expression: "`The platform will enable NFT minting. When a card's star rating and level are maxed, it can be minted into an NFT. The card's Power becomes the NFT's rating, and revenue is shared by NFT rating.`",
      modifiers: {
        "top": true,
        "click": true
      }
    }],
    staticClass: "tooltip"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-info.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "btn-mint-main"
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("MINT NFT")))])])])])]] : _vm._e()], 2)]), _vm.accountInfo.init >= 2 ? _c('m-menu') : _vm._e()], 1), _c('popover', {
    attrs: {
      "name": "rarity"
    }
  }, [_c('div', {
    staticClass: "tip-rarity-info"
  }, [_c('p', [_c('img', {
    attrs: {
      "src": require(`@images/ui/rarity-1.png`),
      "alt": ""
    }
  }), _vm._v("Normal")]), _c('p', [_c('img', {
    attrs: {
      "src": require(`@images/ui/rarity-2.png`),
      "alt": ""
    }
  }), _vm._v("Rare")]), _c('p', [_c('img', {
    attrs: {
      "src": require(`@images/ui/rarity-3.png`),
      "alt": ""
    }
  }), _vm._v("Super Rare")]), _c('p', [_c('img', {
    attrs: {
      "src": require(`@images/ui/rarity-4.png`),
      "alt": ""
    }
  }), _vm._v("Super Super Rare")]), _c('p', [_c('img', {
    attrs: {
      "src": require(`@images/ui/rarity-5.png`),
      "alt": ""
    }
  }), _vm._v("Ultra Rare")])])]), _c('div', {
    ref: "destoryCoinContainerRef",
    staticClass: "getCoinAnim-container"
  }), _c('pop-upgrade', {
    attrs: {
      "card": _vm.card,
      "show": _vm.popUpgradeShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popUpgradeShow = $event;
      }
    }
  }), _c('pop-upgradestar', {
    attrs: {
      "card": _vm.card,
      "show": _vm.popUpgradestarShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popUpgradestarShow = $event;
      }
    }
  }), _c('pop-prompt', {
    ref: "popPromptRef",
    attrs: {
      "show": _vm.popDestoryShow,
      "title": "Destroy",
      "content": "html"
    },
    on: {
      "update:show": function ($event) {
        _vm.popDestoryShow = $event;
      },
      "confirm": _vm.popDestoryConfirm
    }
  }, [_vm.card != null ? _c('div', {
    staticClass: "pop-destory"
  }, [_c('div', {
    staticClass: "pop-destory-balance"
  }, [_c('span', [_vm._v("x" + _vm._s(_vm._f("toPre")(_vm.card.cards)))])]), _c('div', {
    staticClass: "pop-destory-tip"
  }, [_vm._v(_vm._s(_vm.$lang("Cards to be destroyed")))]), _c('div', {
    staticClass: "pop-destory-input"
  }, [_c('div', {
    staticClass: "pop-destory-input-main"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.destroyNum,
      expression: "destroyNum"
    }],
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.destroyNum
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.destroyNum = $event.target.value;
      }, _vm.onInput]
    }
  }), _c('div', {
    staticClass: "pop-destory-btn",
    on: {
      "click": _vm.onAll
    }
  }, [_c('span', [_vm._v("All")])])]), _c('div', {
    staticClass: "pop-destory-info"
  }, [_c('em', [_vm._v("Refund:  ")]), _c('img', {
    attrs: {
      "src": require('@images/ui/icon-gold.png'),
      "alt": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm._f("toPre")(this.gameInfo.cardDestroy * this.destroyNum * this.card.rare)))])])])]) : _vm._e()]), _c('pop-activate-card', {
    attrs: {
      "show": _vm.popActivateCardShow,
      "card": _vm.card
    },
    on: {
      "update:show": function ($event) {
        _vm.popActivateCardShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };