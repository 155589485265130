const stop = body => body.style.overflow = "hidden";
const move = body => body.style.overflow = ""; //出现滚动条
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
export default {
  name: "popUpgrade",
  props: {
    show: Boolean,
    card: null
  },
  data() {
    return {
      isBtnLoading: false,
      isFlag: true,
      cardObj: null,
      isUpgradeStar: true,
      //是否升星
      isShowClose: true,
      isElementAnim: true
    };
  },
  computed: {
    ...gameVuex.mapState(["gameInfo"])
  },
  watch: {
    card(obj) {
      this.cardObj = obj;
    },
    show(bool) {
      this.$nextTick(() => {
        const body = document.querySelector("body");
        bool ? stop(body) : move(body);
        body.append ? body.append(this.$el) : body.appendChild(this.$el);
      });
      if (!bool) {
        this.initPopData();
      }
    }
  },
  beforeDestroy() {},
  methods: {
    ...gameVuex.mapMutations(["setCardList"]),
    ...gameVuex.mapActions(["upStarCard"]),
    initPopData() {
      this.isUpgradeStar = true;
      this.isBtnLoading = false;
      this.isFlag = true;
      this.isElementAnim = true;
    },
    setElementAnim() {
      setTimeout(() => {
        this.isElementAnim = true;
      }, 1200);
    },
    popUpgradeAnimEnter(e, callback) {
      if (this.isUpgradeStar) {
        this.enterBtnBottom(e, callback);
      } else {
        this.enterUpgradeItem(e, callback);
      }
    },
    animUpgradeCard() {
      const el = this.$refs.cardRef;
      if (!el) return;
      el.style.animation = "none";
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          el.style.animation = `popUpgradeCardAnim 0.4s ease-out`;
          el.style["animation-iteration-count"] = 1;
          setTimeout(() => {
            resolve();
          }, 400);
        }, 10);
      });
    },
    async onUpgrade() {
      if (!this.isFlag) {
        return;
      }
      this.isFlag = false;
      this.isBtnLoading = true;
      this.isShowClose = false;
      const res = await this.upStarCard({
        pony_id: this.cardObj.id
      });
      if (!res) {
        this.isFlag = true;
        this.isBtnLoading = false;
        this.isShowClose = true;
        return;
      }
      let cardData = null;
      res.cards.map(item => {
        if (item.gid === this.cardObj.gid) {
          item.cards.map(itm => {
            if (itm.id == this.cardObj.id) {
              cardData = {
                ...itm,
                gid: item.gid,
                group: item.name
              };
            }
          });
        }
      });
      if (!cardData) {
        this.isFlag = true;
        this.isBtnLoading = false;
        this.isShowClose = true;
        return;
      }
      this.isUpgradeStar = false;
      this.isElementAnim = false;
      await this.animUpgradeCard();
      this.cardObj = cardData;
      this.$parent.card = this.cardObj;
      this.$router.replace({
        query: this.cardObj
      });
      // this.setCardList(null);
      this.$nextTick(() => {
        // this.setCardList(res.cards);
        this.isUpgradeStar = true;
        this.isShowClose = true;
        this.isFlag = true;
        this.isBtnLoading = false;
      });
    }
  }
};