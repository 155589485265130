export default {
  name: "mRankItem",
  props: {
    item: {
      typeof: Object,
      default: () => {}
    },
    index: {
      typeof: [String, Number],
      default: 1
    }
  }
};