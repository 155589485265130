var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c('div', {
    staticClass: "popGuide",
    class: _vm.isGuideActive && 'active'
  }, [_c('div', {
    ref: "guideShapeRef",
    staticClass: "popGuide-shape",
    style: {
      ..._vm.shapeSize,
      opacity: _vm.isGuideShape ? 1 : 0
    },
    on: {
      "click": _vm.onShape
    }
  }, [_c('div', {
    ref: "guideHandRef",
    staticClass: "guideHand",
    style: {
      opacity: _vm.isHandShow ? 1 : 0
    }
  })])]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };