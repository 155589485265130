var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mAirdropRankItem"
  }, [_c('div', {
    staticClass: "code"
  }, [_c('div', {
    staticClass: "code-main"
  }, [_vm.index < 3 ? _c('img', {
    attrs: {
      "src": require(`@images/ui/rank-${_vm.index + 1}.png`),
      "alt": ""
    }
  }) : _c('span', [_vm._v(_vm._s(_vm.index + 1))])])]), _c('div', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.item.firstname) + " " + _vm._s(_vm.item.lastname))]), _c('div', {
    staticClass: "amount"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/${_vm.point === 'power' && 'icon-power-new' || _vm.point === 'coin' && 'icon-gold' || _vm.point === 'ref_count' && 'icon-airdop-invite'}.png`),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "num"
  }, [_vm.point === 'power' ? [_vm._v(" " + _vm._s(_vm._f("toPre")(_vm.item.point * 3600)) + " ")] : _vm.point === 'ref_count' ? [_vm._v(" " + _vm._s(_vm._f("toPre")(_vm.item.point, 0)) + " ")] : [_vm._v(_vm._s(_vm._f("toPre")(_vm.item.point, 2)) + " ")]], 2)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };