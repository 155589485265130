import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c('div', {
    staticClass: "popPrompt popPickCombination"
  }, [_c('div', {
    staticClass: "shadow",
    on: {
      "click": function ($event) {
        return _vm.$emit('update:show', false);
      }
    }
  }), _c('transition', {
    attrs: {
      "appear": "",
      "name": "popPromptBottom"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "popPrompt-main bottom"
  }, [_c('div', {
    staticClass: "btn-close",
    on: {
      "click": function ($event) {
        return _vm.$emit('update:show', false);
      }
    }
  }), _c('div', {
    staticClass: "popPrompt__title"
  }, [_vm._v(_vm._s(_vm.$lang("Select attack cards")))]), _c('div', {
    staticClass: "popPrompt-con"
  }, [_vm.cardList && _vm.cardList.length > 0 && _vm.cards && _vm.cards.length > 0 ? _c('div', {
    staticClass: "opponents-info"
  }, [_c('div', {
    staticClass: "opponents-info__title"
  }, [_vm._v(_vm._s(_vm.$lang("Opponents")) + ":")]), _c('div', {
    staticClass: "opponents-info-main"
  }, [_c('div', {
    staticClass: "opponents-info__name"
  }, [_vm._v(_vm._s(_vm.$parent.selectBattleCard.firstname) + " " + _vm._s(_vm.$parent.selectBattleCard.lastname))]), _c('div', {
    staticClass: "opponents-info__power"
  }, [_c('span', {
    style: {
      background: 'url(' + require('@images/ui/icon-arena-power.png') + ') center/auto 100% no-repeat'
    }
  }), _vm._v(_vm._s(_vm._f("toPre")(_vm.$parent.selectBattleCard.group_power * 3600)))])])]) : _vm._e(), _vm.cards ? _c('div', {
    staticClass: "index-list"
  }, _vm._l(_vm.cards, function (item, index) {
    return _c('div', {
      key: index
    }, [[_c('div', {
      staticClass: "index-con",
      class: _vm.selectCard && _vm.selectCard.gid == item.gid && 'active',
      on: {
        "click": function ($event) {
          return _vm.onSelect(item);
        }
      }
    }, [_c('div', {
      staticClass: "title"
    }, [_c('div', {
      staticClass: "title__label"
    }, [_vm._v(_vm._s(item.name))]), _c('div', {
      staticClass: "title-element"
    }, [_c('div', {
      staticClass: "title-element-item power"
    }, [_c('span', {
      style: {
        background: 'url(' + require('@images/ui/icon-arena-power.png') + ') center/auto 100% no-repeat'
      }
    }), _vm._v(_vm._s(_vm._f("toPre")(item.total_power * 3600)))])])]), _c('transition-group', {
      staticClass: "list",
      attrs: {
        "tag": "div",
        "appear": ""
      },
      on: {
        "before-enter": _vm.beforeEnter,
        "enter": _vm.enterList
      }
    }, _vm._l(item.cards, function (itm, idx) {
      return _c('div', {
        key: `l${idx}`,
        staticClass: "item",
        class: _vm.selectCard && _vm.selectCard.gid === item.gid,
        attrs: {
          "data-index": idx + 1
        }
      }, [_c('m-card-item', {
        attrs: {
          "detail": itm,
          "isShowUpdateLevelUI": false,
          "isShowUpdateStarUI": false
        }
      })], 1);
    }), 0), item.total_power > _vm.$parent.selectBattleCard.group_power ? _c('div', {
      staticClass: "lock",
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return (() => {}).apply(null, arguments);
        }
      }
    }, [_c('div', {
      staticClass: "lock-main"
    }, [_c('i', [_c('span'), _vm._v(" " + _vm._s(_vm.$lang("Can't exceed opponent's power"))), _c('br'), _vm._v("Please switch opponents")])])]) : _vm._e()], 1)]], 2);
  }), 0) : _vm._e(), (!_vm.cardList || _vm.cardList && _vm.cardList.length <= 0) && (!_vm.cards || _vm.cards && _vm.cards.length <= 0) ? _c('m-empty', {
    attrs: {
      "isBtn": true,
      "btnText": _vm.$lang('Shop')
    },
    on: {
      "confirm": () => _vm.$router.push('/shop')
    }
  }) : _vm._e()], 1), _vm.cards && _vm.cards.length > 0 && _vm.selectCard ? _c('div', {
    staticClass: "popPickCombination-btns",
    class: !_vm.isMobile && 'pc'
  }, [_c('div', {
    staticClass: "btn-confirm",
    on: {
      "click": _vm.onStart
    }
  }, [_vm._v(_vm._s(_vm.$lang("Battle Setting")))])]) : _vm._e()])]), _c('pop-prompt', {
    ref: "popSetAmountRef",
    attrs: {
      "show": _vm.popSetAmountShow,
      "isShadowClick": true,
      "title": "Battle Setting",
      "content": "html",
      "isBtnConfirmText": _vm.$lang('Start Battle')
    },
    on: {
      "update:show": function ($event) {
        _vm.popSetAmountShow = $event;
      },
      "confirm": _vm.popSetAmountConfirm
    }
  }, [_c('div', {
    staticClass: "pop-setAmount"
  }, [_c('div', {
    staticClass: "pop-setAmount-info"
  }, [_c('div', {
    staticClass: "pop-setAmount-balance"
  }, [_c('div', {
    staticClass: "pop-setAmount-balance__title"
  }, [_vm._v(_vm._s(_vm.$lang("My Balance")))]), _c('div', {
    staticClass: "pop-setAmount-balance__label"
  }, [_vm.$parent.selectTab == 'coin' ? [_c('img', {
    attrs: {
      "src": require('@images/ui/icon-gold.png'),
      "alt": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm._f("toPre")(_vm.accountInfo.coin)))])] : _vm.$parent.selectTab == 'usdt' ? [_c('img', {
    attrs: {
      "src": require('@images/ui/icon-usdt.png'),
      "alt": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm._f("toPre")(_vm.accountInfo.usdt)))])] : _vm.$parent.selectTab == 'pony' ? [_c('img', {
    attrs: {
      "src": require('@images/ui/pony.png'),
      "alt": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm._f("toPre")(_vm.accountInfo.pony)))])] : _vm._e()], 2)]), _c('div', {
    staticClass: "pop-setAmount-balance"
  }, [_c('div', {
    staticClass: "pop-setAmount-balance__title defender"
  }, [_vm._v(_vm._s(_vm.$lang("Opponent")))]), _c('div', {
    staticClass: "pop-setAmount-balance__label"
  }, [_vm.$parent.selectTab == 'coin' ? _c('img', {
    attrs: {
      "src": require('@images/ui/icon-gold.png'),
      "alt": ""
    }
  }) : _vm.$parent.selectTab == 'usdt' ? _c('img', {
    attrs: {
      "src": require('@images/ui/icon-usdt.png'),
      "alt": ""
    }
  }) : _vm.$parent.selectTab == 'pony' ? _c('img', {
    attrs: {
      "src": require('@images/ui/pony.png'),
      "alt": ""
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm._f("toPre")(_vm.$parent.selectBattleCard.amount)) + " ")])])]), _c('div', {
    staticClass: "pop-setAmount-input"
  }, [_c('div', {
    staticClass: "pop-setAmount-input__title"
  }, [_vm._v(_vm._s(_vm.$lang("Amount")))]), _c('div', {
    staticClass: "pop-setAmount-input-main"
  }, [_c('div', {
    staticClass: "pop-setAmount-input-box"
  }, [_vm._v(" " + _vm._s(_vm.amountVal) + " ")]), _c('div', {
    staticClass: "btn-all",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onClear.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang("CLEAR")))])]), _c('div', {
    staticClass: "popJoinArenaSelectAmount-list"
  }, _vm._l(_vm.amountList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "popJoinArenaSelectAmount-item",
      class: [item + _vm.amountVal > _vm.balance && 'disabled'],
      on: {
        "click": function ($event) {
          return _vm.onSelectAmount(item);
        }
      }
    }, [_vm._v("+" + _vm._s(item))]);
  }), 0)])])])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };