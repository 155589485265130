var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mEmpty"
  }, [_vm.content !== 'html' ? [_c('div', {
    staticClass: "mEmpty-text"
  }, [_vm._v(_vm._s(_vm.content))]), _vm.isBtn ? _c('div', {
    staticClass: "mEmpty-btn",
    on: {
      "click": _vm.onBtn
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.btnText))])]) : _vm._e()] : _c('div', {
    staticClass: "mEmpty-main"
  }, [_vm._t("default")], 2)], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };