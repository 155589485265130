const stop = (body) => (body.style.overflow = "hidden");
const move = (body) => (body.style.overflow = ""); //出现滚动条

const popDiv = document.createElement("div");
popDiv.id = "pop";
document.body.appendChild(popDiv);

export default {
  clearPopFunc() {
    const pop = document.getElementById("pop");
    while (pop.firstChild) {
      pop.removeChild(pop.firstChild);
    }
  },
  popFunc(bool, that) {
    return Promise.resolve().then((_) => {
      setTimeout(() => {
        const body = document.querySelector("body");
        const pop = document.getElementById("pop");
        bool ? stop(body) : move(body);
        if (pop.append) {
          pop.append(that.$el);
        } else {
          pop.appendChild(that.$el);
        }
      }, 0);
    });
  },
};
