var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mDaily"
  }, [_vm.task ? _c('div', {
    staticClass: "mDaily-total"
  }, [_c('div', {
    staticClass: "mDaily-total__status"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Daily Sign-In")) + ": "), _vm.task.status === 0 ? _c('span', {
    staticStyle: {
      "color": "#ecaf53"
    }
  }, [_vm._v(_vm._s(_vm.$lang("In progress")))]) : _vm.task.status === 1 ? _c('span', {
    staticStyle: {
      "color": "rgba(255, 255, 255, 0.9)"
    }
  }, [_vm._v(_vm._s(_vm.$lang("Finish")))]) : _vm.task.status === 2 ? _c('span', {
    staticStyle: {
      "color": "rgb(255, 74, 74)"
    }
  }, [_vm._v(_vm._s(_vm.$lang("Failed")))]) : _vm._e(), _c('span')]), _vm.task.status === 1 || _vm.task.status === 2 ? _c('div', {
    staticClass: "mDaily-total-btn",
    on: {
      "click": function ($event) {
        (_vm.task.status === 1 || _vm.task.status === 2) && _vm.onReopen();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang("Restart")) + " "), _vm.task.cost > 0 ? _c('span', [_c('img', {
    attrs: {
      "src": require('@images/ui/icon-diamonds.png'),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.task.cost) + " ")]) : _vm._e()]) : _vm._e()]) : _vm._e(), _vm.config ? [_vm.config.length > 0 ? [_c('transition-group', {
    staticClass: "mDaily-main",
    attrs: {
      "tag": "div",
      "appear": ""
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterSignTask
    }
  }, _vm._l(_vm.config, function (item, index) {
    return _c('div', {
      key: `s${index}`,
      staticClass: "mDaily-item",
      attrs: {
        "data-index": index + 1
      }
    }, [_c('div', {
      staticClass: "mDaily-item__day"
    }, [_vm._v("Day" + _vm._s(index + 1))]), _c('div', {
      staticClass: "mDaily-item-main",
      class: [item.signed && 'signed', index == _vm.distanceDay && _vm.task.status === 0 && 'today']
    }, [_c('div', {
      staticClass: "mDaily-item__icon"
    }, [item.asset === 'diamond' ? _c('img', {
      attrs: {
        "src": require(`@images/ui/icon-diamonds.png`),
        "alt": ""
      }
    }) : item.asset === 'card' ? _c('img', {
      attrs: {
        "src": require(`@images/ui/daily-icon-card.png`),
        "alt": ""
      }
    }) : item.asset === 'coin' ? _c('img', {
      attrs: {
        "src": require(`@images/ui/icon-gold.png`),
        "alt": ""
      }
    }) : _vm._e()]), _c('div', {
      staticClass: "mDaily-item__label"
    }, [_vm._v(_vm._s(_vm._f("toPre")(item.amount)))]), _c('div', {
      staticClass: "mDaily-item__status"
    }, [item.signed ? _c('img', {
      attrs: {
        "src": require(`@images/ui/daily-icon-ok.png`),
        "alt": ""
      }
    }) : _vm._e()]), item.asset === 'card' ? _c('div', {
      staticClass: "mDaily-item__cardTag"
    }, [_vm._v(_vm._s(item.rare == 1 && "N" || item.rare == 2 && "R" || item.rare == 3 && "SR" || item.rare == 4 && "SSR" || item.rare == 5 && "UR"))]) : _vm._e()]), index == _vm.distanceDay && _vm.task.status === 0 && !item.signed ? _c('div', {
      staticClass: "mDaily-item-btns",
      on: {
        "click": function ($event) {
          index == _vm.distanceDay && _vm.task.status === 0 && !item.signed && _vm.onClaim(item);
        }
      }
    }, [item.isClaimLoading ? _c('div', {
      staticClass: "circleLoading"
    }) : [_vm._v(" " + _vm._s(_vm.$lang("Claim")) + " ")]], 2) : _vm._e()]);
  }), 0)] : _vm._e()] : _vm._e(), _c('div', {
    ref: "destoryCoinContainerRef",
    staticClass: "getCoinAnim-container"
  }), _c('pop-prompt', {
    ref: "popReopenRef",
    attrs: {
      "show": _vm.popReopenShow,
      "content": "html"
    },
    on: {
      "update:show": function ($event) {
        _vm.popReopenShow = $event;
      },
      "confirm": _vm.popReopenConfirm
    }
  }, [_c('div', {
    staticClass: "pop-reopen"
  }, [_vm.task ? _c('div', {
    staticClass: "pop-reopen-text diamond"
  }, [_vm.task.cost > 0 ? [_vm._v(" " + _vm._s(_vm.$lang("Confirm to cost"))), _c('br'), _c('img', {
    attrs: {
      "src": require('@images/ui/icon-diamonds.png'),
      "alt": ""
    }
  }), _vm._v(_vm._s(_vm.task.cost) + " " + _vm._s(_vm.$lang("restart to Daily Sign-in")) + "? ")] : [_vm._v(_vm._s(_vm.$lang("confirm to restart Daily Sign-in")))]], 2) : _vm._e()])]), _c('pop-prompt', {
    ref: "popClaimRef",
    attrs: {
      "isShadowClick": true,
      "show": _vm.popClaimShow,
      "content": "html",
      "isShowLoading": false,
      "title": "",
      "title": _vm.$lang('Reward'),
      "isBtnConfirmText": _vm.claimReward && (_vm.claimReward.asset !== 'card' || _vm.claimReward.asset === 'card' && _vm.claimReward.cards.length > 1 ? _vm.$lang('Ok') : 'Check')
    },
    on: {
      "update:show": function ($event) {
        _vm.popClaimShow = $event;
      },
      "confirm": _vm.onConfirm
    }
  }, [_vm.claimReward ? _c('div', {
    staticClass: "pop-reopen"
  }, [_vm.claimReward.hasOwnProperty('cards') && _vm.claimReward.cards.length > 0 ? [_c('transition-group', {
    staticClass: "pop-reopen-card",
    attrs: {
      "tag": "div",
      "appear": ""
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterDailySignIn
    }
  }, _vm._l(_vm.claimReward.cards, function (itm, idx) {
    return _c('div', {
      key: `o${idx}`,
      staticClass: "pop-reopen-card-item",
      attrs: {
        "data-index": idx + 10
      }
    }, [_c('m-card-item', {
      attrs: {
        "detail": itm
      }
    }), _c('div', {
      staticClass: "chip"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/ui/chip.png`),
        "alt": ""
      }
    }), _c('span', [_vm._v("x" + _vm._s(itm.hasOwnProperty("amount") ? itm.amount : 1))])])], 1);
  }), 0)] : _vm._e()], 2) : _vm._e()])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };