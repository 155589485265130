var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "battle"
  }, [_c('div', {
    staticClass: "battle-main"
  }, [_c('div', {
    staticClass: "battle-head"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBattleHeadAnim,
      expression: "isBattleHeadAnim"
    }],
    staticClass: "battle-head-main"
  }, [_c('div', {
    staticClass: "battle-head-left wow battleHeadLeft",
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.attackUserName))])]), _c('div', {
    staticClass: "battle-head-center wow zoomIn",
    attrs: {
      "data-wow-duration": "0.2s"
    }
  }), _c('div', {
    staticClass: "battle-head-right wow battleHeadRight",
    attrs: {
      "data-wow-duration": "1s"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.defenderUserName))])])])]), _c('div', {
    staticClass: "battlefield"
  }, [_c('div', {
    staticClass: "battlefield-main"
  }, [_c('div', {
    ref: "defendersRef",
    staticClass: "defenders row"
  }, _vm._l(_vm.defender, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "card defender"
    }, [_c('div', {
      staticClass: "card-main",
      attrs: {
        "id": `defender${item.id}`
      }
    }, [_c('m-card-item', {
      attrs: {
        "detail": item,
        "isShowUpdateLevelUI": false,
        "isShowShadowUI": false,
        "isShowUpdateStarUI": false
      }
    })], 1), _c('div', {
      staticClass: "card-power"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/ui/battle-icon-power.png`),
        "alt": ""
      }
    }), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm._f("toPre")(item.power, 2)))])]), _c('div', {
      staticClass: "card-pogress"
    }, [_c('div', {
      staticClass: "progress-text"
    }, [_vm._v(_vm._s(_vm._f("toPrecision")(item.helth, 2)))]), _c('div', {
      staticClass: "progress-bar",
      style: {
        width: `${item.helth / item.totalHelth * 100}%`
      }
    }, [_c('div', {
      staticClass: "bar"
    })])])]);
  }), 0), _c('div', {
    ref: "attackRef",
    staticClass: "attackers row"
  }, _vm._l(_vm.attack, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "card attacker"
    }, [_c('div', {
      staticClass: "card-main",
      attrs: {
        "id": `attacker${item.id}`
      }
    }, [_c('m-card-item', {
      attrs: {
        "detail": item,
        "isShowUpdateLevelUI": false,
        "isShowShadowUI": false,
        "isShowUpdateStarUI": false
      }
    })], 1), _c('div', {
      staticClass: "card-power"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/ui/battle-icon-power.png`),
        "alt": ""
      }
    }), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm._f("toPre")(item.power, 2)))])]), _c('div', {
      staticClass: "card-pogress"
    }, [_c('div', {
      staticClass: "progress-text"
    }, [_vm._v(_vm._s(_vm._f("toPrecision")(item.helth, 2)))]), _c('div', {
      staticClass: "progress-bar",
      style: {
        width: `${item.helth / item.totalHelth * 100}%`
      }
    }, [_c('div', {
      staticClass: "bar"
    })])])]);
  }), 0)])]), _vm.isBattleHeadAnim ? _c('div', {
    staticClass: "battle-btns"
  }, [_c('div', {
    staticClass: "btn-jump wow fadeInRight",
    attrs: {
      "data-wow-duration": "1s"
    },
    on: {
      "click": _vm.onJump
    }
  })]) : _vm._e()]), _c('div', {
    staticClass: "attack-effect",
    attrs: {
      "id": "attack-effect"
    }
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };