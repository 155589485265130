var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mRankItem"
  }, [_c('div', {
    staticClass: "code"
  }, [_c('div', {
    staticClass: "code-main"
  }, [_vm.index < 3 ? _c('img', {
    attrs: {
      "src": require(`@images/ui/rank-${_vm.index + 1}.png`),
      "alt": ""
    }
  }) : _c('span', [_vm._v(_vm._s(_vm.index + 1))])])]), _c('div', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.item.firstname) + " " + _vm._s(_vm.item.lastname))]), _c('div', {
    staticClass: "amount"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/${_vm.item.type === 'coin' ? 'icon-gold' : 'icon-power-new'}.png`),
      "alt": ""
    }
  }), _vm.item.type === 'coin' ? _c('div', {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm._f("toPre")(_vm.item.coin)))]) : _c('div', {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm._f("toPre")(_vm.item.power * 3600)))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };