var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "draw"
  }, [_c('m-assets'), _c('div', {
    staticClass: "draw-body page-body"
  }, [_c('div', {
    staticClass: "draw-main page-main"
  }, [_c('div', {
    staticClass: "result",
    attrs: {
      "id": "drawResultDraw"
    }
  }, [_c('div', {
    staticClass: "result-title"
  }, [_vm._v(_vm._s(_vm.$lang("SHOP")))]), _c('div', {
    ref: "resultMainRef",
    staticClass: "result-main"
  }, [_vm.drawNum === 10 || _vm.drawNum === 5 ? _c('div', {
    staticClass: "result-list"
  }, [_vm.drawCardList ? [_vm.drawCardList.length > 0 ? _vm._l(_vm.drawCardList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "result-item",
      attrs: {
        "data-type": item.rare
      }
    }, [_c('div', {
      staticClass: "result-back",
      class: item.isShow && 'isShow'
    }, [_c('m-card-item', {
      attrs: {
        "detail": item
      }
    })], 1)]);
  }) : _vm._e()] : [_c('div', {
    staticClass: "circleLoading"
  })]], 2) : _vm.drawNum === 1 ? _c('div', {
    staticClass: "result-list"
  }, [_vm.drawCardList ? [_vm.drawCardList.length > 0 ? [_c('div', {
    staticClass: "result-item one"
  }, [_c('div', {
    staticClass: "result-item-o",
    attrs: {
      "data-type": _vm.drawCardList[0].rare
    }
  }, [_c('div', {
    staticClass: "result-back",
    class: _vm.drawCardList[0].isShow && 'isShow'
  }, [_c('m-card-item', {
    attrs: {
      "detail": _vm.drawCardList[0]
    }
  })], 1)])])] : _vm._e()] : [_c('div', {
    staticClass: "circleLoading"
  })]], 2) : _vm._e()]), _c('div', {
    staticClass: "btns"
  }, [_vm.isBtnsShow ? [_c('div', {
    staticClass: "btn-draw wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.2s"
    },
    on: {
      "click": function ($event) {
        return _vm.onDraw(1, 'diamond');
      }
    }
  }, [_c('div', {
    staticClass: "btn-draw-main"
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("Draw x1")))]), _c('div', {
    staticClass: "btn-draw-info"
  }, [_c('div', {
    staticClass: "btn-draw-info-main"
  }, [_c('span', [_vm._v(_vm._s(_vm.drawCost))])])])])]), _c('div', {
    staticClass: "btn-draw btn-draw-5 wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.4s"
    },
    on: {
      "click": function ($event) {
        return _vm.onDraw(5, 'diamond');
      }
    }
  }, [_c('div', {
    staticClass: "btn-draw-main",
    attrs: {
      "id": "shopDraw5"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("Draw x5")))]), _c('div', {
    staticClass: "btn-draw-info"
  }, [_c('div', {
    staticClass: "btn-draw-info-main"
  }, [_c('span', [_vm._v(_vm._s(this.drawCost * 5))])])])])]), _c('div', {
    staticClass: "btn-draw btn-draw-10 wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.6s"
    },
    on: {
      "click": function ($event) {
        return _vm.onDraw(10, 'diamond');
      }
    }
  }, [_c('div', {
    staticClass: "btn-draw-main"
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("Draw x10")))]), _c('div', {
    staticClass: "btn-draw-info"
  }, [_c('div', {
    staticClass: "btn-draw-info-main"
  }, [_c('span', [_vm._v(_vm._s(this.drawCost * 10))])])])])]), _c('div', {
    staticClass: "btn-coin-draw wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.8s"
    },
    on: {
      "click": function ($event) {
        return _vm.onDraw(1, 'coin');
      }
    }
  }, [_c('div', {
    staticClass: "btn-coin-draw-main"
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("Coin Draw for Max SR Rarity Card")))]), _vm.coinDrawCard <= 0 ? _c('div', {
    staticClass: "btn-mine-info"
  }, [_c('div', {
    staticClass: "btn-mine-info-main"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("toPre")(this.gameInfo.cardCoin)))])])]) : _vm.coinDrawCard > 0 ? _c('div', {
    staticClass: "btn-mine-info time"
  }, [_c('div', {
    staticClass: "btn-mine-info-main"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("formatDay")(this.coinDrawCard)))])])]) : _vm._e()])])] : _vm._e()], 2)]), _c('Transition', {
    attrs: {
      "name": "activityAnim"
    }
  }, [_vm.activityCard ? _c('div', {
    staticClass: "activity"
  }, [_c('div', {
    staticClass: "activity-title"
  }, [_vm._v(_vm._s(_vm.$lang("Activity")))]), _c('div', {
    staticClass: "activity-list"
  }, [_c('div', {
    staticClass: "activity-item"
  }, [_c('div', {
    staticClass: "time"
  }, [_vm.activityTime < 0 ? [_c('i', [_vm._v(_vm._s(_vm.$lang("Not Started")))]), _c('span', {
    staticClass: "noStarted"
  }, [_vm._v(_vm._s(_vm._f("formatDay")(Math.abs(_vm.activityTime))))])] : _vm.activityTime == 'end' ? [_vm._v(" " + _vm._s(_vm.$lang("Ended")) + " ")] : _vm.activityCard.sold >= _vm.activityCard.amount ? [_vm._v(" " + _vm._s(_vm.$lang("Solded")) + " ")] : _vm.activityTime > 0 ? _c('span', [_vm._v(_vm._s(_vm._f("formatDay")(_vm.activityTime)))]) : _vm._e()], 2), _c('div', {
    staticClass: "activity-card"
  }, [_c('m-card-item', {
    attrs: {
      "detail": _vm.activityCard
    }
  })], 1), _c('div', {
    staticClass: "activity-info"
  }, [_c('div', {
    staticClass: "activity__name"
  }, [_vm._v(_vm._s(_vm.activityCard.name))]), _c('div', {
    staticClass: "activity__add"
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("Stock")) + ": ")]), _vm._v(" "), _c('i', [_vm._v(_vm._s(_vm._f("toPre")(_vm.activityCard.amount - _vm.activityCard.sold)))]), _vm._v("/" + _vm._s(_vm._f("toPre")(_vm.activityCard.amount)) + " ")]), _c('div', {
    staticClass: "btn",
    class: (_vm.activityCard.sold >= _vm.activityCard.amount || _vm.activityTime < 0 || _vm.activityTime == 'end') && 'active'
  }, [_c('div', {
    staticClass: "btn-main",
    on: {
      "click": function ($event) {
        (_vm.activityCard.sold < _vm.activityCard.amount || _vm.activityTime > 0) && _vm.onBuy();
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm._f("toPre")(_vm.activityCard.price)))])])])])])])]) : _vm._e()])], 1), _c('m-menu')], 1), _c('transition', {
    attrs: {
      "name": "ssrResult"
    }
  }, [_vm.isPopTenCardSSR && _vm.rowSSRItem != null ? _c('div', {
    ref: "popTenCardSSRRef",
    staticClass: "popTenCardSSr"
  }, [_c('div', {
    staticClass: "shadow"
  }), _c('div', {
    staticClass: "popTenCardSSr-main"
  }, [_c('div', {
    staticClass: "card-light"
  }), _c('div', {
    staticClass: "card"
  }, [_c('m-card-item', {
    attrs: {
      "detail": _vm.rowSSRItem
    }
  }), _c('div', {
    staticClass: "card-info"
  }, [_vm._v(_vm._s(_vm.$lang("Tap Continue")))])], 1)])]) : _vm._e()]), _c('pop-prompt', {
    attrs: {
      "show": _vm.popPromptShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popPromptShow = $event;
      }
    }
  }), _c('pop-prompt', {
    ref: "popBuyCardRef",
    attrs: {
      "show": _vm.popBuyCardShow,
      "title": "Activity",
      "content": "html",
      "isBtnConfirmText": _vm.$lang('Buy')
    },
    on: {
      "update:show": function ($event) {
        _vm.popBuyCardShow = $event;
      },
      "confirm": _vm.popBuyCardConfirm
    }
  }, [_c('div', {
    staticClass: "pop-buy"
  }, [_c('div', {
    staticClass: "pop-buy-tip"
  }, [_vm._v(_vm._s(_vm.$lang("Purchase Card Quantity")))]), _c('div', {
    staticClass: "pop-buy-input"
  }, [_c('div', {
    staticClass: "pop-buy-input-main"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.buyCardNum,
      expression: "buyCardNum"
    }],
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.buyCardNum
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.buyCardNum = $event.target.value;
      }, _vm.onInput]
    }
  })]), _c('div', {
    staticClass: "pop-buy-btn",
    on: {
      "click": _vm.onAll
    }
  }, [_c('span', [_vm._v("All")])]), _vm.activityCard ? _c('div', {
    staticClass: "pop-buy-info"
  }, [_c('em', [_vm._v(_vm._s(_vm.$lang("Cost")) + ":  ")]), _c('img', {
    attrs: {
      "src": require('@images/ui/icon-diamonds.png'),
      "alt": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm._f("toPre")(_vm.activityCard.price * _vm.buyCardNum)))])]) : _vm._e()])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };