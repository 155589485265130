var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "index"
  }, [[_vm.isShowArena ? _c('m-arena-assets', {
    ref: "mAssetsRef"
  }) : _c('m-assets', {
    ref: "mAssetsRef"
  })], _c('div', {
    staticClass: "index-body page-body"
  }, [_c('div', {
    staticClass: "index-main page-main"
  }, [_c('div', {
    staticClass: "btn-total",
    on: {
      "click": _vm.onBtnTotal
    }
  }, [_c('div', {
    staticClass: "btn-total-main"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Total power:")) + " "), _c('i', [_vm._v(_vm._s(_vm._f("toPre")(_vm.accountInfo.power * 3600, 2)) + " Coin/h")])])]), _vm.cardList ? _c('div', {
    staticClass: "index-list"
  }, _vm._l(_vm.cardList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "index-con",
      class: [_vm.isShowArena && (item.hasOwnProperty('battle_coin') || item.hasOwnProperty('battle_usdt') || item.hasOwnProperty('battle_pony')) && item.active_count > 0 && '_arena']
    }, [_c('div', {
      staticClass: "title"
    }, [_c('div', {
      staticClass: "title__label"
    }, [_vm._v(_vm._s(item.name))]), _c('div', {
      staticClass: "title__addition"
    }, [_vm._v(" " + _vm._s(_vm.$lang("Addition:")) + " "), item.hasOwnProperty('activated') && item.activated ? _c('i', [_vm._v("+" + _vm._s(_vm._f("toPrecision")(item.additional * 100, 2)) + "%")]) : _c('i', [_vm._v("+?%"), _c('img', {
      directives: [{
        name: "tip",
        rawName: "v-tip.top.click",
        value: `Activate all cards in the set for an additional power boost.`,
        expression: "`Activate all cards in the set for an additional power boost.`",
        modifiers: {
          "top": true,
          "click": true
        }
      }],
      attrs: {
        "src": require('@images/ui/icon-question.png'),
        "alt": ""
      }
    })])])]), _c('div', {
      on: {
        "click": function ($event) {
          return _vm.onCheckArena(item);
        }
      }
    }, [_c('transition-group', {
      staticClass: "list",
      attrs: {
        "tag": "div",
        "appear": ""
      },
      on: {
        "before-enter": _vm.beforeEnter,
        "enter": _vm.enterList
      }
    }, _vm._l(item.cards, function (itm, idx) {
      return _c('div', {
        key: `l${idx}`,
        staticClass: "item",
        attrs: {
          "id": itm.id === 'p0101' && `_${itm.id}`,
          "data-index": idx + 1
        },
        on: {
          "click": function ($event) {
            return _vm.onDetail(itm, item);
          }
        }
      }, [_c('m-card-item', {
        attrs: {
          "detail": itm,
          "isShowUpdateStarUI": item.hasOwnProperty('battle_coin') || item.hasOwnProperty('battle_usdt') || item.hasOwnProperty('battle_pony') ? false : true,
          "isShowUpdateLevelUI": item.hasOwnProperty('battle_coin') || item.hasOwnProperty('battle_usdt') || item.hasOwnProperty('battle_pony') ? false : true
        }
      }), itm.cards >= 0 ? _c('div', {
        staticClass: "chip",
        style: {
          opacity: itm.cards <= 0 ? 0.3 : 1
        }
      }, [_c('img', {
        attrs: {
          "src": require(`@images/ui/chip.png`),
          "alt": ""
        }
      }), _c('span', [_vm._v("x" + _vm._s(itm.cards))])]) : _vm._e()], 1);
    }), 0), _vm.isShowArena && item.active_count > 0 ? _c('div', {
      staticClass: "arena"
    }, [item.hasOwnProperty('battle_coin') || item.hasOwnProperty('battle_usdt') || item.hasOwnProperty('battle_pony') ? _c('div', {
      staticClass: "arena-main"
    }, [item.hasOwnProperty('battle_coin') ? _c('div', {
      staticClass: "arena-item _coin",
      on: {
        "click": function ($event) {
          _vm.gameInfo.battleCoinBet && item.battle_coin.amount < _vm.gameInfo.battleCoinBet[0] && _vm.onAmountNoLongerInfo($event, item, 'coin');
        }
      }
    }, [_c('span', [_vm._v(_vm._s(_vm._f("toPre")(item.battle_coin.amount)) + " "), _vm.gameInfo.battleCoinBet && item.battle_coin.amount < _vm.gameInfo.battleCoinBet[0] ? _c('svg-icon', {
      attrs: {
        "name": "icon-warn"
      }
    }) : _vm._e()], 1), item.battle_coin.amount - item.battle_coin.init_amount != 0 ? _c('i', {
      class: item.battle_coin.amount - item.battle_coin.init_amount > 0 ? 'arena-item__add' : 'arena-item__reduce'
    }, [_c('svg-icon', {
      staticClass: "icon-increase-add",
      attrs: {
        "name": "icon-Increase"
      }
    }), _vm._v(" " + _vm._s(_vm._f("toPre")(item.battle_coin.amount - item.battle_coin.init_amount, 2)) + " ")], 1) : _c('i', [_vm._v(_vm._s(_vm.$lang("COIN")))])]) : _vm._e(), item.hasOwnProperty('battle_usdt') ? _c('div', {
      staticClass: "arena-item _usdt",
      on: {
        "click": function ($event) {
          _vm.gameInfo.battleUsdtBet && item.battle_usdt.amount < _vm.gameInfo.battleUsdtBet[0] && _vm.onAmountNoLongerInfo($event, item, 'usdt');
        }
      }
    }, [_c('span', [_vm._v(_vm._s(_vm._f("toPre")(item.battle_usdt.amount)) + " "), _vm.gameInfo.battleUsdtBet && item.battle_usdt.amount < _vm.gameInfo.battleUsdtBet[0] ? _c('svg-icon', {
      attrs: {
        "name": "icon-warn"
      }
    }) : _vm._e()], 1), item.battle_usdt.amount - item.battle_usdt.init_amount != 0 ? _c('i', {
      class: item.battle_usdt.amount - item.battle_usdt.init_amount > 0 ? 'arena-item__add' : 'arena-item__reduce'
    }, [_c('svg-icon', {
      staticClass: "icon-increase-add",
      attrs: {
        "name": "icon-Increase"
      }
    }), _vm._v(" " + _vm._s(_vm._f("toPre")(item.battle_usdt.amount - item.battle_usdt.init_amount, 2)) + " ")], 1) : _c('i', [_vm._v(_vm._s(_vm.$lang("USDT")))])]) : _vm._e(), item.hasOwnProperty('battle_pony') ? _c('div', {
      staticClass: "arena-item _pony",
      on: {
        "click": function ($event) {
          _vm.gameInfo.battlePonyBet && item.battle_pony.amount < _vm.gameInfo.battlePonyBet[0] && _vm.onAmountNoLongerInfo($event, item, 'pony');
        }
      }
    }, [_c('span', [_vm._v(_vm._s(_vm._f("toPre")(item.battle_pony.amount)) + " "), _vm.gameInfo.battlePonyBet && item.battle_pony.amount < _vm.gameInfo.battlePonyBet[0] ? _c('svg-icon', {
      attrs: {
        "name": "icon-warn"
      }
    }) : _vm._e()], 1), item.battle_pony.amount - item.battle_pony.init_amount != 0 ? _c('i', {
      class: item.battle_pony.amount - item.battle_pony.init_amount > 0 ? 'arena-item__add' : 'arena-item__reduce'
    }, [_c('svg-icon', {
      staticClass: "icon-increase-add",
      attrs: {
        "name": "icon-Increase"
      }
    }), _vm._v(" " + _vm._s(_vm._f("toPre")(item.battle_pony.amount - item.battle_pony.init_amount, 2)) + " ")], 1) : _c('i', [_vm._v(_vm._s(_vm.$lang("PONY")))])]) : _vm._e()]) : [_c('div', {
      staticClass: "arena-main _join"
    }, [_c('div', {
      staticClass: "title__addition"
    }, [_c('img', {
      staticClass: "arenaPowerIcon",
      attrs: {
        "src": require('@images/ui/icon-arena-power.png'),
        "alt": ""
      }
    }), _c('i', [_vm._v(_vm._s(_vm._f("toPre")(item.total_power * 3600)))])]), _c('div', {
      staticClass: "join-btns"
    }, [_c('div', {
      staticClass: "btn-join",
      on: {
        "click": function ($event) {
          return _vm.onBtnJoin(item);
        }
      }
    }, [_c('span', [_vm._v(_vm._s(_vm.$lang("Join Arena")))])])])])]], 2) : _vm._e()], 1)]);
  }), 0) : _vm._e()])]), _c('div', {
    staticClass: "float-btns"
  }, [_c('div', {
    ref: "btnAirDropRef",
    staticClass: "btn-airdrop"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBtnAirdropShow && _vm.accountInfo.show_airdrop,
      expression: "isBtnAirdropShow && accountInfo.show_airdrop"
    }],
    staticClass: "btn-airdrop-main",
    on: {
      "click": _vm.onLuckyAirdropInfoShow
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/btn-airdrop.png`),
      "alt": ""
    }
  }), _vm.dropTimeCountdown ? _c('div', {
    staticClass: "btn-airdrop-time"
  }, [_vm._v(_vm._s(_vm._f("formatDay")(_vm.dropTimeCountdown)))]) : _vm._e()])]), _c('div', {
    staticClass: "btn-payDiamond"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBtnPayDiamondShow,
      expression: "isBtnPayDiamondShow"
    }],
    staticClass: "btn-payDiamond-main",
    on: {
      "click": _vm.onPayDiaondShow
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/btn-pay-diamond-info.png`),
      "alt": ""
    }
  })])])]), _c('m-nav'), _c('m-menu', {
    ref: "mMenuRef"
  }), _c('div', {
    ref: "destoryCoinContainerRef",
    staticClass: "getCoinAnim-container"
  }), _c('pop-activate-card', {
    attrs: {
      "show": _vm.popActivateCardShow,
      "card": _vm.selectCardRow
    },
    on: {
      "update:show": function ($event) {
        _vm.popActivateCardShow = $event;
      }
    }
  }), _c('pop-income', {
    ref: "popIncomeRef",
    attrs: {
      "show": _vm.popIncomeShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popIncomeShow = $event;
      }
    }
  }), _c('pop-join-arena', {
    ref: "popJoinArenaRef",
    attrs: {
      "show": _vm.popJoinArenaShow,
      "detail": _vm.selectGroup
    },
    on: {
      "update:show": function ($event) {
        _vm.popJoinArenaShow = $event;
      },
      "update:detail": function ($event) {
        _vm.selectGroup = $event;
      }
    }
  }), _c('pop-reward', {
    attrs: {
      "show": _vm.popRewardShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popRewardShow = $event;
      },
      "anim": _vm.popRewardAnim
    }
  }), _c('pop-lucky-airdrop-info', {
    attrs: {
      "show": _vm.popLuckyAirdropInfoShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popLuckyAirdropInfoShow = $event;
      }
    }
  }), _c('pop-pay-diamond-info', {
    attrs: {
      "show": _vm.popPayDiamondInfoShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popPayDiamondInfoShow = $event;
      }
    }
  }), _c('pop-prompt', {
    attrs: {
      "show": _vm.popAmountNoLongerInfoShow,
      "content": "html",
      "isShadowClick": true,
      "isShowLoading": false,
      "isBtnConfirmText": "Quit"
    },
    on: {
      "update:show": function ($event) {
        _vm.popAmountNoLongerInfoShow = $event;
      },
      "confirm": _vm.popAmountNoLongerInfoConfirm
    }
  }, [_c('div', {
    staticClass: "popAmountNoLongerInfoShow"
  }, [_c('div', {
    staticClass: "popAmountNoLongerInfoShow__title"
  }, [_vm._v("funds are below the minimum bet amount and will no longer appear in the arena.")])])]), _c('pop-prompt', {
    ref: "popBattleQuitRef",
    attrs: {
      "show": _vm.popBattleQuitShow,
      "content": "html",
      "title": "Quit Hint"
    },
    on: {
      "update:show": function ($event) {
        _vm.popBattleQuitShow = $event;
      },
      "confirm": _vm.popBattleQuitConfirm
    }
  }, [_c('div', {
    staticClass: "popBattleQuit"
  }, [_vm.remainingAmount === 0 ? [_vm._v(" " + _vm._s(_vm.$lang("Exit the arena?")) + " ")] : [_vm._v(" You " + _vm._s(_vm.remainingAmount > 0 ? "earned" : "lost") + " "), _c('img', {
    attrs: {
      "src": require(`@images/ui/${_vm.assetType === 'coin' && 'icon-gold' || _vm.assetType === 'usdt' && 'icon-usdt' || _vm.assetType === 'pony' && 'icon-pony'}.png`),
      "alt": ""
    }
  }), _vm._v(" "), _c('i', {
    class: _vm.remainingAmount > 0 ? 'earned' : 'lost'
  }, [_vm._v(_vm._s(_vm._f("toPre")(Math.abs(_vm.remainingAmount))))]), _vm._v(" this time. "), _c('br'), _vm._v("Exit the arena? ")]], 2)])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };