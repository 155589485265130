const stop = body => body.style.overflow = "hidden";
const move = body => body.style.overflow = ""; //出现滚动条
export default {
  name: "popPrompt",
  props: {
    show: Boolean,
    content: {
      typeof: String,
      default: ""
    },
    isBtnConfirmText: {
      typeof: [String, Number],
      default: "Confirm"
    },
    title: {
      typeof: [String, Number],
      default: "Hint"
    },
    isShowLoading: {
      typeof: Boolean,
      default: true
    },
    isBtnConfirm: {
      typeof: Boolean,
      default: true
    },
    isClose: {
      typeof: Boolean,
      default: true
    },
    isShadowClick: {
      typeof: Boolean,
      default: false
    },
    isTitle: {
      typeof: Boolean,
      default: true
    },
    className: {
      typeof: String,
      default: ""
    }
  },
  data() {
    return {
      isLoading: false
    };
  },
  watch: {
    show(bool) {
      this.popFunc(bool, this);
    }
  },
  methods: {
    onClose() {
      this.$emit("close");
      this.$emit("update:show", false);
    },
    onConfirm() {
      this.isLoading = true;
      this.$emit("confirm", () => {
        this.isLoading = false;
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      const popPromptRef = this.$refs.popPromptRef;
      if (!popPromptRef) return;
      popPromptRef.addEventListener("wheel", event => {
        const isAtTop = popPromptRef.scrollTop === 0;
        const isAtBottom = popPromptRef.scrollTop + popPromptRef.clientHeight === popPromptRef.scrollHeight;

        // Prevent scrolling the body if not at the top or bottom of the div
        if (!(isAtTop && event.deltaY < 0) && !(isAtBottom && event.deltaY > 0)) {
          event.stopPropagation(); // 阻止事件冒泡
        }
      });
    });
  }
};