var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mNav",
    class: [_vm.version !== 1 && 'v1']
  }, _vm._l(_vm.navList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item wow fadeInUp",
      attrs: {
        "data-wow-duration": `${(index + 1) * 0.1}s`
      },
      on: {
        "click": function ($event) {
          return _vm.onNav(item.value);
        }
      }
    }, [_c('div', {
      staticClass: "item-main",
      class: [item.value === '/shop' && 'shop', item.value === '/airdrop' && 'airdrop', item.value === '/task' && 'task', item.value == '/rank' && 'rank']
    }, [item.value === '/airdrop' && _vm.notifyInfo.airdrop || item.value === '/task' && (_vm.notifyInfo.dailySign || _vm.notifyInfo.task) ? _c('div', {
      staticClass: "tag"
    }) : _vm._e(), _c('div', {
      staticClass: "item-con",
      attrs: {
        "id": item.value === '/shop' && 'navShop'
      }
    }, [_c('div', {
      staticClass: "item__icon"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/ui/${item.icon}.png`),
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "item__label"
    }, [_vm._v(_vm._s(item.label))])])])]);
  }), 0);
};
var staticRenderFns = [];
export { render, staticRenderFns };