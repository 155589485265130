import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
export default {
  name: "popPayDiamondInfo",
  props: {
    show: Boolean
  },
  computed: {
    ...userVuex.mapState(["accountInfo", "payDiamondInterReward"])
  },
  watch: {
    show(bool) {
      this.popFunc(bool, this);
      if (bool) {
        this.setWowAnim();
      } else {}
    }
  },
  data() {
    return {
      isFlag: true,
      isLoading: false,
      rewards: null,
      isShowAirDrop: false,
      userInfo: null,
      cards: null
    };
  },
  methods: {
    onClose() {
      this.$emit("update:show", false);
    },
    onSkip() {
      console.log(22);
      this.$router.push("/diamond");
    }
  }
};