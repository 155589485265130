/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./mDiamondItem.vue?vue&type=template&id=fd7fd394&scoped=true"
import script from "./mDiamondItem.vue?vue&type=script&lang=js"
export * from "./mDiamondItem.vue?vue&type=script&lang=js"
import style0 from "./mDiamondItem.vue?vue&type=style&index=0&id=fd7fd394&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd7fd394",
  null
  
)

export default component.exports