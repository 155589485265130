var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mTask"
  }, [_c('div', {
    staticClass: "task-list"
  }, [_c('div', {
    staticClass: "task-con"
  }, [_c('transition-group', {
    staticClass: "list",
    attrs: {
      "tag": "div",
      "appear": ""
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterList
    }
  }, [_c('div', {
    key: "a",
    staticClass: "title",
    attrs: {
      "data-index": 1
    }
  }, [_vm._v(_vm._s(_vm.$lang("Official")))]), _vm._l(_vm.task, function (item, index) {
    return _c('div', {
      key: `a${index}`,
      staticClass: "item",
      class: item.id === 4 && 'txe',
      attrs: {
        "data-index": index + 1
      },
      on: {
        "click": function ($event) {
          return _vm.onSkip(item);
        }
      }
    }, [item.id === 4 ? _c('div', {
      staticClass: "item_txe"
    }, [_vm._v("Extra Task")]) : _vm._e(), item.icon ? _c('div', {
      staticClass: "item-icon",
      class: item.icon && 'active'
    }, [_c('img', {
      attrs: {
        "src": item.icon,
        "alt": ""
      }
    })]) : _c('div', {
      staticClass: "item-type"
    }, [_c('svg-icon', {
      attrs: {
        "name": `icon-${item.type}`
      }
    })], 1), _c('div', {
      staticClass: "item-info"
    }, [_c('div', {
      staticClass: "item__title"
    }, [_vm._v(_vm._s(item.title))]), _c('div', {
      staticClass: "item__value"
    }, [_c('span', {
      style: {
        background: 'url(' + require(`@images/ui/${item.asset === 'coin' && 'icon-gold' || item.asset === 'diamond' && 'icon-diamonds' || item.asset === 'usdt' && 'icon-usdt' || item.asset === 'pony' && 'icon-pony'}.png`) + ') center/auto 100% no-repeat'
      }
    }), _vm._v(" +" + _vm._s(item.reward || _vm.toPre) + " ")])]), _c('div', {
      staticClass: "item-arrow"
    }, [item.status == 0 ? [item.isLoading ? _c('div', {
      staticClass: "circleLoading"
    }) : _c('svg-icon', {
      attrs: {
        "name": "icon-arrow"
      }
    })] : [_c('svg-icon', {
      staticClass: "icon-ok",
      attrs: {
        "name": "icon-ok"
      }
    })]], 2)]);
  })], 2)], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };