import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
export default {
  name: "mNav",
  data() {
    const navList = [{
      label: this.$lang("Shop"),
      value: "/shop",
      icon: "icon-shop"
    }, {
      label: this.$lang("Airdrop"),
      value: "/airdrop",
      icon: "icon-airdrop"
    }, {
      label: this.$lang("Task"),
      value: "/task",
      icon: "icon-task"
    }];
    return {
      navList,
      onDrawHandler: null
    };
  },
  computed: {
    ...gameVuex.mapState(["notifyInfo"])
  },
  methods: {
    onNav(value) {
      this.$router.push(value);
    },
    setNav() {
      if (this.version !== 1) {
        const rankNav = {
          label: this.$lang("Ranking"),
          value: "/rank",
          icon: "icon-ranking"
        };
        this.navList.push(rankNav);
        console.log("this.navList", this.navList);
      }
    }
  },
  created() {
    this.setNav();
    this.onDrawHandler = this.onNav.bind(this, "/shop");
  },
  mounted() {
    this.EventBus.$off("onGuideEvent", this.onDrawHandler);
    this.EventBus.$on("onGuideEvent", this.onDrawHandler);
  }
};