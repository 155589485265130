import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
export default {
  name: "mArenaAssets",
  props: {
    gold: {
      type: [String, Number],
      default: "--"
    },
    diamond: {
      type: [String, Number],
      default: "--"
    }
  },
  data() {
    return {
      isAssetCoin: false,
      popAddEnergyShow: false,
      path: ""
    };
  },
  watch: {
    "accountInfo.coin": {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.isAssetCoin = true;
          setTimeout(() => {
            this.isAssetCoin = false;
          }, 100);
        }
      }
    },
    "$route.path": {
      handler(path) {
        this.path = path;
        console.log("this.path", this.path);
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...userVuex.mapState(["accountInfo", "isPayPonyInterLoading", "isPayUsdtInterLoading", "isPayDiamondInterLoading"]),
    ...gameVuex.mapState(["gameInfo"])
  },
  methods: {
    onSkip(type) {
      if (type === "usdt" || type === "pony") {
        this.$router.push("/assets");
      } else if (type === "diamond") {
        this.$router.push("/diamond");
      }
    },
    onEnergyPay() {
      this.popAddEnergyShow = true;
    }
  }
};