var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mMenu"
  }, _vm._l(_vm.menuList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item",
      class: _vm.selectMenu === item.value && 'active',
      on: {
        "click": function ($event) {
          return _vm.onMenu(item);
        }
      }
    }, [_c('div', {
      ref: `menu${item.name}`,
      refInFor: true,
      staticClass: "item__icon",
      attrs: {
        "id": `menu${item.name}Id`
      }
    }, [_vm.selectMenu === item.value ? _c('img', {
      staticClass: "select",
      attrs: {
        "src": require(`@images/ui/${item.icon}-active1.png`),
        "alt": ""
      }
    }) : _c('img', {
      staticClass: "normal",
      attrs: {
        "src": require(`@images/ui/${item.icon}.png`),
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "item__label"
    }, [_vm._v(_vm._s(item.label))])]);
  }), 0);
};
var staticRenderFns = [];
export { render, staticRenderFns };