import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "arena",
  inject: ["setPopComingSoon"],
  computed: {
    ...userVuex.mapState(["accountInfo"]),
    ...gameVuex.mapState(["gameInfo"]),
    isMinAndMaxAmount() {
      return Number(this.listPage.min_amount) + Number(this.listPage.max_amount) + Number(this.listPage.min_power) + Number(this.listPage.max_power);
    }
  },
  data() {
    const tab = [{
      label: this.$lang("COIN"),
      value: "coin"
    }, {
      label: this.$lang("USDT"),
      value: "usdt"
    }, {
      label: this.$lang("PONY"),
      value: "pony"
    }];
    return {
      tab,
      selectTab: "coin",
      popPickCombinationShow: false,
      arenaList: null,
      listPage: {
        page: 1,
        total: 0,
        perPage: 20,
        sort: "power",
        direction: "desc",
        min_amount: null,
        max_amount: null,
        min_power: null,
        max_power: null
      },
      isLoading: null,
      error: false,
      finished: false,
      isShowSelectSort: false,
      isShowSelectAmount: false,
      isShowSelectPower: false,
      isFilterRequestApi: false,
      filterType: null,
      selectBattleCard: null
    };
  },
  beforeDestroy() {
    // this.isFilterRequestApi = false;
    _v.registerBodyClick(false, this.closePopFnc);
  },
  watch: {
    isMinAndMaxAmount: {
      handler(bool) {
        this.isFilterRequestApi = true;
      },
      immediate: true
    }
  },
  methods: {
    ...gameVuex.mapActions(["getBattleList"]),
    onTab(item) {
      const gameInfo = this.gameInfo;
      if (item.value === "coin" && !gameInfo.battleCoinBet || item.value === "usdt" && !gameInfo.battleUsdtBet || item.value === "pony" && !gameInfo.battlePonyBet) {
        this.setPopComingSoon(true);
        return;
      }
      if (this.selectTab == item.value) return;
      this.listPage.page = 1;
      this.listPage.total = 0;
      this.listPage.min_amount = null;
      this.listPage.max_amount = null;
      this.listPage.min_power = null;
      this.listPage.max_power = null;
      this.listPage.sort = "power";
      this.listPage.direction = "desc";
      this.selectTab = item.value;
      this.getBattleListFunc();
    },
    async onAmountFilter() {
      this.listPage.page = 1;
      this.listPage.direction = this.listPage.direction == "desc" ? "asc" : "desc";
      await this.getBattleListFunc();
    },
    onShowSelectSort() {
      this.isShowSelectSort = true;
    },
    onSelectSort(sort) {
      this.listPage.page = 1;
      this.closeAllPop();
      if (this.listPage.sort == sort) {
        return;
      }
      this.listPage.sort = sort;
      this.isShowSelectSort = false;
      this.getBattleListFunc();
    },
    onSelectFilter(type) {
      this.filterType = type;
      this.closeAllPop();
      if (type == "amount") this.isShowSelectAmount = true;else if (type == "power") this.isShowSelectPower = true;
    },
    onSelect(item) {
      if (this.accountInfo.energy <= 0) {
        const mArenaAssetsRef = this.$refs.mArenaAssetsRef;
        if (mArenaAssetsRef) {
          mArenaAssetsRef.onEnergyPay();
        }
        return;
      }
      this.selectBattleCard = item;
      this.popPickCombinationShow = true;
    },
    onSkipRecord() {
      this.$router.push({
        query: {
          asset: this.selectTab,
          direction: "attacker"
        },
        path: "/battleRecord"
      });
    },
    async onFilterClear(type) {
      let flag = false;
      switch (type) {
        case "amount":
          flag = this.listPage.min_amount || this.listPage.max_amount;
          this.listPage.min_amount = null;
          this.listPage.max_amount = null;
          break;
        case "power":
          flag = this.listPage.min_power || this.listPage.max_power;
          this.listPage.min_power = null;
          this.listPage.max_power = null;
          break;
      }
      if (flag) {
        await this.getBattleListFunc();
        this.closeAllPop();
      }
    },
    async getBattleListFunc() {
      return new Promise(async (resolve, reject) => {
        const {
          page,
          perPage,
          sort,
          direction,
          min_amount,
          max_amount,
          min_power,
          max_power
        } = this.listPage;
        let params = {
          asset: this.selectTab,
          page,
          perPage,
          sort,
          direction
        };
        if (min_amount !== null) params["min_amount"] = Number(min_amount);
        if (max_amount) params["max_amount"] = Number(max_amount);
        if (min_power !== null) params["min_power"] = Number(_v.toPrecision(min_power / 3600, 6));
        if (max_power) params["max_power"] = Number(_v.toPrecision(max_power / 3600, 6));
        this.isLoading = true;
        this.arenaList = [];
        const res = await this.getBattleList(params);
        if (!res) {
          this.isLoading = false;
          resolve();
          return;
        }
        this.isLoading = false;
        this.listPage.total = res.total;
        this.arenaList = res.items;
        resolve(1);
      });
    },
    // initData() {
    //   this.listPage.page = 1;
    //   this.listPage.sort = "amount";
    //   this.listPage.direction = "desc";
    //   this.finished = false;
    //   this.error = false;
    //   this.total = 0;
    // },
    closeAllPop() {
      this.isShowSelectSort = false;
      this.isShowSelectAmount = false;
      this.isShowSelectPower = false;
    },
    async closePopRequireApi() {
      if (this.isFilterRequestApi && (this.filterType === "amount" && (this.listPage.min_amount != null || this.listPage.max_amount != null) || this.filterType === "power" && (this.listPage.min_power != null || this.listPage.max_power != null))) {
        await this.getBattleListFunc();
        this.isFilterRequestApi = false;
      }
    },
    onFilterConfirm() {
      this.closePopRequireApi();
    },
    onFilterClose() {
      this.listPage.page = 1;
      this.closeAllPop();
      this.closePopRequireApi();
    },
    closePopFnc() {
      this.closeAllPop();
      // this.closePopRequireApi();
    },

    onInput(_, type) {
      let value = this.listPage[type];
      if (value === "") {
        this.listPage[type] = null;
        return;
      }
      value = this.listPage[type] = value.replace(/[^0-9]/g, "");
      if (value.length > 1 && value.startsWith("0")) {
        this.listPage[type] = value.replace(/^0+/, "");
      }
    },
    onChange(_, type) {
      let value = this.listPage[type];
      if (type === "max_amount" && !this.listPage["max_amount"] && this.listPage["min_amount"] === null) {
        this.listPage["min_amount"] = 0;
      } else if (type === "max_power" && !this.listPage["max_power"] && this.listPage["min_power"] === null) {
        this.listPage["min_power"] = 0;
      }
      if (type === "max_amount" && value !== null && Number(value) < Number(this.listPage["min_amount"])) {
        this.listPage[type] = Number(this.listPage["min_amount"]);
      } else if (type === "max_power" && value !== null && Number(value) < Number(this.listPage["min_power"])) {
        this.listPage[type] = Number(this.listPage["min_power"]);
      }
    },
    async onArenaListPage(page) {
      this.listPage.page = page;
      await this.getBattleListFunc();
    }
  },
  async mounted() {
    if (!this.gameInfo.battleCoinBet && !this.gameInfo.battleUsdtBet && !this.gameInfo.battlePonyBet) {
      this.$router.push("/");
      return;
    }
    this.setWowAnim();
    await this.getBattleListFunc();
    // _v.registerBodyClick(true, this.closePopFnc);
  }
};