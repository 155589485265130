import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
export default {
  name: "popAddEnergy",
  props: {
    show: Boolean
  },
  computed: {
    ...userVuex.mapState(["accountInfo", "botInfo"]),
    ...gameVuex.mapState(["gameInfo"])
  },
  data() {
    return {
      isFlag: true,
      isInviteLimit: false,
      isAdvLimit: false,
      isAdvLoading: false
    };
  },
  watch: {
    show(bool) {
      this.popFunc(bool, this);
      this.isFlag = true;
      this.isAdvLoading = false;
      if (bool) {
        this.calcEnergyAdvInviteLimit();
      }
    }
  },
  beforeDestroy() {
    this.isFlag = true;
  },
  methods: {
    ...userVuex.mapActions(["getAccountInfo", "checkAdsgram", "bindWallet"]),
    ...gameVuex.mapActions(["getAdvEnergy"]),
    calcEnergyAdvInviteLimit() {
      const energy_invite = this.accountInfo.energy_invite;
      const energyInviteMax = this.gameInfo.energyInvite;
      const energy_adv = this.accountInfo.energy_adv;
      const energyAdvMax = this.gameInfo.energyAdv;
      this.isInviteLimit = energy_invite < energyAdvMax;
      this.isAdvLimit = energy_adv < energyAdvMax;
    },
    // calcEnergyTime() {
    //   const calcTimeFunc = () => {
    //     setTimeout(() => {
    //       const nowTime = new Date().getTime();
    //       const energyTime = new Date(this.accountInfo.energy_date).getTime();
    //       const restoresTime = energyTime - nowTime;
    //       console.log("restoresTime", nowTime, energyTime, restoresTime);
    //       calcTimeFunc();
    //     }, 1000);
    //   };
    //   calcTimeFunc();
    // },
    async onInvite() {
      if (this.accountInfo.energy >= this.gameInfo.energyReset) {
        this.$toasted.clear();
        this.$toasted.error(this.$lang("Energy Full!"));
        return;
      }
      if (!this.isInviteLimit) {
        return;
      }
      if (!this.accountInfo.wallet) {
        await this.connectTon();
        await this.bindWallet(this.tonWallet.address);
      }
      const link = `https://t.me/share/url?text=${this.botInfo.invite}&url=${this.botInfo.bot}/game?startapp=ref_${this.accountInfo["ref_code"]}`;
      this.WebApp.openTelegramLink(link);
      this.calcEnergyAdvInviteLimit();
    },
    async useAds() {
      if (this.accountInfo.energy >= this.gameInfo.energyReset) {
        this.$toasted.clear();
        this.$toasted.error(this.$lang("Energy Full!"));
        return;
      }
      if (!this.isAdvLimit) {
        return;
      }
      const fn = async () => {
        const res = await this.getAdvEnergy({
          adv: 1
        });
        if (!res) {
          this.isFlag = true;
          this.isAdvLoading = false;
          return;
        }
        this.isFlag = true;
        this.isAdvLoading = false;
        this.getAccountInfo(res.userInfo);
        this.calcEnergyAdvInviteLimit();
      };
      if (this.isAdvLoading) return;
      this.isFlag = false;
      this.isAdvLoading = true;
      if (!_v.blockId) {
        fn();
        return;
      }
      try {
        const ads = await this.checkAdsgram(_v.blockId);
        if (!ads) {
          this.isFlag = true;
          this.isAdvLoading = false;
          return;
        }
        fn();
      } catch (e) {
        this.isAdvLoading = false;
      }
    },
    onClose() {
      this.$emit("update:show", false);
    }
  }
};