import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
import bigNumber from "bignumber.js";
import particles from "particles.js";
Vue.use(particles);
console.log("particlesJS", particlesJS);
export default {
  name: "mining",
  data() {
    return {
      pool: null,
      cex: null,
      dex: null,
      settleTime: 0,
      apiRequireTime: 0,
      //请求次数
      apiTotalRequireTime: 11,
      //请求上限
      timer: null,
      popClaimShow: false,
      isClaimFlag: true,
      fTimer: true,
      miningText: "00:00:00"
    };
  },
  computed: {
    bigNumber() {
      return bigNumber;
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer);
    clearTimeout(this.fTimer);
    this.apiRequireTime = 0;
  },
  methods: {
    ...userVuex.mapActions(["getPoolInfo", "claimPool", "getAccountInfo"]),
    calcSettleTime(_time) {
      let time = _time;
      let now = parseInt(new Date().getTime() / 1000);
      clearTimeout(this.fTimer);
      clearTimeout(this.timer);
      const fn = async () => {
        now++;
        this.settleTime = time - now > 0 ? time - now : 0;
        if (this.settleTime <= 0 && this.apiRequireTime <= 0) {
          this.apiRequireTime++;
          await this.getPoolInfoData();
          return;
        } else if (this.settleTime <= 0 && this.apiRequireTime > 0) {
          clearTimeout(this.timer);
          this.timer = setTimeout(async () => {
            this.apiRequireTime++;
            await this.getPoolInfoData();
          }, 10000);
          return;
        } else {
          this.apiRequireTime = 0;
        }
        this.fTimer = setTimeout(fn, 1000);
      };
      fn();
    },
    async getPoolInfoData() {
      const res = await this.getPoolInfo();
      if (!res) return;
      this.cex = res.cex;
      this.dex = res.dex;
      this.pool = res;
      if (bigNumber(this.pool.total_pony).gt(0)) {
        this.calcSettleTime(res.settle_time);
        return;
      }
      clearTimeout(this.fTimer);
      clearTimeout(this.timer);
    },
    onClaimShow() {
      this.popClaimShow = true;
    },
    async popClaimConfirm(callback) {
      if (!this.isClaimFlag) return;
      this.isClaimFlag = false;
      this.$refs.popClaimRef.isLoading = false;
      const res = await this.claimPool();
      if (!res) {
        this.isClaimFlag = true;
        this.$refs.popClaimRef.isLoading = false;
        return;
      }
      this.isClaimFlag = true;
      typeof callback == "function" && callback();
      this.popClaimShow = false;
      this.pool = {
        ...this.pool,
        ...res.userPool
      };
      this.calcSettleTime(res.settle_time);
      window.scrollTo(0, 0);
      setTimeout(() => {
        this.$nextTick(() => {
          const refs = this.$refs;
          const destoryCoinContainerRef = refs.destoryCoinContainerRef;
          if (!destoryCoinContainerRef) return;
          let coinRef = "";
          let coinImg = "";
          const mAssetsRef = refs.mAssetsRef;
          if (!mAssetsRef) return;
          const assetType = this.pool.token_name.toLowerCase();
          coinRef = mAssetsRef.$refs[`${assetType}Ref`];
          coinImg = assetType === "coin" && "icon-gold.png" || assetType === "usdt" && "icon-usdt.png" || assetType === "pony" && "pony.png";
          if (!coinRef && !coinImg) return;
          this.coinAnimFn({
            containerRef: destoryCoinContainerRef,
            ref: coinRef,
            img: coinImg,
            numCoins: 20,
            callback: () => {
              this.getAccountInfo(res.userInfo);
            }
          });
        });
      }, 500);
    }
  },
  async mounted() {
    try {
      this.$nextTick(() => {
        particlesJS.load("particles-js", "/particles/particlesjs-config.json");
      });
    } catch (err) {}
    this.setWowAnim();
    this.apiRequireTime++;
    await this.getPoolInfoData();
  }
};