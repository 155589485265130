import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "popJoinArena",
  props: {
    show: Boolean,
    detail: null
  },
  watch: {
    show(bool) {
      this.popFunc(bool, this);
    },
    popBattleQuitShow: {
      handler(bool) {
        this.isBattleQuitFlag = true;
        if (this.$refs?.popBattleQuitRef) {
          this.$refs.popBattleQuitRef.isLoading = false;
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...gameVuex.mapState(["gameInfo"]),
    remainingAmount() {
      if (this.assetType === "coin" && this.detail.hasOwnProperty("battle_coin")) {
        return this.detail.battle_coin.amount - this.detail.battle_coin.init_amount;
      } else if (this.assetType === "usdt" && this.detail.hasOwnProperty("battle_usdt")) {
        return this.detail.battle_usdt.amount - this.detail.battle_usdt.init_amount;
      } else if (this.assetType === "pony" && this.detail.hasOwnProperty("battle_pony")) {
        return this.detail.battle_pony.amount - this.detail.battle_pony.init_amount;
      } else {
        return 0;
      }
    }
  },
  data() {
    return {
      popJoinArenaSelectAmountShow: false,
      selectType: null,
      popBattleQuitShow: false,
      isBattleQuitFlag: true,
      assetType: null,
      popJoinArenaSelectAmountDetail: null
    };
  },
  methods: {
    ...gameVuex.mapActions(["joinArena", "quitBattle"]),
    ...userVuex.mapActions(["getAccountInfo"]),
    onBtnJoin(type) {
      console.log(this.$parent);
      this.selectType = type;
      this.popJoinArenaSelectAmountDetail = this.detail;
      console.log("this.selectType", this.selectType);
      this.popJoinArenaSelectAmountShow = true;
    },
    onQuit(type) {
      this.assetType = type;
      this.popBattleQuitShow = true;
    },
    onSkipRecord(type) {
      this.$router.push({
        query: {
          gid: this.detail.gid,
          name: this.detail.name,
          asset: type,
          direction: "defender"
        },
        path: "/battleRecord"
      });
    },
    async popBattleQuitConfirm(callback) {
      if (!this.isBattleQuitFlag) return;
      this.isBattleQuitFlag = false;
      const params = {
        gid: this.detail.gid,
        asset: this.assetType
      };
      let isAnim = true;
      if (this.assetType === "coin" && this.detail.hasOwnProperty("battle_coin") && this.detail.battle_coin.amount <= 0 || this.assetType === "usdt" && this.detail.hasOwnProperty("battle_usdt") && this.detail.battle_usdt.amount <= 0 || this.assetType === "pony" && this.detail.hasOwnProperty("battle_pony") && this.detail.battle_pony.amount <= 0) {
        isAnim = false;
      }
      const res = await this.quitBattle(params);
      if (!res) {
        this.isBattleQuitFlag = true;
        this.$refs.popBattleQuitRef.isLoading = false;
        return;
      }
      const cardItem = res.cards.filter(item => item.gid === res.battle.gid)[0];
      this.$emit("update:detail", cardItem);
      this.isBattleQuitFlag = true;
      callback();
      this.popBattleQuitShow = false;
      if (!isAnim) {
        this.$nextTick(() => {
          this.$emit("update:show", false);
          this.getAccountInfo(res.userInfo);
        });
        return;
      }
      if (this.assetType === "coin") {
        window.scrollTo(0, 0);
      }
      this.$nextTick(() => {
        this.$emit("update:show", false);
        setTimeout(() => {
          this.$nextTick(() => {
            const refs = this.$parent.$refs;
            const destoryCoinContainerRef = refs.destoryCoinContainerRef;
            if (!destoryCoinContainerRef) return;
            let coinRef = "";
            let coinImg = "";
            if (this.$parent.isShowArena) {
              const mAssetsRef = refs.mAssetsRef;
              if (!mAssetsRef) return;
              coinRef = mAssetsRef.$refs[`${this.assetType}Ref`];
              coinImg = this.assetType === "coin" && "icon-gold.png" || this.assetType === "usdt" && "icon-usdt.png" || this.assetType === "pony" && "pony.png";
            } else {
              const mAssetsRef = refs.mAssetsRef;
              if (!mAssetsRef) return;
              if (this.assetType === "coin") {
                coinRef = mAssetsRef.$refs["coinRef"];
                coinImg = "icon-gold.png";
              } else {
                const mMenuRef = refs.mMenuRef;
                if (!mMenuRef) return;
                const _coinRef = mMenuRef.$refs[`menuassets`];
                if (!_coinRef) return;
                coinRef = _coinRef[0];
                coinImg = this.assetType === "usdt" ? "icon-usdt.png" : "pony.png";
              }
            }
            if (!coinRef && !coinImg) return;
            this.coinAnimFn({
              containerRef: destoryCoinContainerRef,
              ref: coinRef,
              img: coinImg,
              numCoins: 20,
              callback: () => {
                this.getAccountInfo(res.userInfo);
              }
            });
          });
        }, 1000);
      });
    },
    popJoinArenaSelectAmountConfirm(item) {
      this.$emit("update:detail", item);
    }
  }
};