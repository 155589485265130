import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
export default {
  name: "diamond",
  data() {
    return {
      isPayFlag: true
    };
  },
  computed: {
    ...userVuex.mapState(["diamondList", "accountInfo"])
  },
  beforeRouteLeave(to, from, next) {
    this.isPayFlag = true;
    this.diamondList.map(item => {
      item.isLoading = false;
    });
    setTimeout(() => {
      next();
    }, 100);
  },
  methods: {
    ...userVuex.mapActions(["payDiamond", "payDiamondInterval", "bindWallet"]),
    clearIntervalFn() {
      console.log("我将准备清除所有定时器");
      for (let i = 1; i < 30000; i++) {
        clearInterval(i);
      }
    },
    async onPayDiamond(item, index) {
      await this.connectTon();
      if (!this.accountInfo.wallet) {
        await this.bindWallet(this.tonWallet.address);
      }
      if (!this.isPayFlag) return;
      item.isLoading = true;
      this.isPayFlag = false;
      const res = await this.payDiamond(index);
      if (!res) {
        item.isLoading = false;
        this.isPayFlag = true;
        return;
      }
      this.payDiamondInterval(res.order_id);
      console.log("res", res, this.accountInfo);
      item.isLoading = false;
      this.isPayFlag = true;
    }
  }
};