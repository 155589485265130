export default {
  name: "mEmpty",
  props: {
    content: {
      typeof: String,
      default: "It's empty here."
    },
    isBtn: {
      typeof: Boolean,
      default: false
    },
    btnText: {
      typeof: String,
      default: "Confirm"
    }
  },
  methods: {
    onBtn() {
      this.$emit("confirm");
    }
  }
};