var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mDiamondItem"
  }, [_c('div', {
    staticClass: "mDiamondItem-main"
  }, [_c('div', {
    staticClass: "image"
  }, [_c('div', {
    staticClass: "image-main"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/diamond-${_vm.index + 1}.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "image-value"
  }, [_vm._v(_vm._s(_vm.$lang("DIAMOND")) + " x" + _vm._s(_vm.item.diamond))])]), _c('div', {
    staticClass: "btn"
  }, [_vm.item.isLoading ? _c('div', {
    staticClass: "circleLoading"
  }) : _c('div', {
    staticClass: "btn-main"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-ton.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm._f("toPre")(_vm.item.ton)) + " ")])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };