var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mCardItem"
  }, [_vm.detail != null ? _c('div', {
    staticClass: "mCardItem-main"
  }, [_vm.detail.hasOwnProperty('activated') && !_vm.detail.activated && _vm.isShowShadowUI ? _c('div', {
    staticClass: "shadow"
  }) : _vm._e(), _c('div', {
    staticClass: "tag"
  }, [_vm.detail.hasOwnProperty('level_upgradable') && _vm.detail.activated && (_vm.detail.level_upgradable || _vm.detail.star_upgradable) && _vm.isShowUpdateLevelUI ? _c('div', {
    staticClass: "upgrade"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-anim-upgrade.png`),
      "alt": ""
    }
  })]) : _vm._e(), _vm.detail.hasOwnProperty('star_upgradable') && !_vm.detail.activated && _vm.detail.star_upgradable && _vm.isShowUpdateStarUI ? _c('div', {
    staticClass: "activated"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-anim-upgrade_1.png`),
      "alt": ""
    }
  })]) : _vm._e()]), _vm.detail.hasOwnProperty('rare') && _vm.detail.rare > 0 ? _c('div', {
    staticClass: "rarity"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/rarity-text-${_vm.detail.rare}.png`),
      "alt": ""
    }
  })]) : _vm._e(), _vm.detail.hasOwnProperty('star') && _vm.isShowStarUI ? _c('div', {
    staticClass: "star"
  }, [_c('div', {
    staticClass: "star-bg"
  }, _vm._l(4, function (_) {
    return _c('i');
  }), 0), _c('div', {
    staticClass: "star-main"
  }, [_vm.detail.star > 0 ? _vm._l(_vm.detail.star, function (_) {
    return _c('i');
  }) : _vm._e()], 2)]) : _vm._e(), _vm.detail.hasOwnProperty('level') && _vm.detail.level > 0 && _vm.isShowLevelUI ? _c('div', {
    staticClass: "level"
  }, [_vm._v(_vm._s(_vm.detail.level))]) : _vm._e(), _c('div', {
    staticClass: "frame"
  }), _vm.detail.hasOwnProperty('pony_id') ? _c('div', {
    staticClass: "_card",
    style: {
      backgroundImage: 'url(' + `${require(`@images/lists/${_vm.detail.pony_id}.jpg`)}` + ')'
    }
  }) : _vm.detail.hasOwnProperty('id') ? _c('div', {
    staticClass: "_card",
    style: {
      backgroundImage: 'url(' + `${require(`@images/lists/${_vm.detail.id}.jpg`)}` + ')'
    }
  }) : _vm._e()]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };