var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c('div', {
    staticClass: "popAddEnergy"
  }, [_c('div', {
    staticClass: "shadow",
    on: {
      "click": _vm.onClose
    }
  }), _c('transition', {
    attrs: {
      "appear": "",
      "name": "popPromptBottom"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "popAddEnergy-main"
  }, [_c('div', {
    staticClass: "btn-close",
    on: {
      "click": _vm.onClose
    }
  }), _c('div', {
    staticClass: "popAddEnergy__title"
  }, [_vm._v(_vm._s(_vm.$lang("Boost Energy")))]), _c('div', {
    staticClass: "popAddEnergy-total"
  }, [_c('div', {
    staticClass: "popAddEnergy-total-time"
  }, [_c('div', {
    staticClass: "popAddEnergy-total-time-main"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-time"
    }
  }), _vm._v(" " + _vm._s(_vm._f("formatTime")(_vm.accountInfo.energy_date)) + " ")], 1)]), _c('div', {
    staticClass: "popAddEnergy-total-main"
  }, [_vm._v(_vm._s(_vm._f("toPre")(_vm.accountInfo.energy)) + "/" + _vm._s(_vm._f("toPre")(_vm.gameInfo.energyReset)))])]), _c('div', {
    staticClass: "popAddEnergy-info"
  }, [_c('div', {
    staticClass: "popAddEnergy-item",
    class: _vm.accountInfo.energy >= _vm.gameInfo.energyReset && '_disable',
    on: {
      "click": _vm.useAds
    }
  }, [_c('div', {
    staticClass: "popAddEnergy-item-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-ads.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "popAddEnergy-cost"
  }, [_vm._v("Limit: " + _vm._s(_vm._f("toPre")(_vm.accountInfo.energy_adv)) + "/" + _vm._s(_vm._f("toPre")(_vm.gameInfo.energyAdv)))]), _c('div', {
    staticClass: "popAddEnergy-num"
  }, [_vm.isAdvLimit ? [_vm.isAdvLoading ? _c('div', {
    staticClass: "circleLoading"
  }) : _c('div', {
    staticClass: "popAddEnergy-num-main"
  }, [_vm._v("+1")])] : [_vm._v("Max")]], 2), _c('div', {
    staticClass: "popInfo-info"
  }, [_vm._v(_vm._s(_vm.$lang("Watch ads to get")))])]), _c('div', {
    staticClass: "popAddEnergy-item",
    class: _vm.accountInfo.energy >= _vm.gameInfo.energyReset && '_disable',
    on: {
      "click": _vm.onInvite
    }
  }, [_c('div', {
    staticClass: "popAddEnergy-item-icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/enery_invite.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "popAddEnergy-cost"
  }, [_vm._v("Limit: " + _vm._s(_vm._f("toPre")(_vm.accountInfo.energy_invite)) + "/" + _vm._s(_vm._f("toPre")(_vm.gameInfo.energyInvite)))]), _c('div', {
    staticClass: "popAddEnergy-num"
  }, [_vm.isInviteLimit ? [_c('div', {
    staticClass: "popAddEnergy-num-main"
  }, [_vm._v("+1")])] : [_vm._v("Max")]], 2), _c('div', {
    staticClass: "popInfo-info"
  }, [_vm._v(_vm._s(_vm.$lang("Invite to get")))])])])])])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };