import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "friend",
  data() {
    return {
      popInviteTipShow: false
    };
  },
  computed: {
    ...gameVuex.mapState(["gameInfo"]),
    ...userVuex.mapState(["accountInfo", "botInfo"]),
    ...walletVuex.mapState(["tonConnect", "tonAddress"])
  },
  methods: {
    ...userVuex.mapActions(["bindWallet", "getAccountInfo"]),
    onQuestion() {
      this.popInviteTipShow = true;
    },
    async onShare() {
      if (!this.accountInfo.wallet) {
        await this.connectTon();
        await this.bindWallet(this.tonWallet.address);
      }
      const link = `https://t.me/share/url?text=${this.botInfo.invite}&url=${this.botInfo.bot}/game?startapp=ref_${this.accountInfo["ref_code"]}`;
      this.WebApp.openTelegramLink(link);
    },
    async onCopy() {
      if (!this.accountInfo.wallet) {
        await this.connectTon();
        await this.bindWallet(this.tonWallet.address);
      }
      const url = `${this.botInfo.bot}/game?startapp=ref_${this.accountInfo["ref_code"]}`;
      const that = this;
      this.$copyText(url).then(function (e) {
        that.$toasted.clear();
        that.$toasted.success("Copy Success!");
      }, function (e) {});
    }
  },
  mounted() {
    this.setWowAnim();
  }
};