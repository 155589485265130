import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
export default {
  name: "popLuckyAirdropInfo",
  props: {
    show: Boolean
  },
  computed: {
    ...userVuex.mapState(["accountInfo"])
  },
  watch: {
    show(bool) {
      this.popFunc(bool, this);
      if (bool) {
        clearInterval(this.timer);
        this.timer = null;
        // this.calcAirdropTime();
      }
    }
  },

  data() {
    return {
      countdown: null,
      timer: null
    };
  },
  methods: {
    ...gameVuex.mapActions(["getCardList"]),
    ...userVuex.mapActions(["getAccountBonus", "checkAdsgram", "getAccountInfo"]),
    onClose() {
      this.$emit("update:show", false);
      // this.accountInfo.show_airdrop = false;
    }
    // calcAirdropTime() {
    //   let nowTime = parseInt(new Date().getTime() / 1000);
    //   const fn = () => {
    //     nowTime++;
    //     const dropTime = this.accountInfo.drop_time;
    //     if (nowTime >= dropTime) {
    //       clearInterval(this.timer);
    //       this.timer = null;
    //       this.countdown = 0;
    //       this.getAccountInfo();
    //     } else {
    //       this.countdown = dropTime - nowTime;
    //     }
    //     // console.log("countdown", this.countdown);
    //   };
    //   fn();
    //   this.timer = setInterval(fn, 1000);
    // },
  }
};