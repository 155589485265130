import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "assets",
  computed: {
    ...userVuex.mapState(["accountInfo", "isPayDiamondInterLoading", "isPayPonyInterLoading", "isPayUsdtInterLoading"]),
    ...walletVuex.mapState(["tonConnect", "tonAddress"])
  },
  data() {
    return {
      popRebindShow: false,
      isRebindFlag: true,
      isLoading: null,
      popWithdrawShow: false,
      assetType: null,
      withdrawNum: 1,
      popInsufficientShow: false,
      isWithdrawFlag: true,
      popDepositShow: false,
      isDepositFlag: true,
      depositNum: 1,
      isBindLoading: false,
      isBindFlag: true
    };
  },
  beforeRouteLeave(to, from, next) {
    this.popRebindShow = false;
    this.popWithdrawShow = false;
    this.popInsufficientShow = false;
    this.popDepositShow = false;
    setTimeout(() => {
      next();
    }, 100);
  },
  watch: {
    popRebindShow(bool) {
      if (!bool) {
        this.initPop();
      }
    },
    popWithdrawShow(bool) {
      if (!bool) {
        this.initPop();
      }
    },
    popDepositShow(bool) {
      if (!bool) {
        this.initPop();
      }
    }
  },
  methods: {
    ...userVuex.mapActions(["bindWallet", "getAccountInfo", "withdrawAsset", "payPonyorUsdt", "payPonyInterval", "payUsdtInterval", "withdrawPonyInterval", "withdrawUsdtInterval"]),
    initPop() {
      if (this.$refs?.popRebindRef) {
        this.$refs.popRebindRef.isLoading = false;
      }
      if (this.$refs?.popWithdrawRef) {
        this.$refs.popWithdrawRef.isLoading = false;
      }
      if (this.$refs?.popDepositRef) {
        this.$refs.popDepositRef.isLoading = false;
      }
      this.isRebindFlag = true;
      this.withdrawNum = 1;
      this.assetType = null;
      this.isWithdrawFlag = true;
      this.isDepositFlag = true;
    },
    onWithdraw(asset) {
      this.assetType = asset;
      if (asset === "ton" && this.accountInfo.ton <= 0 || asset === "usdt" && this.accountInfo.usdt <= 0 || asset === "pony" && this.accountInfo.pony <= 0) {
        this.popInsufficientShow = true;
        return;
      }
      this.popWithdrawShow = true;
    },
    onInput() {
      const num = this.assetType === "ton" && this.accountInfo.ton || this.assetType === "usdt" && this.accountInfo.usdt || this.assetType === "pony" && this.accountInfo.pony;
      const maxNum = Number(num);
      if (this.withdrawNum >= maxNum) {
        this.withdrawNum = maxNum;
      }
    },
    onDepositInput() {
      if (this.depositNum <= 1) {
        this.$toasted.clear();
        this.$toasted.error(this.$lang(`Deposit at least 1 ${this.assetType}！`));
        return;
      }
      if (this.depositNum.includes(".")) {
        this.depositNum = Math.floor(this.depositNum);
      }
      console.log("this.depositNum", this.depositNum);
    },
    async popWithdrawConfirm(callback) {
      const accountBalance = this.assetType === "ton" && this.accountInfo.ton || this.assetType === "usdt" && this.accountInfo.usdt || this.assetType === "pony" && this.accountInfo.pony;
      const maxNum = Number(accountBalance);
      if (this.withdrawNum > maxNum) {
        this.$toasted.clear();
        this.$toasted.error(this.$lang("Insufficient balance"));
        this.$refs.popWithdrawRef.isLoading = false;
        return;
      }
      if (this.withdrawNum <= 0) {
        this.$toasted.clear();
        this.$toasted.error(this.$lang("Please entern amount!"));
        this.$refs.popWithdrawRef.isLoading = false;
        return;
      }
      await this.connectTon();
      if (!this.accountInfo.wallet) {
        await this.bindWallet(this.tonWallet.address);
      }
      if (!this.isWithdrawFlag) return;
      this.isWithdrawFlag = false;
      const res = await this.withdrawAsset({
        asset: this.assetType,
        amount: this.withdrawNum
      });
      if (!res) {
        this.$refs.popWithdrawRef.isLoading = false;
        this.isWithdrawFlag = true;
        return;
      }
      setTimeout(async () => {
        if (this.assetType === "pony") {
          this.withdrawPonyInterval(res.order_id);
        } else if (this.assetType === "usdt") {
          this.withdrawUsdtInterval(res.order_id);
        }
        this.isWithdrawFlag = true;
        this.$refs.popWithdrawRef.isLoading = false;
        this.$toasted.success("Withdraw success");
        this.popWithdrawShow = false;
        callback();
      }, 1000);
    },
    onDeposit(asset) {
      this.assetType = asset;
      this.$nextTick(() => {
        this.popDepositShow = true;
      });
    },
    async popDepositConfirm(callback) {
      await this.connectTon();
      if (!this.accountInfo.wallet) {
        await this.bindWallet(this.tonWallet.address);
      }
      if (!this.isDepositFlag) return;
      this.isDepositFlag = false;
      const res = await this.payPonyorUsdt({
        asset: this.assetType,
        amount: this.depositNum
      });
      if (!res) {
        this.$refs.popDepositRef.isLoading = false;
        this.isDepositFlag = true;
        return;
      }
      if (this.assetType === "pony") {
        this.payPonyInterval(res.order_id);
      } else if (this.assetType === "usdt") {
        this.payUsdtInterval(res.order_id);
      }
      this.isDepositFlag = true;
      this.$refs.popDepositRef.isLoading = false;
      callback();
      this.popDepositShow = false;
    },
    onAll() {
      const accountBalance = this.assetType === "ton" && this.accountInfo.ton || this.assetType === "usdt" && this.accountInfo.usdt || this.assetType === "pony" && this.accountInfo.pony;
      console.log("accountBalance", accountBalance);
      this.withdrawNum = accountBalance;
    },
    popInsufficientConfirm() {
      this.popInsufficientShow = false;
      // setTimeout(() => {
      //   this.$router.push("/airdrop");
      // }, 100);
    },

    async bindWalletFn() {
      await this.connectTon();
      if (!this.isBindFlag) return;
      this.isBindFlag = false;
      this.isBindLoading = true;
      const res = await this.bindWallet(this.tonWallet.address);
      if (!res) {
        this.isBindLoading = false;
        this.isBindFlag = true;
        return;
      }
      this.isBindLoading = false;
      this.isBindFlag = true;
      this.accountInfo.wallet = res;
      this.$toasted.success(this.$lang("Bind Success"));
    },
    async connectWalletFn() {
      await this.connectTon();
      if (this.accountInfo.wallet) {
        return;
      }
      this.isLoading = true;
      await this.connectTon();
      const res = await this.bindWallet(this.tonAddress);
      if (!res) {
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
    },
    rebindWalletFn() {
      this.popRebindShow = true;
    },
    async quitWalletFn() {
      await this.tonWallet.disconnect(() => {
        console.log("this.tonWallet", this.tonWallet);
        this.$toasted.success(this.$lang("Wallet Logouted!"));
      });
    },
    async popRebindConfirm(callback) {
      await this.connectTon();
      if (!this.accountInfo.wallet) {
        await this.bindWallet(this.tonWallet.address);
      }
      if (!this.isRebindFlag) return;
      this.isRebindFlag = false;
      this.$refs.popRebindRef.isLoading = true;
      if (this.accountInfo.wallet === this.tonAddress) {
        this.isRebindFlag = false;
        this.$refs.popRebindRef.isLoading = false;
        return;
      }
      const res = await this.bindWallet(this.tonAddress);
      if (!res) {
        this.isRebindFlag = false;
        this.$refs.popRebindRef.isLoading = false;
        return;
      }
      this.accountInfo.wallet = res;
      this.$toasted.success(this.$lang("rebind success!"));
      this.isRebindFlag = true;
      callback();
      this.popRebindShow = false;
    }
  },
  mounted() {}
};