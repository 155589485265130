var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mAssets"
  }, [_c('div', {
    staticClass: "mAssets-item"
  }, [_c('div', {
    ref: "coinRef",
    staticClass: "mAssets-item__icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-gold.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "mAssets-item__value",
    class: _vm.isAssetCoin && 'active'
  }, [_vm._v(_vm._s(_vm._f("toPre")(_vm.accountInfo.coin)))]), _c('div', {
    staticClass: "btn btnHover"
  }, [_vm._t("default")], 2)]), _c('div', {
    ref: "diamondRef",
    staticClass: "mAssets-item diamond",
    on: {
      "click": _vm.onSkip
    }
  }, [_c('div', {
    staticClass: "mAssets-item__icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-diamonds.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "mAssets-item__value"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("toPre")(_vm.accountInfo.diamond, 0)) + " "), _vm.isPayDiamondInterLoading ? _c('em', [_c('u', [_c('svg-icon', {
    attrs: {
      "name": "loading",
      "spin": ""
    }
  })], 1)]) : _vm._e()])]), _vm.path !== '/diamond' ? _c('div', {
    staticClass: "btn-pay"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-pay.png`),
      "alt": ""
    }
  })]) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };