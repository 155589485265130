const stop = body => body.style.overflow = "hidden";
const move = body => body.style.overflow = ""; //出现滚动条
import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
export default {
  name: "popIncome",
  props: {
    show: Boolean
  },
  computed: {
    ...userVuex.mapState(["accountInfo"]),
    ...gameVuex.mapState(["gameInfo"])
  },
  data() {
    return {
      popOfflineShow: false,
      isOfflineDiamondFlag: true,
      // isOfflineAdsFlag: true,
      isAdvLoading: false
    };
  },
  watch: {
    show(bool) {
      this.isAdvLoading = false, this.isOfflineAdsFlag = true;
      this.isOfflineDiamondFlag = true;
      this.popFunc(bool, this);
    }
  },
  beforeDestroy() {
    this.popOfflineShow = false;
    this.isOfflineDiamondFlag = true;
    this.isOfflineAdsFlag = true;
  },
  methods: {
    ...userVuex.mapActions(["offlineRewards", "getAccountInfo", "checkAdsgram"]),
    async useDiamond() {
      if (this.accountInfo.offline_diamond > 0 && this.isOfflineDiamondFlag) {
        this.popOfflineShow = true;
      } else {
        await this.popOfflineConfirm();
      }
    },
    async popOfflineConfirm() {
      if (!this.isOfflineDiamondFlag) return;
      this.isOfflineDiamondFlag = false;
      const res = await this.offlineRewards({});
      if (!res) {
        this.isOfflineDiamondFlag = true;
        this.$refs.popOfflineRef.isLoading = false;
        this.accountInfo.offline_coin_displayed = 0;
        this.popOfflineShow = false;
        return;
      } else {
        await this.getAccountInfo(res.userInfo);
      }
      this.isOfflineDiamondFlag = true;
      this.$refs.popOfflineRef.isLoading = false;
      this.popOfflineShow = false;
      //获取金币动画
      this.$nextTick(() => {
        this.$emit("update:show", false);
        const destoryCoinContainerRef = this.$parent.$refs.destoryCoinContainerRef;
        console.log("destoryCoinContainerRef", destoryCoinContainerRef);
        if (!destoryCoinContainerRef) return;
        const mAssetsRef = this.$parent.$refs.mAssetsRef;
        console.log("mAssetsRef", mAssetsRef);
        if (!mAssetsRef) return;
        const coinRef = mAssetsRef.$refs["coinRef"];
        this.coinAnimFn({
          containerRef: destoryCoinContainerRef,
          ref: coinRef,
          callback: () => {
            this.getAccountInfo(res.userInfo);
          }
        });
      });
    },
    async useAds() {
      const fn = async () => {
        const res = await this.offlineRewards({
          adv: 1
        });
        if (!res) {
          this.isOfflineDiamondFlag = true;
          this.isAdvLoading = false;
          return;
        } else {
          await this.getAccountInfo(res.userInfo);
        }
        this.isOfflineDiamondFlag = true;
        this.isAdvLoading = false;
        //获取金币动画
        this.$nextTick(() => {
          this.$emit("update:show", false);
          const destoryCoinContainerRef = this.$parent.$refs.destoryCoinContainerRef;
          console.log("destoryCoinContainerRef", destoryCoinContainerRef);
          if (!destoryCoinContainerRef) return;
          const mAssetsRef = this.$parent.$refs.mAssetsRef;
          if (!mAssetsRef) return;
          const coinRef = mAssetsRef.$refs["coinRef"];
          this.coinAnimFn({
            containerRef: destoryCoinContainerRef,
            ref: coinRef,
            callback: () => {
              this.getAccountInfo(res.userInfo);
            }
          });
        });
      };
      if (!this.isOfflineDiamondFlag) return;
      this.isOfflineDiamondFlag = false;
      this.isAdvLoading = true;
      if (!_v.blockId) {
        fn();
        return;
      }
      try {
        const ads = await this.checkAdsgram(_v.blockId);
        if (!ads) {
          this.isOfflineDiamondFlag = true;
          this.isAdvLoading = false;
          return;
        }
        fn();
      } catch (e) {
        this.isAdvLoading = false;
        this.isOfflineDiamondFlag = true;
      }
    },
    onClose() {
      this.accountInfo.offline_coin_displayed = 1;
      this.$emit("update:show", false);
    }
  }
};