import "core-js/modules/es.array.push.js";
const assetsManifest = require(`@assets/js/assetsManifest`);
import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
export default {
  name: "preload",
  props: {},
  computed: {
    imgUrlArr() {
      if (assetsManifest.assets && assetsManifest.assets.length > 0) {
        return assetsManifest.assets.map(it => {
          return require(`@images/${it}`);
        });
      } else {
        return [];
      }
    }
  },
  data() {
    return {
      msg: "",
      show: true,
      imgsSum: NaN,
      loadedCount: 0,
      total: 100,
      tip: "",
      tipType: 1,
      backgroundImage: ""
    };
  },
  watch: {
    "imgUrlArr.length": {
      async handler(len) {
        if (len > 0) {
          this.tipType = 1;
          this.imgsSum = this.imgUrlArr.length;
          const RADIO = 1.93;
          this.tip = this.$lang("Get login information...");
          this.loadedCount = RADIO;
          const login = await this.getLoginInfo();
          if (login !== 1) {
            this.tipType = 0;
            this.tip = login.message;
            return;
          }
          this.tip = this.$lang("Get account information...");
          this.loadedCount = 2 * RADIO;
          const account = await this.getAccountInfo();
          if (account != 1) {
            this.tipType = 0;
            this.tip = account.message;
            return;
          }
          this.tip = this.$lang("Loading Resources...");
          this.unorderedPreload();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...userVuex.mapActions(["getLoginInfo", "getAccountInfo"]),
    unorderedPreload() {
      this.$nextTick(() => {
        let backgroundImages = [];
        // 无序加载，并发下载图片
        this.imgUrlArr.forEach((imgUrl, i) => {
          var oImg = new Image();
          oImg.addEventListener("load", this.imgLoaded);
          oImg.addEventListener("error", this.imgLoaded);
          oImg.src = imgUrl;
          backgroundImages.push(`url(${imgUrl})`);
        });
        this.backgroundImage = backgroundImages.join(",");
      });
    },
    imgLoaded() {
      // 每次图片加载完成执行，无论成功或者失败
      this.loadedCount++;
      if (this.loadedCount >= this.imgsSum) {
        this.show = false;
        this.$emit("imgAllLoaded");
      }
    }
  }
};