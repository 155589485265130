var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c('div', {
    staticClass: "popBattleResult popPrompt"
  }, [_c('div', {
    staticClass: "shadow"
  }), _c('transition', {
    attrs: {
      "appear": "",
      "name": "popPromptCenter"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "popPrompt-main center"
  }, [_vm.type ? _c('div', {
    staticClass: "light",
    style: {
      background: 'url(' + require('@images/ui/light.png') + ') center/auto 100% no-repeat'
    }
  }) : _vm._e(), _c('div', {
    staticClass: "popPrompt-con"
  }, [_c('div', {
    staticClass: "popBattleResult-tag"
  }, [_vm.type ? _c('div', {
    staticClass: "popBattleResult-tag-victory"
  }, [_c('img', {
    staticClass: "wow zoomIn",
    staticStyle: {
      "z-index": "-2"
    },
    attrs: {
      "data-wow-duration": "1s",
      "src": require(`@images/ui/battle-victory-ribbon.png`),
      "alt": ""
    }
  }), _c('img', {
    staticClass: "left wow battleVictoryLeft",
    staticStyle: {
      "z-index": "-2"
    },
    attrs: {
      "data-wow-duration": "1s",
      "src": require(`@images/ui/battle-victory-wing.png`),
      "alt": ""
    }
  }), _c('img', {
    staticClass: "wow battleVictoryRight",
    staticStyle: {
      "z-index": "-2"
    },
    attrs: {
      "data-wow-duration": "1s",
      "src": require(`@images/ui/battle-victory-wing.png`),
      "alt": ""
    }
  }), _c('img', {
    staticClass: "wow fadeInUp",
    staticStyle: {
      "z-index": "-2"
    },
    attrs: {
      "data-wow-duration": "1s",
      "src": require(`@images/ui/battle-victory-crown.png`),
      "alt": ""
    }
  }), _c('img', {
    staticClass: "wow zoomIn",
    staticStyle: {
      "z-index": "1"
    },
    attrs: {
      "data-wow-duration": "0.5s",
      "src": require(`@images/ui/battle-victory-bg.png`),
      "alt": ""
    }
  }), _c('img', {
    staticClass: "wow battleVictoryText",
    staticStyle: {
      "z-index": "2"
    },
    attrs: {
      "data-wow-duration": "1s",
      "src": require(`@images/ui/battle-victory-text.png`),
      "alt": ""
    }
  })]) : _c('div', {
    staticClass: "popBattleResult-tag-defeat"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/battle-defeat-bg.png`),
      "alt": ""
    }
  })])]), _c('div', {
    staticClass: "popBattleResult-list"
  }, [_c('div', {
    staticClass: "popBattleResult-item"
  }, [_c('div', {
    staticClass: "popBattleResult-item__coin"
  }, [_vm.asset === 'coin' ? _c('img', {
    attrs: {
      "src": require(`@images/ui/icon-gold.png`),
      "alt": ""
    }
  }) : _vm.asset === 'usdt' ? _c('img', {
    attrs: {
      "src": require(`@images/ui/icon-usdt.png`),
      "alt": ""
    }
  }) : _vm.asset === 'pony' ? _c('img', {
    attrs: {
      "src": require(`@images/ui/pony.png`),
      "alt": ""
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "popBattleResult-item__num",
    class: !_vm.type && 'defeat'
  }, [_vm.type ? [_vm._v(_vm._s(_vm._f("toPre")(_vm.reward, 2)))] : [_vm._v("-" + _vm._s(_vm._f("toPre")(_vm.amount, 2)))]], 2)]), _vm.type && _vm.direction === 'attacker' && _vm.honor > 0 ? _c('div', {
    staticClass: "popBattleResult-item"
  }, [_c('div', {
    staticClass: "popBattleResult-item__coin"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/battle-icon-honor.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "popBattleResult-item__num"
  }, [_vm._v(_vm._s(_vm.honor))])]) : _vm._e()]), _c('div', {
    staticClass: "popBattleResult-btns",
    on: {
      "click": _vm.onConfirm
    }
  }, [_c('div', {
    staticClass: "btn-confirm"
  }, [_vm._v(_vm._s(_vm.$lang("Exit")))])])])])])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };