import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
export default {
  name: "mTask",
  props: {
    task: null
  },
  data() {
    return {};
  },
  methods: {
    ...gameVuex.mapActions(["getNotify"]),
    ...userVuex.mapActions(["skipTask", "getAccountInfo"]),
    async onSkip(item) {
      if (item.status === 1) {
        if (item.type == "telegram" || item.type == "channel") {
          this.WebApp.openTelegramLink(item.url);
        } else {
          this.WebApp.openLink(item.url);
        }
        return;
      }
      item.isLoading = true;
      const res = await this.skipTask(item.id);
      if (!res.url) {
        item.isLoading = false;
        return;
      }
      if (item.type == "telegram" || item.type == "channel") {
        this.WebApp.openTelegramLink(res.url);
      } else {
        this.WebApp.openLink(res.url);
      }
      setTimeout(async () => {
        await this.getNotify();
        await this.getAccountInfo();
        item.isLoading = false;
        item.status = res.status;
      }, 10000);
    }
  }
};