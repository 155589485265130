import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "detail",
  data() {
    return {
      popUpgradeShow: false,
      popUpgradestarShow: false,
      card: null,
      popDestoryShow: false,
      destroyNum: 1,
      isDestroyFlag: true,
      popActivateCardShow: false
    };
  },
  computed: {
    ...gameVuex.mapState(["gameInfo"]),
    ...userVuex.mapState(["accountInfo"])
  },
  beforeDestroy() {
    this.EventBus.$off("onActivate", this.onActivate);
  },
  beforeRouteLeave(to, from, next) {
    this.EventBus.$off("onActivate", this.onActivate);
    this.initPopData();
    setTimeout(() => {
      next();
    }, 100);
  },
  watch: {
    popDestoryShow(bool) {
      if (!bool) {
        this.initPopData();
      }
    },
    "card.cards": {
      handler(num) {
        if (num <= 0 || num == "") {
          this.destroyNum = 0;
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...gameVuex.mapActions(["destroyCard"]),
    ...userVuex.mapActions(["getAccountInfo"]),
    ...gameVuex.mapMutations(["setPopInsufficient"]),
    initPopData() {
      this.popUpgradeShow = false;
      this.popUpgradestarShow = false;
      this.popDestoryShow = false;
      this.popActivateCardShow = false;
    },
    onInput() {
      const maxNum = Number(this.card.cards);
      if (this.destroyNum >= maxNum) {
        this.destroyNum = maxNum;
      }
      if (this.destroyNum.includes(".")) {
        this.destroyNum = Math.floor(this.destroyNum);
      }
      console.log("this.destroyNum", this.destroyNum);
    },
    onAll() {
      this.destroyNum = Number(this.card.cards);
    },
    onUpgrade() {
      this.popUpgradeShow = true;
    },
    onUpgradestar() {
      this.popUpgradestarShow = true;
    },
    onDestroy() {
      this.popDestoryShow = true;
    },
    async popDestoryConfirm(callback) {
      const maxNum = Number(this.card.cards);
      if (this.destroyNum > maxNum) {
        this.$toasted.error(this.$lang("You don't have that many cards"));
        this.$refs.popPromptRef.isLoading = false;
        return;
      }
      if (this.destroyNum <= 0) {
        this.$toasted.error(this.$lang("Please entern amount!"));
        this.$refs.popPromptRef.isLoading = false;
        return;
      }
      if (!this.isDestroyFlag) return;
      this.isDestroyFlag = false;
      const res = await this.destroyCard({
        pony_id: this.card.id,
        amount: this.destroyNum
      });
      if (!res) {
        this.$nextTick(() => {
          this.$refs.popPromptRef.isLoading = false;
          this.isDestroyFlag = false;
          return;
        });
      }
      this.card.cards -= res.amount;
      this.$router.replace({
        query: this.card
      });
      this.isDestroyFlag = true;
      this.popDestoryShow = false;
      //获取金币动画
      this.$nextTick(() => {
        const destoryCoinContainerRef = this.$refs.destoryCoinContainerRef;
        if (!destoryCoinContainerRef) return;
        const mAssetsRef = this.$refs.mAssetsRef;
        if (!mAssetsRef) return;
        const coinRef = mAssetsRef.$refs["coinRef"];
        this.coinAnimFn({
          containerRef: destoryCoinContainerRef,
          ref: coinRef,
          callback: () => {
            this.getAccountInfo();
          }
        });
      });
      // if(res.amount>10) {
      // }
      typeof callback === "function" && callback();
    },
    onActivate() {
      if (this.card.cards < this.card.star_cost) {
        this.setPopInsufficient({
          show: true,
          message: "card insufficient",
          code: 102
        });
        return;
      }
      this.popActivateCardShow = true;
    }
  },
  mounted() {
    if (JSON.stringify(this.$route.query) == "{}") {
      this.$router.push("/");
      return;
    }
    const query = this.$route.query;
    query["activated"] = JSON.parse(query["activated"]);
    query["level_upgradable"] = JSON.parse(query["level_upgradable"]);
    query["star_upgradable"] = JSON.parse(query["star_upgradable"]);
    console.log("queryqueryqueryquery", query);
    if (query.hasOwnProperty("star")) {
      query["star"] = Number(query["star"]);
    }
    this.card = {
      ...query
    };
    console.log("this.card", this.card);
    if (this.accountInfo.init >= 2) {
      this.setWowAnim();
    }
    this.EventBus.$off("onActivate", this.onActivate);
    this.EventBus.$on("onActivate", this.onActivate);
  }
};