import Vue from "vue";
import store from "@store/index";

export default function vailCode(rs, callback, errcallback) {
  if (!rs) {
    typeof errcallback == "function" && errcallback(rs);
    return;
  }
  if (rs.code == 0) {
    typeof callback == "function" && callback(rs);
  } else {
    if (rs.code === 401) {
      Vue.toasted.clear();
      // Vue.toasted.error(rs.message);
      _v.WebApp.showAlert(rs.message, function(){
        location.reload();
      });
    } 
    if (rs.code === 101 || rs.code === 102) {
      store.commit("game/setPopInsufficient", {
        show: true,
        message: rs.message,
        code: rs.code,
      });
    } else {
      Vue.toasted.clear();
      Vue.toasted.error(rs.message);
    }

    typeof errcallback == "function" && errcallback(rs);
  }
}
