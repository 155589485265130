import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "battleRecord"
  }, [_c('m-arena-assets', {
    ref: "mArenaAssetsRef"
  }), _c('div', {
    staticClass: "battleRecord-body page-body"
  }, [_c('div', {
    staticClass: "battleRecord-main page-main"
  }, [_c('div', {
    staticClass: "battleRecord-con"
  }, [_c('div', {
    staticClass: "battleRecord-head"
  }, [_vm.detail ? _c('div', {
    staticClass: "battleRecord-title",
    class: _vm.detail.direction === 'defender' && 'active'
  }, [_vm.detail.direction === 'defender' ? [_vm._v(" " + _vm._s(_vm.$lang("My Defense Record")) + " ")] : _vm._e(), _vm.detail.direction === 'attacker' ? [_vm._v(" " + _vm._s(_vm.$lang("My Attack Record")) + " ")] : _vm._e(), _vm.detail ? _c('div', {
    staticClass: "battleRecord-sessions",
    on: {
      "click": _vm.onSessionsFilterShow
    }
  }, [_vm.detail.asset === 'coin' ? _c('img', {
    attrs: {
      "src": require('@images/ui/icon-gold.png'),
      "alt": ""
    }
  }) : _vm.detail.asset === 'usdt' ? _c('img', {
    attrs: {
      "src": require('@images/ui/icon-usdt.png'),
      "alt": ""
    }
  }) : _vm.detail.asset === 'pony' ? _c('img', {
    attrs: {
      "src": require('@images/ui/pony.png'),
      "alt": ""
    }
  }) : _vm._e(), _c('div', {
    staticClass: "battleRecord-sessions__arrow"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow"
    }
  })], 1), _vm.isShowSessionsFilter ? _c('div', {
    staticClass: "pop-filter pop-sessions"
  }, [_c('div', {
    staticClass: "pop-filter-shadow",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onSessionsFilterClose.apply(null, arguments);
      }
    }
  }), _c('div', {
    staticClass: "pop-filter-main",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return (() => {}).apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "pop-filter-list"
  }, [_c('div', {
    staticClass: "pop-filter-scroll"
  }, _vm._l(_vm.assetList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "pop-filter-item",
      class: _vm.selectSessionFilter === item && 'active',
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.onSelectSessionFilter(item);
        }
      }
    }, [item === 'coin' ? _c('img', {
      attrs: {
        "src": require('@images/ui/icon-gold.png'),
        "alt": ""
      }
    }) : item === 'usdt' ? _c('img', {
      attrs: {
        "src": require('@images/ui/icon-usdt.png'),
        "alt": ""
      }
    }) : item === 'pony' ? _c('img', {
      attrs: {
        "src": require('@images/ui/pony.png'),
        "alt": ""
      }
    }) : _vm._e(), _c('span', [_vm._v(_vm._s(_vm._f("capitalizeFirstLetter")(item)))])]);
  }), 0)])])]) : _vm._e()]) : _vm._e(), _vm.selectFilter ? _c('div', {
    staticClass: "battleRecord-filter"
  }, [_c('div', {
    staticClass: "filter-sort",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onFilterShow.apply(null, arguments);
      }
    }
  }, [_vm.selectFilter ? _c('div', {
    staticClass: "filter__label"
  }, [_vm._v(_vm._s(_vm.selectFilter.name))]) : _vm._e()])]) : _vm._e()], 2) : _vm._e()]), _vm.recordList ? [_c('transition-group', {
    staticClass: "battleRecord-list",
    attrs: {
      "tag": "div",
      "appear": ""
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterLongList
    }
  }, _vm._l(_vm.recordList, function (item, index) {
    return _c('div', {
      key: `b${index}`,
      staticClass: "battleRecord-item",
      attrs: {
        "data-index": index <= 50 ? index + 1 : 0
      },
      on: {
        "click": function ($event) {
          return _vm.onCheckRecord(item);
        }
      }
    }, [_c('div', {
      staticClass: "battleRecord-item__tag",
      style: {
        background: 'url(' + require(`@images/ui/${item.log_data.result ? 'battle-record-icon-victory' : 'battle-record-icon-defeat'}.png`) + ') center/auto 100% no-repeat'
      }
    }), _c('div', {
      staticClass: "battleRecord-item-info"
    }, [_vm.accountInfo ? _c('div', {
      staticClass: "battleRecord-item__name"
    }, [_vm.detail.direction === 'defender' ? [_vm._v(" " + _vm._s(item.attacker_firstname) + " " + _vm._s(item.attacker_lastname) + " ")] : _vm.detail.direction === 'attacker' ? [_vm._v(" " + _vm._s(item.defender_firstname) + " " + _vm._s(item.defender_lastname) + " ")] : _vm._e()], 2) : _vm._e(), _c('div', {
      staticClass: "battleRecord-item__time"
    }, [_vm._v(_vm._s(_vm._f("formatTime")(item.battle_time * 1000, "zh")))])]), _c('div', {
      staticClass: "battleRecord-item__num"
    }, [_vm.detail.asset === 'coin' ? _c('img', {
      attrs: {
        "src": require('@images/ui/icon-gold.png'),
        "alt": ""
      }
    }) : _vm.detail.asset === 'usdt' ? _c('img', {
      attrs: {
        "src": require('@images/ui/icon-usdt.png'),
        "alt": ""
      }
    }) : _vm.detail.asset === 'pony' ? _c('img', {
      attrs: {
        "src": require('@images/ui/pony.png'),
        "alt": ""
      }
    }) : _vm._e(), [item.log_data.result ? _c('i', {
      staticClass: "victory"
    }, [_vm._v(" " + _vm._s(_vm._f("toPre")(item.reward)))]) : _c('i', {
      staticClass: "defeat"
    }, [_vm._v(" -" + _vm._s(_vm._f("toPre")(item.amount)))])]], 2), _c('div', {
      staticClass: "battleRecord-item__arrow"
    }, [_c('svg-icon', {
      attrs: {
        "name": "icon-arrow"
      }
    })], 1)]);
  }), 0), _vm.isLoading ? _c('div', {
    staticClass: "loading-list"
  }, _vm._l(3, function (_) {
    return _c('div');
  }), 0) : _vm._e(), _vm.isFinished && _vm.recordList.length > 0 && _vm.recordList.length > _vm.listPage.perPage ? _c('div', {
    staticClass: "finished"
  }, [_vm._v(" " + _vm._s(_vm.$lang("No more")) + " ")]) : _vm._e()] : _vm._e(), _vm.detail && _vm.recordList && _vm.recordList.length <= 0 && _vm.isLoading === false ? _c('m-empty', {
    attrs: {
      "isBtn": _vm.detail.direction === 'attacker',
      "btnText": _vm.$lang('Go Arena'),
      "content": _vm.$lang('No Records')
    },
    on: {
      "confirm": () => _vm.$router.push('/arena')
    }
  }) : _vm._e(), _c('m-menu')], 2)])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };