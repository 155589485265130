var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c('div', {
    staticClass: "popPrompt"
  }, [_c('div', {
    staticClass: "shadow",
    on: {
      "click": function ($event) {
        return _vm.$emit('update:show', false);
      }
    }
  }), _c('transition', {
    attrs: {
      "appear": "",
      "name": "popPromptBottom"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "popPrompt-main bottom"
  }, [_vm.isShowClose ? _c('div', {
    staticClass: "btn-close",
    on: {
      "click": function ($event) {
        return _vm.$emit('update:show', false);
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "popPrompt__title"
  }, [_vm._v(_vm._s(_vm.$lang("UPGRADE STAR")))]), _vm.cardObj ? _c('div', {
    staticClass: "popPrompt-con popUpgrade"
  }, [_c('div', {
    ref: "cardRef",
    staticClass: "popUpgrade-card"
  }, [_c('div', {
    staticClass: "popUpgrade-card-main"
  }, [_c('m-card-item', {
    attrs: {
      "detail": _vm.cardObj,
      "isShowUpdateLevelUI": false,
      "isShowStarUI": false
    }
  })], 1)]), [_c('div', {
    staticClass: "popUpgrade-card__name wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.2s"
    }
  }, [_vm._v(_vm._s(_vm.cardObj.name))]), _c('div', {
    staticClass: "popUpgrade-card-star"
  }, [_c('div', {
    staticClass: "popUpgradestar-star-main"
  }, [_c('div', {
    staticClass: "star"
  }, [_c('div', {
    staticClass: "star-bg"
  }, _vm._l(4, function (_) {
    return _c('i');
  }), 0), _c('transition-group', {
    staticClass: "star-main",
    attrs: {
      "tag": "div"
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": function ($event) {
        return _vm.enterUpgradeStar($event, _vm.setElementAnim);
      }
    }
  }, [_vm.cardObj.star > 0 && _vm.isUpgradeStar ? _vm._l(Array(_vm.cardObj.star).fill(null), function (_, idx) {
    return _c('i', {
      key: `star${idx}`,
      attrs: {
        "data-index": Number(idx) + 1
      }
    });
  }) : _vm._e()], 2)], 1)])]), _c('transition-group', {
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterBtnBottom
    }
  }, [_vm.isElementAnim ? [_c('div', {
    key: "level",
    staticClass: "popUpgrade-info",
    attrs: {
      "data-index": 2
    }
  }, [_c('div', {
    staticClass: "popUpgrade-item"
  }, [_c('div', {
    staticClass: "popUpgrade-item__title"
  }, [_vm._v(_vm._s(_vm.$lang("Power")) + " (Coin/h)")]), _c('div', {
    staticClass: "popUpgrade-item-main"
  }, [_c('span', {
    staticClass: "current"
  }, [_vm._v(_vm._s(_vm._f("toPre")(_vm.cardObj.power * 3600)))]), _c('i'), _c('span', {
    staticClass: "next"
  }, [_vm.card.star < _vm.gameInfo.maxStar ? [_vm._v(" " + _vm._s(_vm._f("toPre")(_vm.cardObj.next_star_power * 3600)))] : [_vm._v(_vm._s(_vm.$lang("Max")))]], 2)])])]), _c('div', {
    key: "btn",
    staticClass: "btn-upgrade",
    class: _vm.card.star >= _vm.gameInfo.maxStar && 'max',
    attrs: {
      "data-index": 3
    },
    on: {
      "click": function ($event) {
        _vm.card.star < _vm.gameInfo.maxStar && _vm.onUpgrade();
      }
    }
  }, [!_vm.isBtnLoading ? _c('div', {
    staticClass: "btn-upgrade-main"
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("UPGRADE STAR")))]), _c('div', {
    staticClass: "btn-upgrade-info"
  }, [_vm.card.star < _vm.gameInfo.maxStar ? _c('div', {
    staticClass: "btn-upgrade-info-main"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("toPre")(_vm.cardObj.star_cost)))])]) : [_vm._v(_vm._s(_vm.$lang("Max")))]], 2)]) : _c('div', {
    staticClass: "circleLoading"
  })])] : _vm._e()], 2)]], 2) : _vm._e()])])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };