import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
export default {
  name: "task",
  computed: {
    ...gameVuex.mapState(["notifyInfo"])
  },
  data() {
    const tab = [{
      label: this.$lang("Task"),
      value: "task"
    }, {
      label: this.$lang("Daily Sign-In"),
      value: "daily"
    }];
    return {
      tab,
      selectTab: "task",
      task: null,
      isTaskLoading: false,
      signTask: null,
      signConfig: null
    };
  },
  async beforeRouteLeave(to, from, next) {
    this.initData();
    setTimeout(() => {
      next();
    }, 500);
  },
  watch: {
    selectTab: {
      async handler(newTab, oldTab) {
        if (newTab === "task") {
          await this.getTasks();
        } else if (newTab === "daily") {
          const res = await this.getSignInTask();
          if (!res) {
            return;
          }
          this.signTask = res.task;
          this.signConfig = res.task_config.map(itm => {
            return {
              ...itm,
              isClaimLoading: false
            };
          });
        }
      },
      // immediate: true,
      deep: true
    }
  },
  methods: {
    ...userVuex.mapActions(["getTaskList", "getSignInTask"]),
    initData() {
      try {
        this.isTaskLoading = false;
        this.$refs.mDailyRef.isReopenFlag = true;
        this.$refs.mDailyRef.popReopenShow = false;
        this.$refs.mDailyRef.$refs.popReopenRef.isLoading = false;
      } catch (err) {}
    },
    onTab(item) {
      this.selectTab = item.value;
      this.isTaskLoading = false;
    },
    async getTasks() {
      this.isTaskLoading = true;
      const res = await this.getTaskList();
      if (!res) {
        this.isTaskLoading = false;
        this.task = [];
        return;
      }
      this.isTaskLoading = false;
      this.task = res;
    }
  },
  async mounted() {
    await this.getTasks();
  }
};