var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c('div', {
    staticClass: "popPayReward popPrompt"
  }, [_c('div', {
    staticClass: "shadow",
    on: {
      "click": _vm.onClose
    }
  }), _c('transition', {
    attrs: {
      "appear": "",
      "name": "popPromptCenter"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "popPrompt-main center"
  }, [_c('div', {
    staticClass: "btn-close",
    on: {
      "click": _vm.onClose
    }
  }), _vm.payDiamondInterReward ? _c('div', {
    staticClass: "popPrompt-con"
  }, [_c('div', {
    staticClass: "popPayReward-title"
  }, [_c('span', [_vm._v("Topup Bonus")])]), _c('div', {
    staticClass: "popPayReward-result"
  }, [_vm.payDiamondInterReward.asset !== 'card' ? [_c('transition-group', {
    attrs: {
      "appear": ""
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterDailySignIn
    }
  }, [_c('div', {
    key: "coin",
    staticClass: "popPayReward-coin",
    attrs: {
      "data-index": 1
    }
  }, [_vm.payDiamondInterReward.asset == 'coin' ? _c('img', {
    attrs: {
      "src": require(`@images/ui/icon-gold.png`),
      "alt": ""
    }
  }) : _vm.payDiamondInterReward.asset == 'usdt' ? _c('img', {
    attrs: {
      "src": require(`@images/ui/icon-usdt.png`),
      "alt": ""
    }
  }) : _vm.payDiamondInterReward.asset == 'pony' ? _c('img', {
    attrs: {
      "src": require(`@images/ui/pony.png`),
      "alt": ""
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm._f("toPre")(_vm.payDiamondInterReward.amount, 2)) + " ")])])] : [_c('transition-group', {
    staticClass: "popPayReward-card",
    attrs: {
      "tag": "div",
      "appear": ""
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterDailySignIn
    }
  }, _vm._l(_vm.payDiamondInterReward.cards, function (itm, idx) {
    return _c('div', {
      key: `o${idx}`,
      staticClass: "popPayReward-card-item",
      attrs: {
        "data-index": idx + 10
      }
    }, [_c('m-card-item', {
      attrs: {
        "detail": itm
      }
    }), _c('div', {
      staticClass: "chip"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/ui/chip.png`),
        "alt": ""
      }
    }), _c('span', [_vm._v("x" + _vm._s(itm.hasOwnProperty("amount") ? itm.amount : 1))])])], 1);
  }), 0)]], 2), [_vm.payDiamondInterReward.asset !== 'card' || _vm.payDiamondInterReward.asset === 'card' && _vm.payDiamondInterReward.cards.length > 1 ? _c('div', {
    staticClass: "popPayReward-btns",
    on: {
      "click": _vm.onConfirm
    }
  }, [_c('div', {
    staticClass: "btn-confirm"
  }, [_c('div', {
    staticClass: "btn-confirm-main"
  }, [_vm._v("Ok")])])]) : [_c('div', {
    staticClass: "popPayReward-btns",
    on: {
      "click": _vm.onCheck
    }
  }, [_c('div', {
    staticClass: "btn-confirm"
  }, [_c('div', {
    staticClass: "btn-confirm-main"
  }, [_vm._v("Check")])])])]]], 2) : _vm._e()])])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };