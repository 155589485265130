import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "popPickCombination",
  computed: {
    ...gameVuex.mapState(["cardList", "gameInfo"]),
    ...userVuex.mapState(["accountInfo"]),
    ...walletVuex.mapState(["isMobile"]),
    isShowPopAndCardList() {
      return this.show && this.cardList && this.cardList.length;
    }
  },
  props: {
    show: Boolean
  },
  watch: {
    show(bool) {
      this.popFunc(bool, this);
      if (!bool) {
        this.amountVal = 0;
        this.isConfirmFlag = true;
        if (this.$refs.popSetAmountRef) {
          this.$refs.popSetAmountRef.isLoading = false;
        }
      } else {
        this.initAmount();
      }
    },
    isShowPopAndCardList: {
      handler(bool) {
        if (!bool) return;
        this.cards = this.cardList.filter(item => {
          // const selectTab = this.$parent.selectTab;
          const bool = !(item.hasOwnProperty("battle_coin") || item.hasOwnProperty("battle_usdt") || item.hasOwnProperty("battle_pony")) && item.active_count > 0;
          return bool;
        });
        console.log("this.cards", this.cards);
        if (this.cards.length > 0) {
          const cards = this.cards.filter(item => {
            return item.total_power <= this.$parent.selectBattleCard.group_power;
          });
          this.selectCard = cards[0];
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      popSetAmountShow: false,
      selectCard: null,
      amountVal: 0,
      isConfirmFlag: true,
      isFlag: true,
      cards: null,
      isLoading: null,
      amountList: [],
      balance: null,
      selectAmount: 0
    };
  },
  methods: {
    ...gameVuex.mapActions(["getBattleFight"]),
    ...userVuex.mapActions(["getAccountInfo"]),
    onSelectAmount(item) {
      if (item > this.balance) {
        return;
      }
      console.log("item", item);
      const max = this.initAmount();
      if (this.amountVal + item <= max) {
        this.amountVal += item;
      }
    },
    initAmount() {
      const selectTab = this.$parent.selectTab;
      if (!selectTab) return;
      let amountList = [];
      let balance = null;
      if (selectTab == "coin" && this.gameInfo.battleCoinBet) {
        amountList = this.gameInfo.battleCoinBet;
        balance = this.accountInfo.coin;
        console.log(111, amountList);
      } else if (selectTab == "usdt" && this.gameInfo.battleUsdtBet) {
        amountList = this.gameInfo.battleUsdtBet;
        balance = this.accountInfo.usdt;
        console.log(222, amountList);
      } else if (selectTab == "pony" && this.gameInfo.battlePonyBet) {
        amountList = this.gameInfo.battlePonyBet;
        balance = this.accountInfo.pony;
        console.log(333, amountList);
      }
      const defender = this.$parent.selectBattleCard.amount;
      this.amountList = amountList;
      let list = this.amountList;
      const max = balance > defender ? defender : balance;
      this.balance = max;
      return max;

      // const result = max < list[0] ? 0 : list.reduce((prev, curr) => (max < curr ? prev : curr));
      // console.log("this.selectIndex", this.selectIndex);
      // this.amountVal = result;
    },

    onStart() {
      // this.$router.push("/battle");
      this.popSetAmountShow = true;
    },
    onSelect(item) {
      this.selectCard = item;
      console.log("this.selectCard", this.selectCard);
    },
    onAmountInput() {
      let amount = this.amountVal;
      const defender = this.$parent.selectBattleCard.amount;
      let balance = null;
      if (this.$parent.selectTab == "coin") {
        balance = this.accountInfo.coin;
      } else if (this.$parent.selectTab == "usdt") {
        balance = this.accountInfo.usdt;
      } else if (this.$parent.selectTab == "pony") {
        balance = this.accountInfo.pony;
      }
      this.amountVal = amount.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1").replace(/^0+(?!\.|$)/, "").replace(/(\.\d{2})\d+/, "$1");
      const max = balance > defender ? defender : balance;
      if (this.amountVal >= max) this.amountVal = max;
    },
    onAll() {
      const defender = this.$parent.selectBattleCard.amount;
      let balance = null;
      if (this.$parent.selectTab == "coin") {
        balance = this.accountInfo.coin;
      } else if (this.$parent.selectTab == "usdt") {
        balance = this.accountInfo.usdt;
      } else if (this.$parent.selectTab == "pony") {
        balance = this.accountInfo.pony;
      }
      this.amountVal = balance > defender ? _v.toPrecision(defender, 2) : _v.toPrecision(balance, 2);
    },
    onClear() {
      this.amountVal = 0;
    },
    async popSetAmountConfirm(callback) {
      const selectCard = this.selectCard;
      const selectTab = this.$parent.selectTab;
      const selectBattleCard = this.$parent.selectBattleCard;
      const amount = this.amountVal;
      _v["battleInfo"] = null;
      console.log("selectCard", selectCard, selectTab, selectBattleCard, amount);
      if (!amount) {
        this.$toasted.error(this.$lang("Please enter amount"));
        this.$refs.popSetAmountRef.isLoading = false;
        return;
      }
      if (!this.isFlag) return;
      this.isFlag = false;
      const params = {
        gid: selectCard.gid,
        asset: selectTab,
        amount,
        dest: selectBattleCard.id
      };
      console.log("params", params);
      const res = await this.getBattleFight(params);
      if (!res) {
        this.$refs.popSetAmountRef.isLoading = false;
        this.isFlag = true;
        return;
      }
      console.log("resresresres", res, selectTab);
      const _params = {
        asset: selectTab,
        direction: "attacker",
        rounds: res.battle.rounds,
        reward: res.reward,
        amount: res.amount,
        result: res.battle.result,
        honor: res.battle.honor,
        attackUserName: `${this.accountInfo.firstname} ${this.accountInfo.lastname}`,
        defenderUserName: `${selectBattleCard.firstname} ${selectBattleCard.lastname}`
      };
      this.getAccountInfo(res.userInfo);
      console.log("_params", _params);
      _v["battleInfo"] = _params;
      this.isFlag = true;
      callback();
      setTimeout(() => {
        this.$router.push("/battle");
      }, 100);
    }
  }
};