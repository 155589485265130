var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c('div', {
    staticClass: "popPrompt"
  }, [_c('div', {
    staticClass: "shadow",
    on: {
      "click": _vm.onClose
    }
  }), _c('transition', {
    attrs: {
      "appear": "",
      "name": "popPromptCenter"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "popPrompt-main center",
    attrs: {
      "id": "popActivateCard"
    }
  }, [_vm.isShowClose ? _c('div', {
    staticClass: "btn-close",
    on: {
      "click": _vm.onClose
    }
  }) : _vm._e(), _c('div', {
    staticClass: "popPrompt__title"
  }, [_vm._v(_vm._s(_vm.$lang("Hint")))]), _vm.cardObj ? _c('div', {
    staticClass: "popPrompt-con popActivated"
  }, [_c('div', {
    ref: "cardRef",
    staticClass: "activated-card"
  }, [_c('m-card-item', {
    attrs: {
      "detail": _vm.cardObj,
      "isShowUpdateStarUI": false,
      "isShowUpdateLevelUI": false
    }
  })], 1), !_vm.isActivated ? [_c('div', {
    staticClass: "activated-info"
  }, [_c('div', {
    staticClass: "activated-info__tip"
  }, [_vm._v(_vm._s(_vm.$lang("Do you want to activate this card?")))]), _vm.cardObj.hasOwnProperty('star_cost') ? _c('div', {
    staticClass: "activated-info__cost"
  }, [_c('em', [_vm._v("Cost:   ")]), _c('img', {
    attrs: {
      "src": require('@images/ui/chip.png'),
      "alt": ""
    }
  }), _c('span', [_c('i', {
    class: [_vm.cardObj.cards >= _vm.cardObj.star_cost && 'fill']
  }, [_vm._v(_vm._s(_vm.cardObj.cards))]), _vm._v("/" + _vm._s(_vm.cardObj.star_cost))])]) : _vm._e()]), _c('div', {
    staticClass: "popPrompt-btns"
  }, [_c('div', {
    staticClass: "btn-confirm",
    on: {
      "click": _vm.onConfirm
    }
  }, [!_vm.isBtnLoading ? [_c('div', {
    staticClass: "btn-confirm-main"
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("Confirm")))])])] : _c('div', {
    staticClass: "circleLoading"
  })], 2)])] : [_c('transition-group', {
    staticClass: "activated-result",
    attrs: {
      "tag": "div",
      "appear": ""
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterBtnBottom
    }
  }, [_c('div', {
    key: "name",
    staticClass: "activated-result__name",
    attrs: {
      "data-index": 2
    }
  }, [_vm._v(_vm._s(_vm.cardObj.name))]), _c('div', {
    key: "power",
    staticClass: "activated-result__power",
    attrs: {
      "data-index": 3
    }
  }, [_c('i', [_vm._v("Power: ")]), _vm._v(" " + _vm._s(_vm._f("toPre")(_vm.cardObj.power * 3600)) + " Coin/h")]), _c('div', {
    key: "tip",
    staticClass: "activated-result__tip",
    attrs: {
      "data-index": 1
    }
  }, [_vm._v(_vm._s(_vm.$lang("Activation successful!")))])])]], 2) : _vm._e()])])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };