export default {
  name: "mCardItem",
  props: {
    detail: null,
    isShowShadowUI: {
      typeof: Boolean,
      default: true
    },
    isShowUpdateLevelUI: {
      typeof: Boolean,
      default: true
    },
    isShowUpdateStarUI: {
      typeof: Boolean,
      default: true
    },
    isShowStarUI: {
      typeof: Boolean,
      default: true
    },
    isShowLevelUI: {
      typeof: Boolean,
      default: true
    }
  }
};