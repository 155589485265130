export const anim = {
  methods: {
    beforeEnter(el) {
      el.style.opacity = 0;
    },
    enterList(el, done) {
      let delay = el.dataset.index * 50;
      let timer = setTimeout(() => {
        el.style.transition = "opacity 0.1s";
        el.style.opacity = 1;
        el.style.animation = `listAnim 0.2s ease-out infinite`;
        el.style["animation-iteration-count"] = 1;
        clearTimeout(timer);
        done();
      }, delay);
    },
    enterLongList(el, done) {
      let delay = el.dataset.index * 50;
      let timer = setTimeout(() => {
        el.style.transition = "opacity 0.1s";
        el.style.opacity = 1;
        el.style.animation = `listLongAnim 0.2s ease-out infinite`;
        el.style["animation-iteration-count"] = 1;
        clearTimeout(timer);
        done();
      }, delay);
    },
    enterBtnBottom(el, done) {
      let delay = el.dataset.index * 50;
      let timer = setTimeout(() => {
        el.style.transition = "opacity 0.1s";
        el.style.opacity = 1;
        el.style.animation = `btnBottomAnim 0.5s ease-out infinite`;
        el.style["animation-iteration-count"] = 1;
        clearTimeout(timer);
        done();
      }, delay);
    },
    enterSignTask(el, done) {
      let delay = el.dataset.index * 30;
      let timer = setTimeout(() => {
        el.style.transition = "opacity 0.1s";
        el.style.opacity = 1;
        el.style.animation = `signTaskAnim 0.15s ease-out infinite`;
        el.style["animation-iteration-count"] = 1;
        clearTimeout(timer);
        done();
      }, delay);
    },
    enterUpgradeStar(el, done) {
      let delay = el.dataset.index * 300;
      let timer = setTimeout(() => {
        el.style.transition = "opacity 0.1s";
        el.style.opacity = 1;
        el.style.animation = `upgradeStarAnim 0.5s ease-out infinite`;
        el.style["animation-iteration-count"] = 1;
        clearTimeout(timer);
        done();
      }, delay);
    },
    //金币位移动画
    coinAnimFn(params) {
      const _params = {
        containerRef: null, //出现金币区域dom 必传
        radius: 60, //出现金币区域范围
        numCoins: 30, //出现金币个数
        img: "icon-gold.png", //图片名 带后缀
        ref: null, //金币位移的终点dom 必传 String
        callback: null, //金币位移到终点后回调 必穿
      };

      if (params == null || JSON.stringify(params) == "{}") {
        return;
      }
      if (!params.hasOwnProperty("ref")) {
        console.error("error: coinAnim ref?");
        return;
      } else {
        _params.ref = params.ref;
      }
      if (!params.hasOwnProperty("containerRef")) {
        console.error("error: coinAnim containerRef?");
        return;
      } else {
        _params.containerRef = params.containerRef;
      }
      if (!params.hasOwnProperty("callback") && typeof callback != "function") {
        console.error("error: coinAnim callback?");
        return;
      } else {
        _params.callback = params.callback;
      }

      if (params?.radius) {
        _params.radius = params.radius;
      }
      if (params?.numCoins) {
        _params.numCoins = params.numCoins;
      }
      if (params?.img) {
        _params.img = params.img;
      }

      console.log("_params", _params);
      let count = 0;
      let src = require(`@images/ui/${_params.img}`);
      console.log("src", src);
      this.$nextTick(() => {
        const coinContainer = _params.containerRef;
        coinContainer.innerHTML = "";
        let coins = [];

        for (let i = 0; i < _params.numCoins; i++) {
          let coin = document.createElement("img");
          coin.src = src;
          coin.classList.add("coin");

          let angle = Math.random() * 2 * Math.PI;
          let r = Math.sqrt(Math.random()) * _params.radius;

          let x = _params.radius + r * Math.cos(angle) - 25;
          let y = _params.radius + r * Math.sin(angle) - 25;

          coin.style.left = "50%";
          coin.style.top = "50%";

          coinContainer.appendChild(coin);
          coins.push({ element: coin, finalX: x, finalY: y });
        }
        console.log("coins", coins);

        void coinContainer.offsetWidth;

        coins.forEach((coin, index) => {
          // coin.element.style.animation = `getCoinAnim 2s ease forwards`;
          coin.element.style.left = `${coin.finalX}px`;
          coin.element.style.top = `${coin.finalY}px`;
        });

        setTimeout(() => {
          let endButton = _params.ref;
          let endButtonRect = endButton.getBoundingClientRect();
          let containerRect = coinContainer.getBoundingClientRect();

          let endX = endButtonRect.left - containerRect.left;
          let endY = endButtonRect.top - containerRect.top;
          console.log("endXendXendXendX", endX, endY);
          coins.forEach((coin, index) => {
            setTimeout(() => {
              coin.element.style.left = `${endX}px`;
              coin.element.style.top = `${endY}px`;
              if (index === coins.length - 1) {
                coin.element.addEventListener("transitionend", () => {
                  count++;
                  coinContainer.innerHTML = "";
                  if (count == 1) _params.callback();
                });
              }
            }, index * 20);
          });
        }, 500);
      });
    },

    enterDailySignIn(el, done) {
      let delay = el.dataset.index * 30;
      let timer = setTimeout(() => {
        el.style.transition = "opacity 0.1s";
        el.style.opacity = 1;
        el.style.animation = `dailySignInAnim 0.4s ease-out infinite`;
        el.style["animation-iteration-count"] = 1;
        clearTimeout(timer);
        done();
      }, delay);
    },

    setWowAnim() {
      _v.$nextTick(() => {
        const wow = new this.$wow({
          live: false,
        });
        wow.init();
      });
    },
  },
  mounted() {},
};
