var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_vm.isRouterAlive && _vm.route != '/battleTest' ? [_vm.isDisablePc && !_vm.isPhone ? [_c('div', {
    staticClass: "disable"
  }, [_c('div', {
    staticClass: "disable__text"
  }, [_c('p', [_vm._v(_vm._s(_vm.$lang("Game only runs on mobile")))]), _c('p', [_vm._v(_vm._s(_vm.$lang("Telegram mini-app")))])]), _vm._m(0)])] : [_vm.isPreload ? _c('preload-image', {
    on: {
      "imgAllLoaded": _vm.perloadResultFn
    }
  }) : _c('layout'), _c('pop-prompt', {
    attrs: {
      "show": _vm.popInsufficientPrompt.show,
      "content": _vm.popInsufficientPrompt.message,
      "isBtnConfirmText": _vm.popInsufficientPrompt.code === 101 && _vm.$lang('Buy Diamond') || _vm.popInsufficientPrompt.code === 102 && _vm.$lang('Draw Card') || _vm.popInsufficientPrompt.code !== 102 && _vm.popInsufficientPrompt.code !== 101 && _vm.$lang('Confirm')
    },
    on: {
      "update:show": function ($event) {
        return _vm.$set(_vm.popInsufficientPrompt, "show", $event);
      },
      "confirm": _vm.popInsufficientPromptConfirm
    }
  }), _c('pop-guide', {
    ref: "popGuideRef",
    attrs: {
      "show": _vm.popGuideShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popGuideShow = $event;
      }
    }
  })]] : _vm._e(), _vm.route == '/battleTest' ? _c('layout') : _vm._e(), _c('pop-prompt', {
    attrs: {
      "show": _vm.popComingsoonShow,
      "className": "popComingSoon",
      "content": "html",
      "isShadowClick": true,
      "isBtnConfirm": false,
      "isTitle": false,
      "isClose": false
    },
    on: {
      "update:show": function ($event) {
        _vm.popComingsoonShow = $event;
      }
    }
  }, [_c('div', {
    staticClass: "pop-comingsoon"
  }, [_c('div', {
    staticClass: "comingsoon",
    style: {
      background: 'url(' + require('@images/ui/ponyverse.png') + ') center/auto 100% no-repeat'
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$lang("Coming Soon")))])])]), _c('pop-pay-reward', {
    attrs: {
      "show": _vm.popPayRewardShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popPayRewardShow = $event;
      }
    }
  }), _c('div', {
    staticClass: "getCoinAnim-container",
    attrs: {
      "id": "coinAnimStartRef"
    }
  }), _c('div', {
    staticClass: "coinAnimEnd",
    attrs: {
      "id": "coinAnimEndRef"
    }
  })], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "disable__code"
  }, [_c('img', {
    attrs: {
      "src": "/qrcode.jpg",
      "alt": ""
    }
  })]);
}];
export { render, staticRenderFns };