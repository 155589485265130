import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "popJoinArenaSelectAmount",
  props: {
    show: Boolean,
    type: String,
    detail: null
  },
  data() {
    return {
      amountList: [],
      selectAmount: 0,
      selectIndex: null,
      isLoading: false,
      isFlag: true,
      balance: null,
      popDepositShow: false,
      depositNum: 1,
      isDepositFlag: true
    };
  },
  computed: {
    ...userVuex.mapState(["accountInfo"]),
    ...gameVuex.mapState(["gameInfo"])
  },
  watch: {
    show(bool) {
      this.popFunc(bool, this);
      this.selectAmount = 0;
      this.initAmount();
      if (!bool) {}
    }
  },
  methods: {
    ...gameVuex.mapActions(["joinArena"]),
    ...userVuex.mapActions(["bindWallet", "getAccountInfo", "payPonyorUsdt", "payPonyInterval", "payUsdtInterval"]),
    //设置比较余额可选栏目
    initAmount() {
      if (!this.type) return;
      let amountList = [];
      let balance = null;
      if (this.type == "coin" && this.gameInfo.battleCoinBet) {
        amountList = this.gameInfo.battleCoinBet;
        balance = this.accountInfo.coin;
        console.log(111, amountList);
      } else if (this.type == "usdt" && this.gameInfo.battleUsdtBet) {
        amountList = this.gameInfo.battleUsdtBet;
        balance = this.accountInfo.usdt;
        console.log(222, amountList);
      } else if (this.type == "pony" && this.gameInfo.battlePonyBet) {
        amountList = this.gameInfo.battlePonyBet;
        balance = this.accountInfo.pony;
        console.log(333, amountList);
      }
      this.amountList = amountList;
      this.balance = balance;

      // const result = balance < list[0] ? 0 : list.reduce((prev, curr) => (balance < curr ? prev : curr));
      // const index = balance < list[0] ? -1 : list.findIndex((value) => value === result);
      // this.selectIndex = index;
      // console.log("this.selectIndex", this.selectIndex);
      // this.selectAmount = result;
    },

    onClear() {
      this.selectAmount = 0;
    },
    onSelectAmount(item) {
      if (item > this.balance) {
        return;
      }
      this.initAmount();
      if (this.selectAmount + item <= this.balance) {
        this.selectAmount += item;
      }
    },
    async onConfirm() {
      if (!this.selectAmount) {
        this.$toasted.error(this.$lang("Please set amount!"));
        return;
      }
      if (!this.detail) return;
      if (!this.isFlag) return;
      this.isFlag = false;
      this.isLoading = true;
      const params = {
        gid: this.detail.gid,
        asset: this.type,
        amount: this.selectAmount
      };
      const res = await this.joinArena(params);
      if (!res) {
        this.isFlag = true;
        this.isLoading = false;
        return;
      }
      const cardItem = res.filter(item => item.gid === this.detail.gid)[0];
      this.isFlag = true;
      this.isLoading = false;
      this.$emit("confirm", cardItem);
      this.$emit("update:show", false);
    },
    onPay() {
      this.popDepositShow = true;
    },
    onDepositInput() {
      if (this.depositNum <= 1) {
        this.$toasted.error(this.$lang(`Deposit at least 1 ${this.type}！`));
        return;
      }
      if (this.depositNum.includes(".")) {
        this.depositNum = Math.floor(this.depositNum);
      }
      console.log("this.depositNum", this.depositNum);
    },
    async popDepositConfirm(callback) {
      if (!this.accountInfo.wallet) {
        await this.connectTon();
        await this.bindWallet(this.tonWallet.address);
      }
      if (!this.isDepositFlag) return;
      this.isDepositFlag = false;
      const res = await this.payPonyorUsdt({
        asset: this.type,
        amount: this.depositNum
      });
      if (!res) {
        this.$refs.popDepositRef.isLoading = false;
        this.isDepositFlag = true;
        return;
      }
      if (this.type === "pony") {
        this.payPonyInterval(res.order_id);
      } else if (this.type === "usdt") {
        this.payUsdtInterval(res.order_id);
      }
      this.isDepositFlag = true;
      this.$refs.popDepositRef.isLoading = false;
      callback();
      this.popDepositShow = false;
    }
  }
};