var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "ToolTip"
  }, [_c('div', {
    staticClass: "ToolTip-main"
  }, [_c('div', {
    staticClass: "tip",
    style: {
      '--t': _vm.remTran(_vm.translate),
      '--b': _vm.remTran(-_vm.translate)
    }
  }, [_vm._v(_vm._s(_vm.tip))]), _vm._t("default")], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };