var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mining"
  }, [_c('m-arena-assets', {
    ref: "mAssetsRef"
  }), _c('div', {
    staticClass: "mining-body page-body"
  }, [_c('div', {
    staticClass: "mining-main page-main"
  }, [_c('div', {
    staticClass: "mining-head"
  }), _c('div', {
    staticClass: "mining-con"
  }, [_c('div', {
    staticClass: "mining-total wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "mining-total__title"
  }, [_vm._v(_vm._s(_vm.$lang("Total Power")))]), _c('div', {
    staticClass: "mining-total__num"
  }, [_c('span', [_vm.pool ? [_vm._v(" " + _vm._s(_vm._f("toPre")(_vm.pool.total_power)) + " ")] : [_vm._v("--")]], 2)])]), _c('div', {
    staticClass: "mining-pool"
  }, [_c('div', {
    staticClass: "mining-pool-q wow zoomIn",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('i', {
    style: {
      zIndex: 1,
      background: 'url(' + require('@images/ui/mining/mining-q-1.png') + ') center/auto 100% no-repeat'
    }
  }), _c('span', {
    staticClass: "left",
    style: {
      zIndex: 2,
      background: 'url(' + require('@images/ui/mining/mining-q-2.png') + ') center/auto 100% no-repeat'
    }
  }), _c('span', {
    staticClass: "right",
    style: {
      zIndex: 3,
      background: 'url(' + require('@images/ui/mining/mining-q-3.png') + ') center/auto 100% no-repeat'
    }
  }), _c('span', {
    staticClass: "left",
    style: {
      zIndex: 4,
      background: 'url(' + require('@images/ui/mining/mining-q-4.png') + ') center/auto 100% no-repeat'
    }
  }), _c('span', {
    staticClass: "right",
    style: {
      zIndex: 5,
      background: 'url(' + require('@images/ui/mining/mining-q-5.png') + ') center/auto 100% no-repeat'
    }
  })]), _c('div', {
    attrs: {
      "id": "particles-js"
    }
  }), _c('div', {
    staticClass: "mining-pool-main wow zoomIn",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "mining-pool__title"
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("Mining pool")))])]), _c('div', {
    staticClass: "mining-pool__num wow zoomIn",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_vm.pool ? [_vm._v(" " + _vm._s(_vm._f("toPre")(_vm.pool.total_pony)) + " ")] : [_vm._v("--")]], 2), _vm.pool ? _c('div', {
    staticClass: "mining-pool__time wow zoomIn",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_vm.settleTime > 0 ? [_vm._v(" " + _vm._s(_vm._f("formatDay")(_vm.settleTime)) + " ")] : _c('span', {
    class: _vm.bigNumber(_vm.pool.total_pony).gt(0) && 'active'
  }, [_vm.bigNumber(_vm.pool.total_pony).gt(0) ? [_vm._v(" " + _vm._s(_vm.miningText) + " ")] : [_vm._v(" " + _vm._s(_vm.$lang("coming soon")) + " ")]], 2)], 2) : _vm._e()])]), _c('div', {
    staticClass: "mining-pending wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "mining-pending-title"
  }, [_c('div', {
    staticClass: "mining-pending-title__label"
  }, [_vm._v(_vm._s(_vm.$lang("Pending")))]), _c('div', {
    staticClass: "mining-pending-title__num"
  }, [_c('span', [_vm.pool ? [_vm._v(" " + _vm._s(_vm._f("toPre")(_vm.pool.pool_pony, 4)) + " ")] : [_vm._v("--")]], 2)])]), _c('div', {
    staticClass: "mining-pending-main"
  }, [_c('div', {
    staticClass: "mining-pending-item wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "mining-pending-item__label"
  }, [_vm._v(_vm._s(_vm.$lang("My Power"))), _c('img', {
    directives: [{
      name: "popover",
      rawName: "v-popover:myPowerInfo.top",
      arg: "myPowerInfo",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "src": require('@images/ui/icon-question.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "mining-pending-item__num"
  }, [_c('span', [_vm.pool ? [_vm._v(" " + _vm._s(_vm._f("toPre")(_vm.pool.user_total_power)) + " ")] : [_vm._v(" -- ")]], 2)])]), _c('div', {
    staticClass: "mining-pending-item wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "mining-pending-item__label"
  }, [_vm._v(_vm._s(_vm.$lang("Total Claimed")))]), _c('div', {
    staticClass: "mining-pending-item__num token"
  }, [_c('span', [_vm.pool ? [_vm._v(" " + _vm._s(_vm._f("toPre")(_vm.pool.claim_pony, 4)) + " ")] : [_vm._v("--")]], 2)])])]), _vm.pool ? _c('div', {
    staticClass: "btn-claim wow fadeInUp",
    class: [_vm.bigNumber(_vm.pool.pool_pony).gt(0) && 'active'],
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "btn-claim-main",
    on: {
      "click": function ($event) {
        _vm.bigNumber(_vm.pool.pool_pony).gt(0) && _vm.onClaimShow();
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("CLAIM")))])])]) : _vm._e()]), _vm.pool ? _c('div', {
    staticClass: "mining-info wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "mining-info__title"
  }, [_vm._v(_vm._s(_vm.$lang("What is")) + " " + _vm._s(_vm._f("capitalizeFirstLetter")(_vm.pool.token_name)) + "?")]), _c('div', {
    staticClass: "mining-info__label"
  }, [_vm._v(_vm._s(_vm.pool.token_desc))])]) : _vm._e(), _vm.cex && JSON.stringify(_vm.cex) != '{}' ? _c('div', {
    staticClass: "mining-centralized"
  }, [_c('div', {
    staticClass: "mining-ce__title"
  }, [_vm._v(_vm._s(_vm.$lang("Centralized exchange")))]), _c('div', {
    staticClass: "mining-ce-main"
  }, _vm._l(_vm.cex, function (item, key) {
    return _c('div', {
      staticClass: "mining-ce-item wow fadeInUp",
      attrs: {
        "data-wow-duration": "0.5s"
      }
    }, [_c('div', {
      staticClass: "mining-ce-item-logo"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/ui/mining/logo/${key}.png`),
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "mining-ce-item-info"
    }, [_c('div', {
      staticClass: "mining-ce-item-title"
    }, [_c('span', [_vm._v(_vm._s(_vm._f("capitalizeFirstLetter")(key)))]), _vm._v(" "), _c('i', [_vm._v(_vm._s(_vm._f("toPre")(item * 100, 2)) + "%")])]), _c('div', {
      staticClass: "mining-ce-progress"
    }, [_c('div', {
      staticClass: "mining-ce-progress-bar",
      style: {
        width: item * 100 + '%'
      }
    })])])]);
  }), 0)]) : _vm._e(), _vm.dex && JSON.stringify(_vm.dex) != '{}' ? _c('div', {
    staticClass: "mining-decentralized"
  }, [_c('div', {
    staticClass: "mining-ce__title"
  }, [_vm._v(_vm._s(_vm.$lang("Decentralized exchange")))]), _c('div', {
    staticClass: "mining-ce-main"
  }, _vm._l(_vm.dex, function (item, key) {
    return _c('div', {
      staticClass: "mining-ce-item wow fadeInUp",
      attrs: {
        "data-wow-duration": "0.5s"
      }
    }, [_c('div', {
      staticClass: "mining-ce-item-logo"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/ui/mining/logo/${key}.png`),
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "mining-ce-item-info"
    }, [_c('div', {
      staticClass: "mining-ce-item-title"
    }, [_c('span', [_vm._v(_vm._s(_vm._f("capitalizeFirstLetter")(key)))]), _vm._v(" "), _c('i', [_vm._v(_vm._s(_vm._f("toPre")(item * 100, 2)) + "%")])]), _c('div', {
      staticClass: "mining-ce-progress"
    }, [_c('div', {
      staticClass: "mining-ce-progress-bar",
      style: {
        width: item * 100 + '%'
      }
    })])])]);
  }), 0)]) : _vm._e()])])]), _vm.pool ? _c('popover', {
    attrs: {
      "name": "myPowerInfo"
    }
  }, [_c('div', {
    staticClass: "myPowerInfo"
  }, [_c('div', {
    staticClass: "myPowerInfo-item"
  }, [_c('span', [_vm._v("My Power")]), _c('i', {
    staticClass: "x"
  }, [_vm._v(_vm._s(_vm._f("toPre")(_vm.pool.user_total_power)))])]), _c('div', {
    staticClass: "myPowerInfo-item"
  }, [_c('span', [_vm._v(" ≈ ")]), _c('i', [_vm._v(" ≈ ")])]), _c('div', {
    staticClass: "myPowerInfo-item"
  }, [_c('span', [_vm._v("Total Power")]), _c('i', {
    staticClass: "x"
  }, [_vm._v(_vm._s(_vm._f("toPre")(_vm.pool.user_power)))])]), _c('div', {
    staticClass: "myPowerInfo-item"
  }, [_c('span', [_vm._v(" + ")]), _c('i', [_vm._v(" + ")])]), _c('div', {
    staticClass: "myPowerInfo-item"
  }, [_c('span', [_vm._v("Honor percent x Total Power")]), _c('i', [_vm._v(_vm._s(_vm._f("toPre")(_vm.pool.percent * 100, 4)) + "% x "), _c('i', {
    staticClass: "x"
  }, [_vm._v(_vm._s(_vm._f("toPre")(_vm.pool.user_power)))])])])])]) : _vm._e(), _c('m-menu', {
    ref: "mMenuRef"
  }), _c('div', {
    ref: "destoryCoinContainerRef",
    staticClass: "getCoinAnim-container"
  }), _c('pop-prompt', {
    ref: "popClaimRef",
    attrs: {
      "show": _vm.popClaimShow,
      "content": "html",
      "isShadowClick": true
    },
    on: {
      "update:show": function ($event) {
        _vm.popClaimShow = $event;
      },
      "confirm": _vm.popClaimConfirm
    }
  }, [_c('div', {
    staticClass: "pop-claim"
  }, [_vm.pool ? _c('div', {
    staticClass: "pop-claim-text"
  }, [_vm._v(" " + _vm._s(_vm.$lang("Confirm to claim")) + " "), _c('img', {
    attrs: {
      "src": require('@images/ui/pony.png'),
      "alt": ""
    }
  }), _vm._v(_vm._s(_vm._f("toPre")(_vm.pool.pool_pony, 4)) + "? ")]) : _vm._e()])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };