import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "shop",
  inject: ["getPopGuideRef", "reload"],
  data() {
    return {
      isPopTenCardSSR: false,
      popTenCardSSRItem: null,
      drawNum: 10,
      popPromptShow: false,
      drawCardList: [],
      isBtnsShow: false,
      rowSSRItem: null,
      isFlag: true,
      coinDrawCard: null,
      coinDrawCardTimer: null,
      activityCard: null,
      activityTimer: null,
      activityTime: null,
      popBuyCardShow: false,
      buyCardNum: 1,
      isBuyFlag: true
    };
  },
  computed: {
    ...gameVuex.mapState(["gameInfo"]),
    ...userVuex.mapState(["accountInfo"])
  },
  beforeRouteLeave(to, from, next) {
    this.EventBus.$off("onGuideDrawEvent", this.onDrawHandler);
    clearInterval(this.coinDrawCardTimer);
    this.coinDrawCardTimer = null;
    clearInterval(this.activityTimer);
    this.activityTimer = null;
    this.coinDrawCardTimer = null;
    this.popPromptShow = false;
    this.popBuyCardShow = false;
    setTimeout(() => {
      next();
    }, 200);
  },
  beforeDestroy() {
    // 销毁前移除事件监听器
    this.EventBus.$off("onGuideDrawEvent", this.onDrawHandler);
  },
  watch: {
    isBtnsShow(newBool, oldBool) {
      if (this.accountInfo.init >= 2) {
        this.setWowAnim();
      }
    },
    popBuyCardShow: {
      handler(bool) {
        if (!bool) {
          this.buyCardNum = 1;
          this.isBuyFlag = true;
        }
      }
    }
  },
  methods: {
    ...userVuex.mapActions(["getLoginInfo", "getAccountInfo"]),
    ...gameVuex.mapActions(["drawCardRandom", "getSaleCard", "buySaleCard"]),
    async setGuide() {
      const popGuideRef = await this.getPopGuideRef();
      console.log("popGuideRef.", popGuideRef);
      if (!popGuideRef) return;
      popGuideRef.guideStep.init0.step2.show = false;
      popGuideRef.guideStep.init0.step3.show = true;
      console.log("popGuideRef.guideStep.init0", popGuideRef.guideStep.init0);
    },
    setMoreDrawCard(cardNum) {
      this.$nextTick(() => {
        const that = this;
        this.isBtnsShow = false;
        const gridPositions = [];
        // 计算每个图片的最终位置（使用 top 和 left 来定位）
        const items = document.querySelectorAll(".result-item");
        const resultMainRef = this.$refs.resultMainRef;
        if (!resultMainRef) return;
        const boxWidth = resultMainRef.offsetWidth / 5 - 15;
        console.dir(resultMainRef);
        items.forEach((item, index) => {
          const row = Math.floor(index / 5);
          const col = index % 5;
          const x = col * (resultMainRef.offsetWidth / 5 - 10); // 100px 图片宽度 + 10px 间距
          const y = row * (boxWidth * 1.17737 + 5) + (cardNum === 10 ? 0 : 40); // 100px 图片高度 + 10px 间距
          gridPositions.push({
            x,
            y
          });
        });
        // // 图片从中心弹出，宽高恢复到原大小
        console.log("resultMainRef.offsetWidth", resultMainRef.offsetWidth);
        setTimeout(() => {
          items.forEach(item => {
            item.style.opacity = 1;
            item.style.width = boxWidth + "px";
            item.style.height = boxWidth * 1.17737;
            item.style.transform = "translate(-50%, -50%) scale(1)";
          });
        }, 500);

        // // 弹出后等待 1 秒，图片移动到对应的2行5列位置（移除 translate(-50%, -50%)）
        setTimeout(() => {
          items.forEach((item, index) => {
            const {
              x,
              y
            } = gridPositions[index];
            item.style.left = this.remTran(x);
            item.style.top = this.remTran(y);
            item.style.transform = `scale(1) translate(0, 0)`; // 移除 translate(-50%, -50%)
          });
        }, 1500);
        setTimeout(() => {
          let flipIndex = 0;
          const flipNextImage = () => {
            if (flipIndex < items.length) {
              const item = items[flipIndex];
              const itemType = item.getAttribute("data-type");
              // 检查ssr以上 弹窗提示
              if (itemType >= 3 && !this.isPopTenCardSSR) {
                this.rowSSRItem = this.drawCardList[flipIndex];
                this.isPopTenCardSSR = true;
                this.$nextTick(() => {
                  // 暂停翻转动画，等待弹窗关闭
                  this.$refs.popTenCardSSRRef.onclick = () => {
                    this.isPopTenCardSSR = false;
                    item.classList.add("flip");
                    setTimeout(() => {
                      flipIndex++;
                      this.drawCardList[flipIndex - 1].isShow = true;
                      flipNextImage(); // 关闭弹窗后继续翻转
                    }, 220); // 在动画进行到一半时（500ms）替换图片
                  };
                });
              } else {
                // 沿 z 轴翻转当前图片
                item.classList.add("flip");
                // 在翻转到90度时切换图片
                setTimeout(() => {
                  this.drawCardList[flipIndex - 1].isShow = true;
                }, 220); // 在动画进行到一半时（500ms）替换图片

                flipIndex++;
                setTimeout(flipNextImage, 500); // 每隔1秒翻转下一张图片
              }
            } else {
              // 所有图片翻转完毕后的回调
              allImagesFlippedCallback();
            }
          };
          flipNextImage(); // 开始翻转图片
        }, 2000); // 确保所有图片位移到位后再开始翻转
        // 全部翻转完毕后的回调事件
        async function allImagesFlippedCallback() {
          that.isBtnsShow = true;
          that.isFlag = true;
          console.log("this.accountInfo.initxxxxx", that.accountInfo.init);
          if (that.accountInfo.init <= 0) {
            await that.setGuide();
          }
        }
      });
    },
    setOneCard() {
      this.$nextTick(() => {
        const that = this;
        this.isBtnsShow = false;
        // 计算每个图片的最终位置（使用 top 和 left 来定位）
        const item = document.querySelector(".result-item-o");
        // // 图片从中心弹出，宽高恢复到原大小
        setTimeout(() => {
          item.style.opacity = 1;
          item.style.transform = "scale(1)";
        }, 500);
        setTimeout(() => {
          const flipNextImage = () => {
            const itemType = item.getAttribute("data-type");
            // 检查ssr以上 弹窗提示
            if (itemType >= 3 && !this.isPopTenCardSSR) {
              this.rowSSRItem = this.drawCardList[0];
              this.isPopTenCardSSR = true;
              this.$nextTick(() => {
                // 暂停翻转动画，等待弹窗关闭
                this.$refs.popTenCardSSRRef.onclick = () => {
                  item.classList.add("flip");
                  this.isPopTenCardSSR = false;
                  setTimeout(() => {
                    this.drawCardList[0].isShow = true;
                  }, 220); // 在动画进行到一半时（500ms）替换图片
                };

                allImagesFlippedCallback();
              });
            } else {
              // 沿 z 轴翻转当前图片
              item.classList.add("flip");
              // 在翻转到90度时切换图片
              setTimeout(() => {
                this.drawCardList[0].isShow = true;
                allImagesFlippedCallback();
              }, 220); // 在动画进行到一半时（500ms）替换图片
            }
          };

          flipNextImage(); // 开始翻转图片
        }, 2000); // 确保所有图片位移到位后再开始翻转
        // 全部翻转完毕后的回调事件
        function allImagesFlippedCallback() {
          that.isBtnsShow = true;
          that.isFlag = true;
        }
      });
    },
    async onDraw(amount, asset) {
      if (asset === "coin" && this.coinDrawCard > 0) {
        this.$toasted.error("The cooldown is not over yet.");
        return;
      }
      if (asset === "coin" && this.accountInfo.coin < this.gameInfo.cardCoin) {
        this.$toasted.clear();
        this.$toasted.error(this.$lang("coin Insufficient!"));
        return;
      }
      if (this.isFlag) {
        this.isFlag = false;
        this.drawCardList = null;
        let isGuide = null;
        if (this.accountInfo.init === 0) {
          isGuide = true;
        }
        const data = await this.drawCardRandom({
          amount,
          asset,
          isGuide
        });
        if (asset === "coin") {
          this.calcCoinDraw();
        }
        if (!data) {
          this.isFlag = true;
          this.drawCardList = [];
          return;
        }
        this.drawCardList = data;
        this.drawNum = amount;
        if (amount === 10 || amount === 5) {
          this.setMoreDrawCard(amount);
        } else if (amount === 1) {
          this.setOneCard();
        }
      }
    },
    calcCoinDraw() {
      let coinCardTime = this.accountInfo.coin_card_time;
      //未参与金币抽卡
      if (coinCardTime === null) {
        this.coinDrawCard = 0;
        return;
      }
      let nowTime = parseInt(new Date().getTime() / 1000);
      const calcTime = async () => {
        nowTime++;
        this.coinDrawCard = coinCardTime - nowTime <= 0 ? 0 : coinCardTime - nowTime;
        if (this.coinDrawCard <= 0) {
          clearInterval(this.coinDrawCardTimer);
          this.coinDrawCardTimer = null;
          await this.getLoginInfo();
          this.calcCoinDraw();
        }
      };
      //已参与金币抽卡
      if (coinCardTime > nowTime) {
        calcTime();
        this.coinDrawCardTimer = setInterval(async () => {
          calcTime();
        }, 1000);
      }
    },
    async saleCardInfo() {
      this.activityCard = await this.getSaleCard();
      if (!this.activityCard) {
        return;
      }
      let nowTime = new Date().getTime() / 1000;
      const startTime = this.activityCard.start_time;
      const endTime = this.activityCard.end_time;
      this.activityTimer = setInterval(() => {
        nowTime++;
        if (nowTime < startTime) {
          this.activityTime = -(startTime - nowTime);
        } else if (nowTime >= endTime) {
          this.activityTime = "end";
          clearInterval(this.activityTimer);
          this.activityTimer = null;
        } else if (nowTime < endTime) {
          this.activityTime = endTime - nowTime;
        }
        if (this.activityCard.sold >= this.activityCard.amount) {
          clearInterval(this.activityTimer);
          this.activityTimer = null;
        }
      }, 1000);
    },
    onBuy() {
      this.popBuyCardShow = true;
    },
    async popBuyCardConfirm(callback) {
      if (!this.isBuyFlag) return;
      this.$refs.popBuyCardRef.isLoading = true;
      this.isBuyFlag = false;
      const res = await this.buySaleCard(this.buyCardNum);
      if (!res) {
        this.isBuyFlag = true;
        this.$refs.popBuyCardRef.isLoading = false;
        return;
      }
      this.activityCard = {
        ...res,
        name: this.activityCard.name
      };
      this.isBuyFlag = true;
      this.$toasted.success(this.$lang("Buy success!"));
      callback();
      this.popBuyCardShow = false;
    },
    onAll() {
      const stockNum = Number(this.activityCard.amount - this.activityCard.sold);
      const ownBuyNum = parseInt(this.accountInfo.diamond / this.activityCard.price);
      if (ownBuyNum >= stockNum) {
        this.buyCardNum = stockNum;
      } else {
        this.buyCardNum = ownBuyNum;
      }
    },
    onInput() {
      const stockNum = Number(this.activityCard.amount - this.activityCard.sold);
      const ownBuyNum = parseInt(this.accountInfo.diamond / this.activityCard.price);
      console.log("stockNum", stockNum, ownBuyNum);
      if (stockNum >= ownBuyNum && this.buyCardNum >= ownBuyNum) {
        this.buyCardNum = ownBuyNum;
      } else if (stockNum < ownBuyNum && this.buyCardNum >= stockNum) {
        this.buyCardNum = stockNum;
      }
      if (String(this.buyCardNum).includes(".")) {
        this.buyCardNum = Math.floor(this.buyCardNum);
      }
      console.log("this.destroyNum", this.buyCardNum);
    }
  },
  created() {
    this.onDrawHandler = this.onDraw.bind(this, 5, "diamond");
    this.EventBus.$off("onGuideDrawEvent", this.onDrawHandler);
    this.EventBus.$on("onGuideDrawEvent", this.onDrawHandler);
  },
  async mounted() {
    this.isBtnsShow = true;
    await this.saleCardInfo();
    this.calcCoinDraw();
  }
};