import "core-js/modules/es.array.push.js";
const body = document.querySelector("body");
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "battleRecord",
  data() {
    return {
      recordList: null,
      listPage: {
        page: 1,
        total: 0,
        perPage: 50
      },
      detail: null,
      isLoading: false,
      isShowCardFilter: false,
      filterCards: null,
      selectFilter: null,
      isShowSessionsFilter: false,
      assetList: [],
      selectSessionFilter: null,
      isFinished: false
    };
  },
  computed: {
    ...gameVuex.mapState(["cardList", "gameInfo"]),
    ...userVuex.mapState(["accountInfo"])
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    ...gameVuex.mapActions(["getBattleReplay", "getCardList"]),
    initData() {
      this.listPage.page = 1;
      this.listPage.sort = "amount";
      this.listPage.direction = "desc";
      this.total = 0;
    },
    async getBattleRecordListFunc(isPage) {
      return new Promise(async (resolve, reject) => {
        this.listPage.page++;
        if (!isPage) {
          this.recordList = [];
          this.listPage.page = 1;
        }
        const {
          asset,
          direction
        } = this.detail;
        const {
          page,
          perPage
        } = this.listPage;
        let params = {
          asset: this.selectTab,
          page,
          perPage,
          direction,
          asset
        };
        if (this.selectFilter && this.selectFilter.hasOwnProperty("gid")) {
          params["gid"] = this.selectFilter.gid;
        }
        this.isLoading = true;
        const res = await this.getBattleReplay(params);
        if (!res) {
          this.isLoading = false;
          resolve();
          return;
        }
        this.listPage.total = res.total;
        const detail = this.detail;
        const recordList = res.items.map(item => {
          if (detail.direction === "defender") {
            const log_data = item.log_data;
            this.$set(log_data, "result", !log_data.result);
          }
          if (this.selectFilter) {
            this.$set(item, "group_name", this.selectFilter.name);
          } else {
            this.cardList.map(itm => {
              if (itm.gid === item.attacker_gid) {
                this.$set(item, "group_name", itm.name);
              }
            });
          }
          return item;
        });
        this.recordList.push(...recordList);
        this.isFinished = this.recordList.length >= this.listPage.total; // 没有更多数据
        console.log("this.recordList", recordList, this.recordList);
        this.isLoading = false;
        resolve(1);
      });
    },
    onCheckRecord(item) {
      _v["battleInfo"] = null;
      //direction为defender时 由于接口下发数据中结果是按攻击方算的 所以结果相反 即 reward为amount amount为reward result为!result
      const detail = this.detail;
      console.log("detail.directiondetail.direction", detail.direction, detail.asset);
      const params = {
        asset: detail.asset,
        direction: detail.direction,
        rounds: item.log_data.rounds,
        reward: item.reward,
        amount: item.amount,
        result: item.log_data.result,
        honor: item.log_data.honor,
        attackUserName: `${item.attacker_firstname} ${item.attacker_lastname}`,
        defenderUserName: `${item.defender_firstname} ${item.defender_lastname}`
      };
      console.log("params1111", detail.direction, item.log_data.result);
      _v["battleInfo"] = params;
      setTimeout(() => {
        this.$router.push("/battle");
      }, 100);
    },
    onFilterClose() {
      this.isShowCardFilter = false;
    },
    onFilterShow() {
      this.isShowCardFilter = true;
    },
    onSelectFilter(item) {
      this.selectFilter = item;
      this.isShowCardFilter = false;
      this.getBattleRecordListFunc();
    },
    onSelectSessionFilter(item) {
      this.selectSessionFilter = item;
      this.$set(this.detail, "asset", item);
      console.log("this.selectFilter", this.selectFilter, this.detail);
      this.isShowSessionsFilter = false;
      this.getBattleRecordListFunc();
    },
    onSessionsFilterShow() {
      this.isShowSessionsFilter = true;
    },
    onSessionsFilterClose() {
      this.isShowSessionsFilter = false;
    },
    initFn() {
      if (!this.$route.query || JSON.stringify(this.$route.query) === "{}") {
        this.$router.go(-1);
        return;
      }
      const assetList = ["coin", "usdt", "pony"];
      this.assetList = assetList.filter(item => {
        if (this.gameInfo.battleCoinBet && item === "coin") {
          return item;
        } else if (this.gameInfo.battleUsdtBet && item === "usdt") {
          return item;
        } else if (this.gameInfo.battlePonyBet && item === "pony") {
          return item;
        }
      });
      this.detail = this.$route.query;
      console.log("this.detail", this.detail);
      if (!this.detail) {
        this.$router.push("/");
      }
      setTimeout(async () => {
        const asset = this.detail.asset;
        let selectFilter = null;
        this.selectSessionFilter = asset;
        if (this.detail.direction === "defender") {
          this.filterCards = this.cardList.filter(item => {
            if (asset === "coin" && item.hasOwnProperty("battle_coin")) {
              return item;
            } else if (asset === "usdt" && item.hasOwnProperty("battle_usdt")) {
              return item;
            } else if (asset === "pony" && item.hasOwnProperty("battle_pony")) {
              return item;
            }
            // return (asset === "coin" && item.hasOwnProperty("battle_coin")) || (asset === "usdt" && item.hasOwnProperty("battle_usdt")) || (asset === "pony" && item.hasOwnProperty("battle_pony"));
          });
        } else if (this.detail.direction === "attacker") {
          this.filterCards = this.cardList.filter(item => {
            if (item.active_count <= 0) {
              return item;
            }
            if (asset === "coin" && !item.hasOwnProperty("battle_coin")) {
              return item;
            } else if (asset === "usdt" && !item.hasOwnProperty("battle_usdt")) {
              return item;
            } else if (asset === "pony" && !item.hasOwnProperty("battle_pony")) {
              return item;
            }
          });
        }
        selectFilter = this.filterCards.filter(item => {
          return this.detail.gid === item.gid;
        });
        if (selectFilter && selectFilter.length > 0) {
          this.selectFilter = selectFilter[0];
        }
        window.addEventListener("scroll", this.handleScroll);
        await this.getBattleRecordListFunc();
      }, 500);
    },
    async handleScroll() {
      console.log("!this.isLoading || this.isFinished", !this.isLoading || this.isFinished, this.isLoading, this.isFinished);
      if (this.isLoading || this.isFinished) return;
      // 获取页面滚动的距离
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

      // 判断是否接近页面底部（距离底部100px以内时触发加载更多）
      console.log("xxxxxx", scrollTop + windowHeight + 1 >= documentHeight, scrollTop + windowHeight + 1, documentHeight);
      if (scrollTop + windowHeight + 1 >= documentHeight) {
        await this.getBattleRecordListFunc(true);
      }
    }
  },
  mounted() {
    this.initFn();
  }
};