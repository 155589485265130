var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "friend"
  }, [_c('div', {
    staticClass: "friend-main"
  }, [_c('div', {
    staticClass: "total"
  }, [_c('img', {
    staticClass: "total-img wow fadeInDown",
    attrs: {
      "data-wow-duration": "0.5s",
      "src": require(`@images/ui/friend-bg.jpg`),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_vm._v("Invite Friends")]), _c('div', {
    staticClass: "btns wow fadeInRight",
    attrs: {
      "data-wow-duration": "0.5s"
    }
  }, [_c('div', {
    staticClass: "btn-invite"
  }, [_c('div', {
    staticClass: "btn-invite-main",
    on: {
      "click": _vm.onShare
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-invite.png`),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$lang("Invite Friend")) + " ")])]), _c('div', {
    staticClass: "btn-copy",
    on: {
      "click": _vm.onCopy
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-copy.png`),
      "alt": ""
    }
  })])])]), _c('div', {
    staticClass: "friend-income-title"
  }, [_vm._v(_vm._s(_vm.$lang("Income")))]), _c('transition-group', {
    staticClass: "list",
    attrs: {
      "tag": "div",
      "appear": ""
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterLongList
    }
  }, [_c('div', {
    key: "a",
    staticClass: "item",
    attrs: {
      "data-index": 1
    }
  }, [_c('div', {
    staticClass: "item__num"
  }, [_vm._v(_vm._s(_vm._f("toPre")(_vm.accountInfo.ref_count_real)))]), _c('div', {
    staticClass: "item__label"
  }, [_vm._v(_vm._s(_vm.$lang("My Invite")))])]), _c('div', {
    key: "b",
    staticClass: "item",
    attrs: {
      "data-index": 2
    }
  }, [_c('div', {
    staticClass: "item__num"
  }, [_vm._v(_vm._s(_vm._f("toPre")(_vm.accountInfo.comm_coin_total)))]), _c('div', {
    staticClass: "item__label"
  }, [_vm._v(_vm._s(_vm.$lang("Coin Income")))])]), _c('div', {
    key: "c",
    staticClass: "item",
    attrs: {
      "data-index": 3
    }
  }, [_c('div', {
    staticClass: "item__num"
  }, [_vm._v(_vm._s(_vm._f("toPre")(_vm.accountInfo.comm_total)))]), _c('div', {
    staticClass: "item__label"
  }, [_vm._v(_vm._s(_vm.$lang("Ton Income")))])]), _c('div', {
    key: "d",
    staticClass: "item tip",
    attrs: {
      "data-index": 4
    }
  }, [_c('img', {
    attrs: {
      "src": require('@images/ui/icon-question.png'),
      "alt": ""
    },
    on: {
      "click": _vm.onQuestion
    }
  }), _c('p', [_vm._v(" 1. Recharge "), _c('i', [_vm._v(_vm._s(_vm._f("toPre")(_vm.gameInfo.refRatio * 100)) + "%")]), _vm._v(" Ton rebate ")]), _c('p', [_vm._v(" 2. Invite a regular member and get "), _c('i', [_vm._v(_vm._s(_vm._f("toPre")(_vm.gameInfo.refCoin)))]), _vm._v(" Coin ")]), _c('p', [_vm._v(" 3. Invite a premium member and get "), _c('i', [_vm._v(_vm._s(_vm._f("toPre")(_vm.gameInfo.refPremium)))]), _vm._v(" Coin ")]), _c('p', [_vm._v(" 4. Referred players must complete the tutorial for the referrer to receive rewards. ")])])])], 1), _c('m-menu'), _c('pop-prompt', {
    attrs: {
      "show": _vm.popInviteTipShow,
      "content": "html",
      "isShadowClick": true,
      "isBtnConfirm": false,
      "isClose": false
    },
    on: {
      "update:show": function ($event) {
        _vm.popInviteTipShow = $event;
      }
    }
  }, [_vm.popInviteTipShow && _vm.gameInfo.hasOwnProperty('refDecay') ? _c('div', {
    staticClass: "popInviteTip"
  }, [_c('div', {
    staticClass: "popInviteTip__title"
  }, [_vm._v(_vm._s(_vm.$lang("As the number of invitees increases, the reward proportionally decreases:")))]), _c('div', {
    staticClass: "popInvite-main"
  }, _vm._l(_vm.gameInfo.refDecay, function (it, idx) {
    return _c('p', [_vm._v(_vm._s(idx - 1 < 0 ? 1 : _vm.gameInfo.refDecay[idx - 1].max + 1) + _vm._s(`${it.max < 0 ? `+` : `-${it.max}`}`) + " invites: " + _vm._s(it.pct * 100) + "% reward")]);
  }), 0)]) : _vm._e()])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };