import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
export default {
  name: "mDaily",
  props: {
    config: null,
    task: null
  },
  computed: {
    ...gameVuex.mapState(["cardList"]),
    distanceDay() {
      if (!this.task) return -1;
      console.log("config", this.config);
      return this.config.findIndex(item => item.claim);
      // const created_at = new Date(this.task.created_at).setHours(0, 0, 0, 0);
      // const nowDay = new Date().setHours(0, 0, 0, 0);
      // const signInIndex = this.config.findIndex((itm) => !itm.signed);
      // const signInToDay = signInIndex === -1 ? 0 : signInIndex * 24 * 3600 * 1000;
      // const signInDay = signInIndex === -1 ? 0 : (signInIndex + 1) * 24 * 3600 * 1000;
      // const nextDay = created_at + signInDay;
      // const today = created_at + signInToDay;
      // if (nowDay >= today) {
      //   const day = Math.floor((today - created_at) / 1000 / 3600 / 24);
      //   console.log("daydayday", day, signInToDay, created_at);
      //   return day;
      // } else {
      //   return -1;
      // }
    }
  },

  beforeDestroy() {
    this.popClaimShow = false;
    this.popReopenShow = false;
  },
  data() {
    return {
      isReopenFlag: true,
      popReopenShow: false,
      created_at: null,
      isClaimFlag: true,
      popClaimShow: false,
      claimReward: null
    };
  },
  watch: {},
  methods: {
    ...userVuex.mapActions(["reopenSignInTask", "getAccountInfo", "signInTask", "checkAdsgram", "getSignInTask"]),
    ...gameVuex.mapActions(["getNotify"]),
    async onReopen() {
      this.popReopenShow = true;
    },
    async popReopenConfirm(callback) {
      if (!this.isReopenFlag) return;
      this.$refs.popReopenRef.isLoading = true;
      this.isReopenFlag = true;
      const res = await this.reopenSignInTask();
      if (!res) {
        this.$refs.popReopenRef.isLoading = false;
        this.isReopenFlag = false;
        return;
      }
      this.getAccountInfo(res.userInfo);
      const signRes = await this.getSignInTask();
      this.$emit("update:task", signRes.task);
      this.$emit("update:config", signRes.task_config.map(itm => {
        return {
          ...itm,
          isClaimLoading: false
        };
      }));
      this.$refs.popReopenRef.isLoading = false;
      this.isReopenFlag = false;
      this.popReopenShow = false;
      callback();
    },
    async onClaim(item) {
      const fn = async () => {
        const res = await this.signInTask();
        if (!res) {
          item.isClaimLoading = false;
          this.isClaimFlag = true;
          return;
        }
        await this.getNotify();
        if (item.asset === "coin" || item.asset === "diamond") {
          this.popClaimConfirm(() => {
            this.$emit("update:task", res.task);
            this.$emit("update:config", res.task_config);
            item.isClaimLoading = false;
            this.isClaimFlag = true;
          }, item);
        } else {
          this.claimReward = item;
          this.$set(item, "cards", res.rewards.cards);
          this.$emit("update:task", res.task);
          this.$emit("update:config", res.task_config);
          item.isClaimLoading = false;
          this.isClaimFlag = true;
          this.popClaimShow = true;
        }
      };
      if (item.isClaimLoading) return;
      this.isClaimFlag = false;
      item.isClaimLoading = true;
      if (!_v.blockId) {
        fn();
        return;
      }
      try {
        const ads = await this.checkAdsgram(_v.blockId);
        if (!ads) {
          item.isClaimLoading = false;
          this.isClaimFlag = true;
          return;
        }
        fn();
      } catch (e) {
        item.isClaimLoading = false;
      }
    },
    async popClaimConfirm(callback, item) {
      const claimReward = item;
      if (claimReward.asset === "coin" || claimReward.asset === "diamond") {
        const claimReward = item;
        //获取金币动画
        this.$nextTick(() => {
          const destoryCoinContainerRef = this.$refs.destoryCoinContainerRef;
          if (!destoryCoinContainerRef) return;
          const mAssetsRef = this.$parent.$refs.mAssetsRef;
          if (!mAssetsRef) return;
          const coinRef = mAssetsRef.$refs["coinRef"];
          this.coinAnimFn({
            containerRef: destoryCoinContainerRef,
            img: claimReward.asset === "coin" && "icon-gold.png" || claimReward.asset === "diamond" && "icon-diamonds.png",
            ref: coinRef,
            callback: () => {
              typeof callback === "function" && callback();
            }
          });
        });
      } else {
        this.popClaimShow = false;
      }
    },
    onConfirm() {
      const claimReward = this.claimReward;
      if (claimReward.asset !== "card" || claimReward.asset === "card" && claimReward.cards.length > 1) {
        this.popClaimShow = false;
      } else {
        console.log(6666632);
        this.popClaimShow = false;
        let cardItem = claimReward.cards[0];
        console.log("cardItem", cardItem);
        this.cardList.map(item => {
          item.cards.map(itm => {
            if (cardItem.id == itm.id) {
              cardItem = {
                ...itm,
                ...cardItem
              };
            }
          });
        });
        console.log("cardItem11", cardItem);
        this.$router.push({
          name: "detail",
          query: cardItem
        });
      }
    }
  },
  mounted() {
    console.log("toDay", this.distanceDay);
  }
};