export default {
  name: "mAirdropRankItem",
  props: {
    item: {
      typeof: Object,
      default: () => {}
    },
    index: {
      typeof: [String, Number],
      default: 1
    },
    point: String
  }
};