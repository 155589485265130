import Vue from "vue";
import i18n from "./i18n";
import App from "./App.vue";
import store from "./store";
import "./assets/css/reset.scss";
import "./assets/css/common.scss";
import "./assets/css/anim.scss";
import "./assets/icons"; //svg图标
import "./assets/js/rem";
import "./assets/fonts/font.css";
import Axios from "./http/index";
import router from "./router";
import Toasted from "vue-toasted";
import directive from "./directive";
import formatter from "./utils/formatter";
import loadConfig from "@tonWalletConfig/config";
import WebApp from "@twa-dev/sdk";
import { anim } from "./mixins/anim.js";
import { WOW } from "wowjs";
import "animate.css";
import { EventBus } from "@assets/js/eventBus";
import VPopover from "vue-js-popover";
Vue.use(VPopover, { tooltip: true });
import pop from "./assets/js/pop";

// import vconsole from "vconsole";
// new vconsole()

import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);

import "./assets/css/vtip.css";
import Vtip from "vtip";
Vue.use(Vtip.directive);
Vue.prototype.$tip = Vtip.tip;

window._v = Vue.prototype;
_v.screenWidth = window.innerWidth;
_v.screenHeight = window.innerHeight;
WebApp.ready();
WebApp.expand();
WebApp.isVerticalSwipesEnabled = false;
WebApp.isClosingConfirmationEnabled = true;

Axios.install(Vue);
_v.EventBus = EventBus;

const options = {
  duration: 3000,
  position: "top-center",
  fullWidth: false,
};
Vue.use(Toasted, options);

_v.WebApp = WebApp;
for (let key in pop) {
  _v[key] = pop[key];
}

for (let key in loadConfig) {
  _v[key] = loadConfig[key];
}
for (let func in formatter) {
  _v[func] = formatter[func];
}
for (let func in formatter) {
  _v[func] = formatter[func];
}

Vue.use(directive);
for (let func in formatter) {
  Vue.filter(func, formatter[func]);
}
_v.formatter = formatter;

Vue.config.productionTip = false;

_v.registerBodyClick = (isRegister, callback) => {
  isRegister ? document.body.addEventListener("click", callback) : document.body.removeEventListener("click", callback);
};

Vue.mixin(anim);

_v.$wow = WOW;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
