const stop = body => body.style.overflow = "hidden";
const move = body => body.style.overflow = ""; //出现滚动条
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "popActivateCard",
  inject: ["getPopGuideRef"],
  props: {
    show: Boolean,
    card: null
  },
  data() {
    return {
      isActivated: false,
      //是否激活
      isBtnLoading: false,
      isFlag: true,
      cardObj: null,
      isShowClose: true,
      isGuideUpstar: false
    };
  },
  computed: {
    ...userVuex.mapState(["accountInfo"])
  },
  beforeDestroy() {
    this.EventBus.$off("onActivateConfirm", this.onConfirm);
    this.EventBus.$off("onActivateClose", this.onClose);
    if (this.accountInfo && this.accountInfo.init < 2 && this.isGuideUpstar) {
      this.getAccountInfo();
    }
  },
  watch: {
    card(obj) {
      this.cardObj = obj;
    },
    show(bool) {
      this.$nextTick(() => {
        const body = document.querySelector("body");
        bool ? stop(body) : move(body);
        body.append ? body.append(this.$el) : body.appendChild(this.$el);
      });
      if (!bool) {
        this.initPopData();
      } else {
        if (this.accountInfo !== null && this.accountInfo.init === 1) {
          this.isShowClose = false;
        } else {
          this.isShowClose = true;
        }
      }
    }
  },
  methods: {
    ...userVuex.mapActions(["getAccountInfo"]),
    ...gameVuex.mapActions(["upStarCard"]),
    ...gameVuex.mapMutations(["setCardList"]),
    onClose() {
      if (this.isShowClose) this.$emit("update:show", false);
    },
    async setGuide(userInfo) {
      const popGuideRef = await this.getPopGuideRef();
      if (!popGuideRef) return;
      this.getAccountInfo(userInfo);
      this.isShowClose = true;
      popGuideRef.guideStep.init1.step3.show = true;
    },
    animActivatedCard() {
      return new Promise((resolve, reject) => {
        const el = this.$refs.cardRef;
        console.dir(el);
        if (!el) return;
        el.style.animation = `popActivateCardAnim 0.4s ease-out infinite`;
        el.style["animation-iteration-count"] = 1;
        setTimeout(() => {
          resolve();
        }, 400);
      });
    },
    initPopData() {
      this.isBtnLoading = false;
      this.isFlag = true;
      this.isActivated = false;
      this.$parent.selectActivateCardRow = null;
    },
    async onConfirm() {
      if (!this.isFlag) {
        return;
      }
      this.isFlag = false;
      this.isBtnLoading = true;
      let isGuide = null;
      this.isShowClose = false;
      if (this.accountInfo.init === 1) {
        this.isGuideUpstar = false;
        isGuide = true;
      }
      const res = await this.upStarCard({
        pony_id: this.cardObj.id,
        isGuide
      });
      if (this.accountInfo.init === 1) {
        this.isGuideUpstar = true;
      }
      if (!res) {
        this.isFlag = true;
        this.isBtnLoading = false;
        this.isShowClose = true;
        return;
      }
      let cardData = null;
      res.cards.map(item => {
        if (item.gid === this.cardObj.gid) {
          item.cards.map(itm => {
            if (itm.id == this.cardObj.id) {
              cardData = {
                ...itm,
                gid: item.gid,
                group: item.name
              };
            }
          });
        }
      });
      if (!cardData) {
        this.isFlag = true;
        this.isBtnLoading = false;
        this.isShowClose = true;
        return;
      }
      setTimeout(async () => {
        await this.animActivatedCard();
        this.$parent.card = cardData;
        this.$router.replace({
          query: this.card
        });
        this.$nextTick(() => {
          this.isActivated = true;
          this.isFlag = true;
          this.isBtnLoading = false;
          if (this.accountInfo !== null && this.accountInfo.init === 1) {
            setTimeout(() => {
              this.setGuide(res.userInfo);
            }, 1000);
          } else {
            this.isShowClose = true;
            this.getAccountInfo(res.userInfo);
          }
        });
      }, 1000);
    }
  },
  mounted() {
    this.EventBus.$off("onActivateConfirm", this.onConfirm);
    this.EventBus.$off("onActivateClose", this.onClose);
    this.EventBus.$on("onActivateConfirm", this.onConfirm);
    this.EventBus.$on("onActivateClose", this.onClose);
  }
};