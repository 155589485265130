import "core-js/modules/es.array.push.js";
const stop = body => body.style.overflow = "hidden";
const move = body => body.style.overflow = ""; //出现滚动条
import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
export default {
  name: "popGuide",
  props: {
    show: Boolean
  },
  computed: {
    ...userVuex.mapState(["accountInfo"]),
    isPopAndInt() {
      return this.show && this.accountInfo !== null && this.accountInfo.init < 2 && this.accountInfo.init >= 0;
    }
  },
  data() {
    return {
      isGuideActive: false,
      isGuideShape: true,
      shapeSize: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
        borderRadius: 0
      },
      navBtnShopDom: null,
      isHandShow: false,
      guideStep: {
        init0: {
          step0: {
            show: false,
            message: "",
            size: null
          },
          step1: {
            show: false,
            message: "",
            size: null
          },
          step2: {
            show: false,
            message: "",
            size: null
          },
          step3: {
            show: false,
            message: "",
            size: null
          }
        },
        init1: {
          step0: {
            show: false,
            message: "",
            size: null
          },
          step1: {
            show: false,
            message: "",
            size: null
          },
          step2: {
            show: false,
            message: "",
            size: null
          },
          step3: {
            show: false,
            message: "",
            size: null
          }
        }
      }
    };
  },
  watch: {
    show(bool) {
      this.setShowIndex(bool);
      if (!bool) {
        window.removeEventListener("scroll", this.checkScroll);
      }
    },
    isPopAndInt: {
      async handler(bool) {
        if (bool) {
          this.initGuideStepStatus();
          this.isGuideShape = false;
          this.isHandShow = false;
          this.$router.push("/");
          this.$nextTick(() => {
            setTimeout(() => {
              this.initGuideFn();
              this.isGuideShape = true;
            }, 500);
          });
          if (this.accountInfo != null) {
            window.addEventListener("scroll", this.checkScroll);
          }
        }
      }
    },
    "guideStep.init0.step3.show": {
      handler(bool) {
        if (bool) {
          console.log(66662);
          if (this.accountInfo.init == 0) {
            this.setGuideDomSize();
          }
        }
      },
      deep: true,
      immediate: true
    },
    "$route.path": {
      async handler(newPath, oldPath) {
        if (newPath == oldPath) return;
        if (oldPath == "/detail") {
          this.isHandShow = true;
          this.guideStep.init1.step0.show = true;
          this.guideStep.init1.step1.show = false;
          this.setGuideDomSize("_p0101");
        }
        if (oldPath == "/shop") {
          this.guideStep.init0.step0.show = true;
          this.guideStep.init0.step1.show = false;
          this.setGuideDomSize("navShop");
        }
      },
      deep: true
    }
  },
  methods: {
    initGuideStepStatus() {
      this.guideStep.init0.step0.show = false;
      this.guideStep.init0.step1.show = false;
      this.guideStep.init0.step2.show = false;
      this.guideStep.init0.step3.show = false;
      this.guideStep.init1.step0.show = false;
      this.guideStep.init1.step1.show = false;
      this.guideStep.init1.step2.show = false;
      this.guideStep.init1.step3.show = false;
    },
    setShow(bool) {
      this.$parent.popGuideShow = bool;
    },
    initGuideFn() {
      console.log(2313);
      this.initGuideStepStatus();
      const init = this.accountInfo.init;
      this.isHandShow = true;
      if (init == 0) {
        this.setGuideDomSize("navShop");
        this.guideStep.init0.step0.show = true;
      } else if (init == 1) {
        this.guideStep.init1.step0.show = true;
        console.log("this.guideStep.init1this.guideStep.init1", this.guideStep.init1);
        this.setGuideDomSize("_p0101");
      }
    },
    setShowIndex(bool) {
      this.$nextTick(() => {
        const body = document.querySelector("body");
        bool ? stop(body) : move(body);
        body.append ? body.append(this.$el) : body.appendChild(this.$el);
      });
    },
    getScrollEle() {
      const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      return [scrollLeft, scrollTop];
    },
    setShapeSize(size) {
      for (const key in size) {
        this.$set(this.shapeSize, key, `${size[key]}px`);
      }
      console.log("this.shapeSize", this.shapeSize);
    },
    setGuideDomSize(id, radius) {
      this.$nextTick(() => {
        const guideShapeRef = this.$refs.guideShapeRef;
        if (!guideShapeRef) return;
        if (id) {
          const dom = document.getElementById(id);
          if (!dom) {
            this.setGuideDomSize(id);
            return;
          }
          const rect = dom.getBoundingClientRect();
          const [scrollLeft, scrollTop] = this.getScrollEle();
          console.log("scrollTop", rect.top + scrollTop, rect.top, scrollTop);
          this.setShapeSize({
            width: rect.width,
            height: rect.height,
            left: rect.left,
            top: rect.top,
            borderRadius: radius || 15
          });
          return;
        }
        this.$nextTick(() => {
          this.isHandShow = true;
          const guideHandRef = this.$refs.guideHandRef;
          if (!guideHandRef) return;
          const guideHandRect = guideHandRef.getBoundingClientRect();
          console.log("guideHandRect", guideHandRect);
          const [scrollLeft, scrollTop] = this.getScrollEle();
          console.log("scrollLeft, scrollTop", scrollLeft, scrollTop);
          this.setShapeSize({
            width: 0,
            height: 0,
            left: guideHandRect.width,
            top: guideHandRect.height - 30,
            borderRadius: 0
          });
        });
      });
    },
    async onShape() {
      this.isHandShow = true;
      if (this.guideStep.init0.step0.show) {
        _v.EventBus.$emit("onGuideEvent");
        this.guideStep.init0.step0.show = false;
        this.guideStep.init0.step1.show = true;
        this.setGuideDomSize("shopDraw5");
      } else if (this.guideStep.init0.step1.show) {
        this.isHandShow = false;
        this.guideStep.init0.step1.show = false;
        this.guideStep.init0.step2.show = true;
        this.setGuideDomSize("drawResultDraw", 20);
        _v.EventBus.$emit("onGuideDrawEvent");
      } else if (this.guideStep.init1.step0.show) {
        this.guideStep.init1.step0.show = false;
        this.guideStep.init1.step1.show = true;
        _v.EventBus.$emit("onSkipCardEvent");
        this.setGuideDomSize("activateCardBtn");
      } else if (this.guideStep.init1.step1.show) {
        this.guideStep.init1.step1.show = false;
        this.guideStep.init1.step2.show = true;
        _v.EventBus.$emit("onActivate");
        this.isHandShow = false;
        this.$nextTick(() => {
          setTimeout(() => {
            this.setGuideDomSize("popActivateCard", 5);
          }, 200);
        });
      } else if (this.guideStep.init1.step2.show) {
        this.isHandShow = false;
        this.guideStep.init1.step2.show = false;
        this.guideStep.init1.step3.show = true;
        _v.EventBus.$emit("onActivateConfirm");
      } else if (this.guideStep.init1.step3.show) {
        this.isHandShow = false;
        this.$parent.popGuideShow = false;
        this.guideStep.init1.step3.show = false;
        _v.EventBus.$emit("onActivateClose");
      }
    },
    checkScroll() {
      console.log("22223333", this.guideStep);
      if (this.guideStep.init0.step1.show) {
        this.setGuideDomSize("shopDraw5");
      } else if (this.guideStep.init0.step2.show) {
        this.setGuideDomSize("drawResultDraw");
      } else if (this.guideStep.init1.step0.show) {
        this.setGuideDomSize("_p0101");
      } else if (this.guideStep.init1.step1.show) {
        this.setGuideDomSize("activateCardBtn");
      }
    }
  },
  mounted() {}
};