var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c('div', {
    staticClass: "popPrompt"
  }, [_c('div', {
    staticClass: "shadow",
    on: {
      "click": function ($event) {
        return _vm.$emit('update:show', false);
      }
    }
  }), _c('transition', {
    attrs: {
      "appear": "",
      "name": "popPromptBottom"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "popPrompt-main bottom"
  }, [_vm.isShowClose ? _c('div', {
    staticClass: "btn-close",
    on: {
      "click": function ($event) {
        return _vm.$emit('update:show', false);
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "popPrompt__title"
  }, [_vm._v(_vm._s(_vm.$lang("UPGRADE")))]), _vm.cardObj ? _c('div', {
    staticClass: "popPrompt-con popUpgrade"
  }, [_c('div', {
    ref: "cardRef",
    staticClass: "popUpgrade-card"
  }, [_c('div', {
    staticClass: "popUpgrade-card-main"
  }, [_c('m-card-item', {
    attrs: {
      "detail": _vm.cardObj,
      "isShowUpdateLevelUI": false,
      "isShowLevelUI": false
    }
  })], 1)]), [_c('div', {
    staticClass: "popUpgrade-card__name wow fadeInUp",
    attrs: {
      "data-wow-duration": "0.2s"
    }
  }, [_vm._v(_vm._s(_vm.cardObj.name))]), _c('transition-group', {
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterBtnBottom
    }
  }, [_vm.isUpgrade ? [_c('div', {
    key: "power",
    staticClass: "popUpgrade-card__power",
    attrs: {
      "data-index": 1
    }
  }, [_c('i', [_vm._v("Power: ")]), _vm._v(_vm._s(_vm._f("toPre")(_vm.cardObj.power * 3600)) + " Coin/h")]), _c('div', {
    key: "level",
    staticClass: "popUpgrade-info",
    attrs: {
      "data-index": 2
    }
  }, [_c('div', {
    staticClass: "popUpgrade-item"
  }, [_c('div', {
    staticClass: "popUpgrade-item__title"
  }, [_vm._v(_vm._s(_vm.$lang("Level")))]), _c('div', {
    staticClass: "popUpgrade-item-main"
  }, [_c('span', {
    staticClass: "current"
  }, [_vm._v("Lv." + _vm._s(_vm.cardObj.level))]), _c('i'), _c('span', {
    staticClass: "next"
  }, [_vm.cardObj.level < _vm.gameInfo.maxLevel ? [_vm._v(" Lv." + _vm._s(Number(_vm.cardObj.level) + 1))] : [_vm._v("Lv." + _vm._s(_vm.cardObj.level))]], 2)])])]), _c('div', {
    key: "btn",
    staticClass: "btn-upgrade",
    class: _vm.card.level >= _vm.gameInfo.maxLevel && 'max',
    attrs: {
      "data-index": 3
    },
    on: {
      "click": function ($event) {
        _vm.cardObj.level < _vm.gameInfo.maxLevel && _vm.onUpgrade();
      }
    }
  }, [!_vm.isBtnLoading ? _c('div', {
    staticClass: "btn-upgrade-main"
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang("UPGRADE")))]), _c('div', {
    staticClass: "btn-upgrade-info"
  }, [_vm.cardObj.level < _vm.gameInfo.maxLevel ? _c('div', {
    staticClass: "btn-upgrade-info-main"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("toPre")(_vm.cardObj.level_cost)))])]) : [_vm._v(_vm._s(_vm.$lang("Max")))]], 2)]) : _c('div', {
    staticClass: "circleLoading"
  })])] : _vm._e()], 2)]], 2) : _vm._e()])])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };