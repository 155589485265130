import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const userVuex = createNamespacedHelpers("user");
const gameVuex = createNamespacedHelpers("game");
export default {
  name: "popReward",
  props: {
    show: Boolean
  },
  computed: {
    ...userVuex.mapState(["accountInfo"]),
    ...gameVuex.mapState(["cardList"])
  },
  watch: {
    show(bool) {
      this.popFunc(bool, this);
      if (bool) {
        this.setWowAnim();
      } else {
        this.isFlag = true;
        this.isLoading = false;
        this.userInfo = null;
        this.cards = null;
        this.isShowAirDrop = false;
        this.rewards = null;
      }
    }
  },
  data() {
    return {
      isFlag: true,
      isLoading: false,
      rewards: null,
      isShowAirDrop: false,
      userInfo: null,
      cards: null
    };
  },
  methods: {
    ...gameVuex.mapActions(["getCardList"]),
    ...userVuex.mapActions(["getAccountBonus", "checkAdsgram", "getAccountInfo"]),
    getAirDropRect() {
      const popRewardTagRef = this.$refs.popRewardTagRef;
      if (!popRewardTagRef) return;
      const popRewardTagRect = popRewardTagRef.getBoundingClientRect();
      return popRewardTagRect;
    },
    emitAirDropRect(popRewardTagRect) {
      this.$emit("anim", {
        rect: popRewardTagRect,
        bool: true
      });
    },
    setShowAirDropAnim() {
      const popRewardTagRect = this.getAirDropRect();
      this.emitAirDropRect(popRewardTagRect);
    },
    onClose() {
      this.setShowAirDropAnim();
      this.$emit("update:show", false);
      this.accountInfo.drop_id = 0;
    },
    async onWatchAds() {
      const fn = async () => {
        console.log("this.accountInfo", this.accountInfo);
        console.log("this.accountInfo.drop_id", this.accountInfo.drop_id);
        if (!this.accountInfo.hasOwnProperty("drop_id") && this.accountInfo.drop_id < 0) {
          this.isLoading = false;
          this.isFlag = true;
          return;
        }
        const res = await this.getAccountBonus(this.accountInfo.drop_id);
        if (!res) {
          this.isLoading = false;
          this.isFlag = true;
          return;
        }
        this.rewards = res.rewards;
        this.userInfo = res.userInfo;
        this.cards = res.cards;
        this.isLoading = false;
        this.isFlag = true;
        this.accountInfo.drop_id = 0;
      };
      if (this.isLoading) return;
      this.isFlag = false;
      this.isLoading = true;
      if (!_v.blockId) {
        fn();
        return;
      }
      try {
        const ads = await this.checkAdsgram(_v.blockId);
        if (!ads) {
          this.isLoading = false;
          this.isFlag = true;
          return;
        }
        fn();
      } catch (e) {
        this.isLoading = false;
      }
    },
    onConfirm() {
      console.log("this.rewards", this.rewards);
      this.$parent.isBtnAirdropShow = false;
      if (this.rewards.asset === "card") {
        this.$emit("update:show", false);
        this.getCardList(this.cards);
        this.getAccountInfo(this.userInfo);
        this.setShowAirDropAnim();
        return;
      }
      if (this.rewards.asset === "coin") {
        window.scrollTo(0, 0);
      }
      setTimeout(() => {
        this.$nextTick(() => {
          this.$emit("update:show", false);
          const popRewardTagRect = this.getAirDropRect();
          const refs = this.$parent.$refs;
          const destoryCoinContainerRef = refs.destoryCoinContainerRef;
          if (!destoryCoinContainerRef) return;
          let coinRef = "";
          let coinImg = "";
          if (this.rewards.asset === "coin") {
            const mAssetsRef = refs.mAssetsRef;
            if (!mAssetsRef) return;
            coinRef = mAssetsRef.$refs["coinRef"];
            coinImg = "icon-gold.png";
          } else {
            const mMenuRef = refs.mMenuRef;
            if (!mMenuRef) return;
            const _coinRef = mMenuRef.$refs[`menuassets`];
            if (!_coinRef) return;
            coinRef = _coinRef[0];
            coinImg = this.rewards.asset === "usdt" ? "icon-usdt.png" : "pony.png";
          }
          if (!coinRef && !coinImg) return;
          this.coinAnimFn({
            containerRef: destoryCoinContainerRef,
            ref: coinRef,
            img: coinImg,
            numCoins: 20,
            callback: () => {
              this.getCardList(this.cards);
              this.getAccountInfo(this.userInfo);
              this.emitAirDropRect(popRewardTagRect);
            }
          });
        });
      }, 500);
    },
    async onCheck() {
      this.$emit("update:show", false);
      this.getCardList(this.cards);
      let cardItem = this.rewards.cards[0];
      this.cardList.map(item => {
        item.cards.map(itm => {
          if (cardItem.id == itm.id) {
            cardItem = {
              ...itm,
              ...cardItem,
              gid: item.gid,
              group: item.name
            };
          }
        });
      });
      this.$router.push({
        name: "detail",
        query: cardItem
      });
    }
  }
};