class RequestCache {
  constructor() {
    this.cache = new Map();
  }

  // 根据请求 URL 和参数生成唯一的缓存key
  generateCacheKey(config) {
    let data = null;
    if (typeof config.data === "string") {
      data = JSON.parse(config.data);
    } else {
      data = config.data;
    }

    let _data = JSON.stringify(data);
    return `${config.url}_${_data}`;
  }

  // 从缓存中获取数据
  getCache(cacheKey, duration) {
    const cached = this.cache.get(cacheKey);
    if (cached && Date.now() - cached.timestamp < duration) {
      return cached.response; // 返回有效缓存
    }
    return null; // 如果缓存不存在或已过期
  }

  // 设置缓存
  setCache(cacheKey, response, duration) {
    this.cache.set(cacheKey, {
      response,
      timestamp: Date.now(),
      duration,
    });

    // 定时清除缓存
    setTimeout(() => {
      this.cache.delete(cacheKey);
    }, duration);
  }
}

export default new RequestCache();
