var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c('div', {
    staticClass: "popLuckyAirdropInfo popPrompt"
  }, [_c('div', {
    staticClass: "shadow",
    on: {
      "click": _vm.onClose
    }
  }), _c('transition', {
    attrs: {
      "appear": "",
      "name": "popPromptCenter"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "popPrompt-main center"
  }, [_c('div', {
    staticClass: "btn-close",
    on: {
      "click": _vm.onClose
    }
  }), _c('div', {
    staticClass: "popPrompt-con"
  }, [[_c('div', {
    staticClass: "popLuckyAirdropInfo-tag"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/reward-coin-question.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "popLuckyAirdropInfo-title"
  }, [_c('span', [_vm._v("Lucky Airdrop "), _c('div', {
    staticClass: "btn-question"
  }, [_c('img', {
    directives: [{
      name: "popover",
      rawName: "v-popover:luckyAirdropInfo.top",
      arg: "luckyAirdropInfo",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "src": require('@images/ui/icon-question.png'),
      "alt": ""
    }
  })])])]), _c('div', {
    staticClass: "popLuckyAirdropInfo-info"
  }, [_vm.$parent.dropTimeCountdown !== null ? [_vm.$parent.dropTimeCountdown === 0 ? _c('div', {
    staticClass: "popLuckyAirdropInfo__text"
  }, [_vm._v("The longer you stay online, the higher your chances of receiving rewards.")]) : _c('div', {
    staticClass: "popLuckyAirdropInfo__time"
  }, [_vm._v(_vm._s(_vm._f("formatDay")(_vm.$parent.dropTimeCountdown)))])] : _vm._e()], 2)]], 2)])]), _c('popover', {
    attrs: {
      "name": "luckyAirdropInfo"
    }
  }, [_c('div', {
    staticClass: "popLuckyAirdropInfo"
  }, [_vm._v("New users have chances to receive card or coin airdrops. The longer you're online, the higher the chances.")])])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };