import axios from "axios";
import Vue from "vue";
import loadConfig from "@tonWalletConfig/config";
import store from "@store/index";
const crypto = require("crypto");
import requestApiCache from "@assets/js/requestApiCache"; // 引入缓存模块

const Axios = axios.create({
  timeout: 30000, // 请求超时时间
});

// 缓存配置：为每个URL 定义独立的缓存策略
const cacheConfig = {
  "battle/list": { duration: 5000, cacheEnabled: true },
  "rank/list": { duration: 30000, cacheEnabled: true },
};

Axios.defaults.baseURL = loadConfig.http_host;
// 添加请求拦截器 缓存策略相关
Axios.interceptors.request.use(
  (config) => {
    if (config.useInterceptor === "request") {
      let token = store.state["user"]["token"] || "";
      if (token) config.data["_token"] = token;
      config.maxBodyLength = Infinity;
      config.headers["Content-Type"] = "application/json";
      _v.infoTimer = true;
    }
    const url = config.url;
    const cacheOptions = cacheConfig[url] || { cacheEnabled: false }; // 获取缓存配置
    if (cacheOptions.cacheEnabled && config.method === "post") {
      const cacheKey = requestApiCache.generateCacheKey(config);
      const cachedData = requestApiCache.getCache(cacheKey, cacheOptions.duration);
      //响应时存在缓存数据
      if (cachedData) {
        const controller = new AbortController();
        config.signal = controller.signal;
        // 调用 abort() 方法取消请求
        controller.abort();
        //阻止axios发送http请求并抛出异常 在异常中下发缓存数据
        return Promise.reject(new axios.Cancel(`${url}-||-${cacheKey} -||- ${JSON.stringify(cachedData)}`));
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// 添加响应拦截器 缓存策略相关
Axios.interceptors.response.use(
  (res) => {
    const url = res.config.url;
    const cacheOptions = cacheConfig[url] || { cacheEnabled: false }; // 获取缓存配置
    if (cacheOptions.cacheEnabled && res.config.method === "post") {
      const cacheKey = requestApiCache.generateCacheKey(res.config);
      const cachedData = requestApiCache.getCache(cacheKey, cacheOptions.duration);
      requestApiCache.setCache(cacheKey, cachedData ? cachedData : res.data, cacheOptions.duration);
    }

    return res.data;
  },
  (err) => {
    const cacheData = err.message.split("-||-");
    if (err.message && cacheData && cacheData.length >= 2) {
      //下发缓存数据
      console.log(`读取缓存并下发: ${cacheData[0]} 缓存信息 key:${cacheData[1]} 数据:`, cacheData[2]);
      return JSON.parse(cacheData[2]);
    }
    _v.$toasted.clear();
    _v.$toasted.error(`${Vue.prototype.$lang("network error")}`);
    return Promise.resolve(null);
  }
);

//添加加密拦截器
Axios.interceptors.request.use(
  (config) => {
    if (config.url !== "account/info") {
      _v.infoTimer = true;
    }
    if (config.useInterceptor === "encRequest") {
      const expireTime = 60;
      const token = store.state["user"]["token"] || "";
      if (!token) return config;
      const now = parseInt(new Date().getTime() / 1000);
      // console.log("config", config, config.data);
      // config.params = {
      //   ...config.params,
      //   ...config.data,
      // };
      const expire = now + expireTime;
      let r = token.split("_")[2].split("-");
      r[0] = r[0].padStart(64, r[1]);
      const cipher = crypto.createCipheriv("aes-256-cbc", Buffer.from(r[0], "hex"), Buffer.from(r[1], "hex"));
      const jsonString = JSON.stringify({ ...config.data, expire });
      console.log("JSON", jsonString);
      let encrypted = cipher.update(jsonString, "utf8", "base64");
      encrypted += cipher.final("base64");
      let data = { data: encrypted, _token: token };

      config.method = "post";
      config.maxBodyLength = Infinity;
      config.headers["Content-Type"] = "application/json";
      config["data"] = JSON.stringify(data);
      return config;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// 对axios的实例重新封装成一个plugin ,方便 Vue.use(xxxx)
export default {
  install: (Vue) =>
    Object.defineProperty(Vue.prototype, "$http", {
      value: Axios,
    }),
  Axios,
};
