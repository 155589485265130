var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c('div', {
    staticClass: "popReward popPrompt"
  }, [_c('div', {
    staticClass: "shadow"
  }), _c('transition', {
    attrs: {
      "appear": "",
      "name": "popPromptCenter"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "popPrompt-main center"
  }, [_c('div', {
    staticClass: "btn-close",
    on: {
      "click": _vm.onClose
    }
  }), _c('div', {
    staticClass: "popPrompt-con"
  }, [!_vm.rewards ? [_c('div', {
    ref: "popRewardTagRef",
    staticClass: "popReward-tag"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/reward-coin-question.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "popReward-title"
  }, [_c('span', [_vm._v("Lucky Airdrop "), _c('div', {
    staticClass: "btn-question"
  }, [_c('img', {
    directives: [{
      name: "popover",
      rawName: "v-popover:luckyAirdropInfo.top",
      arg: "luckyAirdropInfo",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "src": require('@images/ui/icon-question.png'),
      "alt": ""
    }
  })])])]), _c('div', {
    staticClass: "popReward-btns no",
    on: {
      "click": _vm.onWatchAds
    }
  }, [_c('div', {
    staticClass: "btn-confirm"
  }, [_c('div', {
    staticClass: "btn-confirm-main"
  }, [_vm.isLoading ? _c('div', {
    staticClass: "circleLoading"
  }) : [_c('img', {
    attrs: {
      "src": require(`@images/ui/reward-icon-watch.png`),
      "alt": ""
    }
  }), _vm._v("WATCH ADS TO GET ")]], 2)])])] : [_c('div', {
    ref: "popRewardTagRef",
    staticClass: "popReward-tag"
  }, [!_vm.rewards ? _c('img', {
    attrs: {
      "src": require(`@images/ui/reward-coin-question.png`),
      "alt": ""
    }
  }) : _vm.rewards.asset === 'card' ? _c('img', {
    attrs: {
      "src": require(`@images/ui/reward-card-img.png`),
      "alt": ""
    }
  }) : _vm.rewards.asset === 'coin' ? _c('img', {
    attrs: {
      "src": require(`@images/ui/reward-coin-img.png`),
      "alt": ""
    }
  }) : _vm.rewards.asset === 'usdt' ? _c('img', {
    attrs: {
      "src": require(`@images/ui/reward-usdt-img.png`),
      "alt": ""
    }
  }) : _vm.rewards.asset === 'pony' ? _c('img', {
    attrs: {
      "src": require(`@images/ui/reward-pony-img.png`),
      "alt": ""
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "popReward-result"
  }, [_vm.rewards.asset !== 'card' ? [_c('transition-group', {
    attrs: {
      "appear": ""
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterDailySignIn
    }
  }, [_c('div', {
    key: "coin",
    staticClass: "popReward-coin",
    attrs: {
      "data-index": 1
    }
  }, [_vm.rewards.asset == 'coin' ? _c('img', {
    attrs: {
      "src": require(`@images/ui/icon-gold.png`),
      "alt": ""
    }
  }) : _vm.rewards.asset == 'usdt' ? _c('img', {
    attrs: {
      "src": require(`@images/ui/icon-usdt.png`),
      "alt": ""
    }
  }) : _vm.rewards.asset == 'pony' ? _c('img', {
    attrs: {
      "src": require(`@images/ui/pony.png`),
      "alt": ""
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm._f("toPre")(_vm.rewards.amount, 2)) + " ")])])] : [_c('transition-group', {
    staticClass: "popReward-card",
    attrs: {
      "tag": "div",
      "appear": ""
    },
    on: {
      "before-enter": _vm.beforeEnter,
      "enter": _vm.enterDailySignIn
    }
  }, _vm._l(_vm.rewards.cards, function (itm, idx) {
    return _c('div', {
      key: `o${idx}`,
      staticClass: "popReward-card-item",
      attrs: {
        "data-index": idx + 10
      }
    }, [_c('m-card-item', {
      attrs: {
        "detail": itm
      }
    }), _c('div', {
      staticClass: "chip"
    }, [_c('img', {
      attrs: {
        "src": require(`@images/ui/chip.png`),
        "alt": ""
      }
    }), _c('span', [_vm._v("x" + _vm._s(itm.hasOwnProperty("amount") ? itm.amount : 1))])])], 1);
  }), 0)]], 2), [_vm.rewards.asset !== 'card' || _vm.rewards.asset === 'card' && _vm.rewards.cards.length > 1 ? _c('div', {
    staticClass: "popReward-btns",
    on: {
      "click": _vm.onConfirm
    }
  }, [_c('div', {
    staticClass: "btn-confirm"
  }, [_c('div', {
    staticClass: "btn-confirm-main"
  }, [_vm._v("Ok")])])]) : [_c('div', {
    staticClass: "popReward-btns",
    on: {
      "click": _vm.onCheck
    }
  }, [_c('div', {
    staticClass: "btn-confirm"
  }, [_c('div', {
    staticClass: "btn-confirm-main"
  }, [_vm._v("Check")])])])]]]], 2)])]), _c('popover', {
    attrs: {
      "name": "luckyAirdropInfo"
    }
  }, [_c('div', {
    staticClass: "popLuckyAirdropInfo"
  }, [_vm._v("New users have chances to receive card or coin airdrops. The longer you're online, the higher the chances.")])])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };