var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mArenaAssets"
  }, [_c('div', {
    staticClass: "mArenaAssets-item"
  }, [_c('div', {
    ref: "coinRef",
    staticClass: "mArenaAssets-item__icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-gold.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "mArenaAssets-item__value",
    class: _vm.isAssetCoin && 'active'
  }, [_vm._v(_vm._s(_vm._f("toPre")(_vm.accountInfo.coin)))]), _c('div', {
    staticClass: "btn btnHover"
  }, [_vm._t("default")], 2)]), _c('div', {
    staticClass: "mArenaAssets-item",
    on: {
      "click": function ($event) {
        return _vm.onSkip('usdt');
      }
    }
  }, [_c('div', {
    ref: "usdtRef",
    staticClass: "mArenaAssets-item__icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-usdt.png`),
      "alt": ""
    }
  }), _vm.isPayUsdtInterLoading ? _c('u', [_c('svg-icon', {
    attrs: {
      "name": "loading",
      "spin": ""
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "mArenaAssets-item__value"
  }, [_vm._v(_vm._s(_vm._f("toPre")(_vm.accountInfo.usdt)))]), _c('div', {
    staticClass: "btn btnHover"
  }, [_vm._t("default")], 2)]), _c('div', {
    staticClass: "mArenaAssets-item",
    on: {
      "click": function ($event) {
        return _vm.onSkip('pony');
      }
    }
  }, [_c('div', {
    ref: "ponyRef",
    staticClass: "mArenaAssets-item__icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/pony.png`),
      "alt": ""
    }
  }), _vm.isPayPonyInterLoading ? _c('u', [_c('svg-icon', {
    attrs: {
      "name": "loading",
      "spin": ""
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "mArenaAssets-item__value"
  }, [_vm._v(_vm._s(_vm._f("toPre")(_vm.accountInfo.pony)))]), _c('div', {
    staticClass: "btn btnHover"
  }, [_vm._t("default")], 2)]), [_vm.path == '/arena' ? _c('div', {
    staticClass: "mArenaAssets-item"
  }, [_c('div', {
    ref: "energyRef",
    staticClass: "mArenaAssets-item__icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-energy.png`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "mArenaAssets-item__value"
  }, [_vm._v(_vm._s(_vm._f("toPre")(_vm.accountInfo.energy)) + "/" + _vm._s(_vm._f("toPre")(_vm.gameInfo.energyReset)))]), _vm.accountInfo.energy < _vm.gameInfo.energyReset ? _c('div', {
    staticClass: "btn-pay",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onEnergyPay.apply(null, arguments);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-pay.png`),
      "alt": ""
    }
  })]) : _vm._e()]) : _c('div', {
    staticClass: "mArenaAssets-item",
    on: {
      "click": function ($event) {
        return _vm.onSkip('diamond');
      }
    }
  }, [_c('div', {
    ref: "diamondRef",
    staticClass: "mArenaAssets-item__icon diamond"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-diamonds.png`),
      "alt": ""
    }
  }), _vm.isPayDiamondInterLoading ? _c('u', [_c('svg-icon', {
    attrs: {
      "name": "loading",
      "spin": ""
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "mArenaAssets-item__value"
  }, [_vm._v(_vm._s(_vm._f("toPre")(_vm.accountInfo.diamond)))]), _c('div', {
    staticClass: "btn-pay"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/ui/icon-pay.png`),
      "alt": ""
    }
  })])])], _c('pop-add-energy', {
    attrs: {
      "show": _vm.popAddEnergyShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popAddEnergyShow = $event;
      }
    }
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };